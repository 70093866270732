import React from "react";

const Information = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8033 2.19668C11.3867 0.780147 9.50332 0 7.5 0C5.49668 0 3.61327 0.780147 2.19668 2.19668C0.780147 3.61327 0 5.49668 0 7.5C0 9.50332 0.780147 11.3867 2.19668 12.8033C3.61327 14.2199 5.49668 15 7.5 15C9.50332 15 11.3867 14.2199 12.8033 12.8033C14.2199 11.3867 15 9.50332 15 7.5C15 5.49668 14.2199 3.61327 12.8033 2.19668ZM7.5 2.05078C8.38849 2.05078 9.11133 2.77362 9.11133 3.66211C9.11133 4.5506 8.38849 5.27344 7.5 5.27344C6.61151 5.27344 5.88867 4.5506 5.88867 3.66211C5.88867 2.77362 6.61151 2.05078 7.5 2.05078ZM9.55078 12.3047H5.44922V11.4258H6.32812V7.03125H5.44922V6.15234H8.67188V11.4258H9.55078V12.3047Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
  </svg>
);
export default Information;
