import React, { useState, useEffect } from "react";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import queryString from "query-string";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Daftar from "../../vector/daftar.js";
import Masuk from "../../vector/masuk.js";
import MasukEmail from "../../vector/masukEmail.js";
import IconPassword from "../../vector/password.js";
import google from "../../vector/google.svg";
import iconEmail from "../../vector/email.svg";
import pass from "../../vector/pass.svg";
import EmailLain from "../../vector/emaillain.js";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as Sentry from "@sentry/react";

import AppBar from "../app-bar";
import { loginWithFirebaseToken } from "../../services/auth";

import "./firebase-ui.css";
import "./imports.css";
import "./mdl.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
    }
  }
});

const useStyles = makeStyles(theme => ({
  textField: {
    ".MuiInput-underline-447:after": {
      borderBottomColor: "2px solid #F4783B"
    },
    ".MuiInput-underline-447:before": {
      borderBottomColor: "2px solid #F4783B"
    },
    width: "100%",
    "& label": {
      fontSize: 12
    }
  },
  gridGoogle: {
    marginBottom: 58,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 20
    }
  },
  nikmat: {
    marginBottom: 70,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 37
    }
  },
  loginTitle: {
    fontWeight: 600,
    fontSize: 16
  }
}));

function FirebaseLogin(props, target, event) {
  if (props.user) {
    firebase
      .auth()
      .getRedirectResult()
      .then(result => {
        props.setUserLogin(result.user);
        props.history.push(getUrl.ref);
      });
  }
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [click, setClick] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [withEmail, setWithEmail] = useState(false);
  const [login, setLogin] = useState(true);
  const [forgotPass, setForgotPass] = useState(false);
  const getUrl = queryString.parse(window.location.search);
  const provider = new firebase.auth.GoogleAuthProvider();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(
    localStorage.getItem("loginWithGoogle") === "true"
  );

  const loginWithGoogle = async () => {
    setLoading(true);
    localStorage.setItem("loginWithGoogle", "true");
    firebase.auth().signInWithRedirect(provider);
  };

  const loginWithEmail = () => {
    if (email === "") {
      alert("Mohon isi email");
      setIsError(true);
      return;
    }
    if (password === "") {
      alert("Mohon isi password");
      setIsError(true);
      return;
    }
    setWithEmail(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async result => {
        if (result) {
          localStorage.setItem("users", JSON.stringify(result.user));
          const googleToken = await result.user.getIdToken();
          loginWithFirebaseToken(googleToken)
            .then(res => {
              localStorage.setItem("token", res.data.token);
              Sentry.setUser({ email: res.data.email });
              window.location.href = getUrl.ref;
            })
            .catch(err => {
              setWithEmail(false);
              alert(
                "Ups terjadi kesalahan, periksa kembali password, bila belum terdaftar silahkan sign up"
              );
            });
          // window.location.href = getUrl.ref;
        }
      })
      .catch(error => {
        // Handle Errors here.

        setWithEmail(false);
        alert(
          "Ups terjadi kesalahan, periksa kembali password, bila belum terdaftar silahkan sign up"
        );

        // ...
      });
  };

  const signupWithEmail = () => {
    setWithEmail(true);
    if (email === "") {
      alert("Mohon isi email");
      return;
    }
    if (password === "") {
      alert("Mohon isi password");
      return;
    }
    setWithEmail(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async res => {
        if (res) {
          localStorage.setItem("users", JSON.stringify(res.user));
          const googleToken = await res.user.getIdToken();
          loginWithFirebaseToken(googleToken)
            .then(res => {
              localStorage.setItem("token", res.data.token);
              Sentry.setUser({ email: res.data.email });
              window.location.href = getUrl.ref;
            })
            .catch(err => {
              setWithEmail(false);
              alert(
                "Ups terjadi kesalahan, periksa kembali password, bila belum terdaftar silahkan sign up"
              );
            });
          // window.location.href = getUrl.ref;
        }
      })
      .catch(error => {
        // Handle Errors here.
        alert("Akun sudah terdaftar, silahkan login");

        setWithEmail(false);
        // ...
      });
  };

  const resetPassword = () => {
    const emailAddress = email;
    firebase
      .auth()
      .sendPasswordResetEmail(emailAddress)
      .then(() => {
        alert("Silahkan cek inbox email anda");
        history.push("/login?ref=/profile&state=login");
      });
  };

  // console.log(getUrl);
  // console.log(props.history);

  useEffect(() => {
    const search = queryString.parse(location.search);
    if (click && login) {
      if (search.state !== "login") {
        props.history.push("/login?ref=/profile&state=login");
      }
    }
    if (forgotPass) {
      if (search.state !== "forgotPassword") {
        props.history.push("/login?ref=/profile&state=forgotPassword");
      }
    }
    if (click && !login) {
      if (search.state !== "register") {
        props.history.push("/login?ref=/profile&state=register");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login, click, forgotPass]);

  useEffect(() => {
    console.log(location);
    const search = queryString.parse(location.search);
    console.log(search.state);

    if (!search.state) {
      setClick(false);
      setForgotPass(false);
    }
    if (search.state === "register") {
      setClick(true);
      setLogin(false);
      setForgotPass(false);
    }
    if (search.state === "login") {
      setClick(true);
      setForgotPass(false);
      setLogin(true);
    }
    if (search.state === "forgotPassword") {
      setForgotPass(true);
    }
  }, [location]);

  useEffect(() => {
    if (loading) {
      firebase
        .auth()
        .getRedirectResult()
        .then(async res => {
          if (res) {
            localStorage.setItem("users", JSON.stringify(res.user));
            const googleToken = await res.user.getIdToken();
            loginWithFirebaseToken(googleToken)
              .then(res => {
                localStorage.removeItem("loginWithGoogle");
                localStorage.setItem("token", res.data.token);
                Sentry.setUser({ email: res.data.email });
                window.location.href = getUrl.ref;
              })
              .catch(err => {
                localStorage.removeItem("loginWithGoogle");
                setLoading(false);
                setWithEmail(false);
                alert("Ups terjadi kesalahan");
                console.log("error");
              });
            // window.location.href = getUrl.ref;
          }
        })
        .catch(error => {
          localStorage.removeItem("loginWithGoogle");
          setLoading(false);
          setWithEmail(false);
          alert("Ups terjadi kesalahan");
          console.log("error", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showHideIcon = showPassword => {
    if (showPassword) {
      return <Visibility fontSize="small" />;
    } else {
      return <VisibilityOff fontSize="small" />;
    }
  };
  console.log({
    click,
    forgotPass,
    login
  });

  return (
    <React.Fragment>
      {forgotPass === true ? (
        <AppBar goBack title="Atur Ulang Password" />
      ) : (
        <React.Fragment>
          {click === false ? (
            <AppBar goBack title="Masuk Akun" />
          ) : (
            <React.Fragment>
              {login === true ? (
                <AppBar goBack title="Masuk Dengan Email" />
              ) : (
                <AppBar goBack title="Daftar Dengan Email" />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <div style={{ marginBottom: 20 }}>
        {forgotPass === true ? (
          <div className="firebaseui-container firebaseui-page-provider-sign-in firebaseui-id-page-provider-sign-in firebaseui-use-spinner">
            <div className="firebaseui-card-content">
              {/* <div style={{ padding: 20 }}> */}
              <center>
                <div style={{ marginTop: 72 }} align="center">
                  <IconPassword />
                  <Typography
                    className={classes.loginTitle}
                    variant="body2"
                    style={{ marginBottom: 20, marginTop: 30 }}
                  >
                    Atur Ulang Password
                  </Typography>
                </div>

                <TextField
                  value={email || ""}
                  onChange={e => setEmail(e.target.value)}
                  label="Email"
                  className={classes.textField}
                  type="email"
                  autoComplete="email"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    style: { fontSize: 12 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          style={{
                            color: "white",
                            padding: 0,
                            margin: 0
                          }}
                        >
                          <img src={iconEmail} alt="email" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Masukan Email"
                  color="white"
                />
                <Typography style={{ fontSize: 12, marginTop: 22 }}>
                  Kami akan mengirimkan email untuk mengatur ulang password Anda
                </Typography>
                <Grid style={{ marginTop: 30 }} container spacing={0}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor:
                          process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                        color: "white",
                        width: 200,
                        textTransform: "none"
                      }}
                      className={classes.button}
                      onClick={resetPassword}
                    >
                      <b>Reset Ulang</b>
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      style={{
                        marginTop: "5%",
                        color: "#9FA3A6",
                        width: 200,
                        textTransform: "none"
                      }}
                      className={classes.button}
                      onClick={() => setForgotPass(!forgotPass)}
                    >
                      <b>Batalkan</b>
                    </Button>
                  </Grid>
                </Grid>
              </center>
            </div>
          </div>
        ) : (
          <div>
            {!props.user ? (
              <div>
                <div lang="en">
                  <div className="firebaseui-container firebaseui-page-provider-sign-in firebaseui-id-page-provider-sign-in firebaseui-use-spinner">
                    <div className="firebaseui-card-content">
                      <form
                        onSubmit={event => {
                          event.preventDefault();
                        }}
                      >
                        <ul className="firebaseui-idp-list">
                          {click === false ? (
                            <Grid container align="center">
                              <Grid
                                style={{ marginBottom: 30, marginTop: 72 }}
                                item
                                xs={12}
                              >
                                <Masuk />
                              </Grid>
                              <Grid item xs={12} className={classes.nikmat}>
                                <Typography
                                  variant="body2"
                                  className={classes.loginTitle}
                                >
                                  Masuk
                                </Typography>
                                <Typography variant="caption" display="block">
                                  Nikmati kepuasan dan kenyamanan kualitas
                                  belanja kebutuhan sehari - hari dengan{" "}
                                  {process.env.REACT_APP_BRAND_NAME ||
                                    "Srikopi"}
                                </Typography>
                              </Grid>
                              {loading ? (
                                <Grid style={{ marginBottom: 10 }} item xs={12}>
                                  <div
                                    style={{
                                      width: "100%",
                                      justifyContent: "center"
                                    }}
                                  >
                                    <CircularProgress
                                      style={{
                                        color:
                                          process.env.REACT_APP_COLOR_PRIMARY ||
                                          "#FFD101",
                                        alignSelf: "center"
                                      }}
                                      disableShrink
                                    />
                                  </div>
                                </Grid>
                              ) : (
                                <>
                                  <Grid
                                    style={{ marginBottom: 10 }}
                                    item
                                    xs={12}
                                  >
                                    <button
                                      style={{
                                        backgroundColor: "#4285F4",
                                        margin: 0,
                                        padding: 3,
                                        borderRadius: 4
                                      }}
                                      className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button"
                                      data-provider-id="google.com"
                                      data-upgraded=",MaterialButton"
                                      onClick={loginWithGoogle}
                                    >
                                      <span
                                        align="center"
                                        style={{
                                          backgroundColor: "white",
                                          borderRadius: 4,
                                          width: "20%"
                                        }}
                                        className="firebaseui-idp-icon-wrapper"
                                      >
                                        <img
                                          className="firebaseui-idp-icon"
                                          alt=""
                                          src={google}
                                          style={{ width: 37, height: 35 }}
                                        />
                                      </span>
                                      <span
                                        style={{ color: "white", fontSize: 13 }}
                                        className="firebaseui-idp-text firebaseui-idp-text-long"
                                      >
                                        <b>Masuk Dengan Google</b>
                                      </span>
                                      <span className="firebaseui-idp-text firebaseui-idp-text-short">
                                        Google
                                      </span>
                                    </button>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    className={classes.gridGoogle}
                                  >
                                    <button
                                      style={{
                                        backgroundColor:
                                          process.env.REACT_APP_COLOR_PRIMARY ||
                                          "#FFD101",
                                        margin: 0,
                                        padding: 3,
                                        borderRadius: 4
                                      }}
                                      className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-password firebaseui-id-idp-button"
                                      data-provider-id="password"
                                      data-upgraded=",MaterialButton"
                                      onClick={() => setClick(!click)}
                                    >
                                      <span
                                        align="center"
                                        style={{
                                          backgroundColor: "white",
                                          borderRadius: 4,
                                          width: "23%",
                                          height: 35
                                        }}
                                        className="firebaseui-idp-icon-wrapper"
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                          }}
                                        >
                                          <EmailLain />
                                        </div>
                                      </span>
                                      <span
                                        className="firebaseui-idp-text firebaseui-idp-text-long"
                                        style={{
                                          fontSize: 13,
                                          color:
                                            process.env.REACT_APP_COLOR_FONT ||
                                            "#000000"
                                        }}
                                      >
                                        <b>Masuk Dengan Email</b>
                                      </span>
                                      <span className="firebaseui-idp-text firebaseui-idp-text-short">
                                        Email
                                      </span>
                                    </button>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          ) : (
                            <ThemeProvider theme={theme}>
                              <Box>
                                {login === true ? (
                                  <div>
                                    {withEmail === false ? (
                                      <div style={{ marginTop: 72 }}>
                                        <div align="center">
                                          <MasukEmail />
                                          <Typography
                                            variant="body2"
                                            className={classes.loginTitle}
                                            style={{
                                              marginBottom: 20,
                                              marginTop: 30
                                            }}
                                          >
                                            Masuk Dengan Email
                                          </Typography>
                                        </div>

                                        <TextField
                                          value={email || ""}
                                          error={isError}
                                          onChange={e =>
                                            setEmail(e.target.value)
                                          }
                                          label="Email:"
                                          className={classes.textField}
                                          type="email"
                                          name="email"
                                          autoComplete="email"
                                          margin="normal"
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          InputProps={{
                                            style: { fontSize: 12 },
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton
                                                  style={{
                                                    color: "white",
                                                    padding: 0,
                                                    margin: 0
                                                  }}
                                                >
                                                  <img
                                                    src={iconEmail}
                                                    alt="Email"
                                                  />
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }}
                                          placeholder="Masukan Email"
                                        />
                                        <TextField
                                          value={password || ""}
                                          error={isError}
                                          onChange={e =>
                                            setPassword(e.target.value)
                                          }
                                          InputProps={{
                                            style: { fontSize: 12 },
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton
                                                  style={{
                                                    color: "white",
                                                    padding: 0,
                                                    margin: 0
                                                  }}
                                                >
                                                  <img src={pass} alt="Pass" />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  style={{
                                                    color: "rgb(159, 163, 166)",
                                                    padding: 0,
                                                    margin: 0
                                                  }}
                                                  onClick={() =>
                                                    setShowPassword(
                                                      !showPassword
                                                    )
                                                  }
                                                >
                                                  {showHideIcon(showPassword)}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }}
                                          label="Password:"
                                          className={classes.textField}
                                          type={
                                            showPassword ? "text" : "password"
                                          }
                                          autoComplete="current-password"
                                          margin="normal"
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          placeholder="Masukan Password"
                                        />
                                        {login === true ? (
                                          <div>
                                            <Grid
                                              container
                                              spacing={0}
                                              style={{ padding: 0 }}
                                            >
                                              <Grid item xs={8}>
                                                <Typography
                                                  style={{
                                                    color: "black",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: 12
                                                  }}
                                                  variant="caption"
                                                >
                                                  Belum terdaftar ?{" "}
                                                  <Button
                                                    onClick={() =>
                                                      setLogin(!login)
                                                    }
                                                    disableRipple
                                                    style={{
                                                      color: "#14181B",
                                                      background: "transparent",
                                                      textTransform: "none",
                                                      fontSize: 12,
                                                      padding: 0,
                                                      marginLeft: "-2%"
                                                    }}
                                                  >
                                                    <b> Daftar</b>
                                                  </Button>
                                                </Typography>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  padding: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "flex-end"
                                                }}
                                              >
                                                <Button
                                                  onClick={() =>
                                                    setForgotPass(!forgotPass)
                                                  }
                                                  disableRipple
                                                  style={{
                                                    color: "#9FA3A6",
                                                    background: "transparent",
                                                    textTransform: "none",
                                                    fontSize: 12,
                                                    padding: 0,
                                                    marginTop: -2
                                                  }}
                                                >
                                                  <u> Lupa Password?</u>
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        ) : (
                                          <Typography
                                            style={{ color: "black" }}
                                            variant="caption"
                                          >
                                            Sudah terdaftar ?{" "}
                                            <b
                                              style={{
                                                color: "#14181B"
                                              }}
                                              onClick={() => setLogin(!login)}
                                            >
                                              Login
                                            </b>
                                          </Typography>
                                        )}

                                        <div style={{ marginTop: 40 }}>
                                          <Grid
                                            align="center"
                                            container
                                            spacing={3}
                                          >
                                            <Grid item xs={12}>
                                              <Button
                                                variant="contained"
                                                style={{
                                                  backgroundColor:
                                                    process.env
                                                      .REACT_APP_COLOR_PRIMARY ||
                                                    "#FFD101",
                                                  color: "white",
                                                  borderRadius: 4,
                                                  width: 200,
                                                  textTransform: "none",
                                                  fontFamily:
                                                    "'Montserrat', sans-serif !important"
                                                }}
                                                className={classes.button}
                                                onClick={loginWithEmail}
                                              >
                                                <b>Masuk</b>
                                              </Button>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ marginTop: -20 }}
                                            >
                                              <Button
                                                style={{
                                                  color: "#9FA3A6",
                                                  width: 200,
                                                  textTransform: "none"
                                                }}
                                                // className={classes.button}
                                                onClick={() => setClick(!click)}
                                              >
                                                <b>Batalkan</b>
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        align="center"
                                        style={{
                                          width: "100%",
                                          height: "70vh",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center"
                                        }}
                                      >
                                        <CircularProgress
                                          style={{
                                            color:
                                              process.env
                                                .REACT_APP_COLOR_PRIMARY ||
                                              "#FFD101",
                                            alignSelf: "center"
                                          }}
                                          disableShrink
                                        />
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div style={{ marginTop: 72 }}>
                                    {withEmail === false ? (
                                      <div style={{ marginTop: 72 }}>
                                        <div align="center">
                                          <Daftar />
                                          <Typography
                                            variant="body2"
                                            className={classes.loginTitle}
                                            style={{
                                              marginBottom: 20,
                                              marginTop: 30
                                            }}
                                          >
                                            Daftar Dengan Email
                                          </Typography>
                                        </div>
                                        <TextField
                                          value={email || ""}
                                          onChange={e =>
                                            setEmail(e.target.value)
                                          }
                                          label="Email:"
                                          className={classes.textField}
                                          type="email"
                                          autoComplete="email"
                                          margin="normal"
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          placeholder="Masukan Email"
                                          color="white"
                                          InputProps={{
                                            style: { fontSize: 12 },
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton
                                                  style={{
                                                    color: "white",
                                                    margin: 0,
                                                    padding: 0
                                                  }}
                                                >
                                                  <img
                                                    src={iconEmail}
                                                    alt="Email"
                                                  />
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }}
                                        />
                                        <TextField
                                          value={password || ""}
                                          onChange={e =>
                                            setPassword(e.target.value)
                                          }
                                          InputProps={{
                                            style: { fontSize: 12 },
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton
                                                  style={{
                                                    color: "white",
                                                    margin: 0,
                                                    padding: 0
                                                  }}
                                                >
                                                  <img src={pass} alt="pass" />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  style={{
                                                    color: "rgb(159, 163, 166)",
                                                    margin: 0,
                                                    padding: 0
                                                  }}
                                                  onClick={() =>
                                                    setShowPassword(
                                                      !showPassword
                                                    )
                                                  }
                                                >
                                                  {showHideIcon(showPassword)}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }}
                                          label="Password:"
                                          className={classes.textField}
                                          type={
                                            showPassword ? "text" : "password"
                                          }
                                          autoComplete="current-password"
                                          margin="normal"
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          placeholder="Masukan Password"
                                        />
                                        {login === true ? (
                                          <Typography
                                            style={{ color: "black" }}
                                            variant="caption"
                                          >
                                            Belum terdaftar ?{" "}
                                            <b
                                              style={{
                                                color:
                                                  process.env
                                                    .REACT_APP_COLOR_PRIMARY ||
                                                  "#FFD101"
                                              }}
                                              onClick={() => setLogin(!login)}
                                            >
                                              SignUp
                                            </b>
                                            <Button
                                              onClick={() => setLogin(!login)}
                                              disableRipple
                                              style={{
                                                color:
                                                  process.env
                                                    .REACT_APP_COLOR_PRIMARY ||
                                                  "#FFD101",
                                                background: "transparent",
                                                textTransform: "none",
                                                fontSize: 12,
                                                padding: 0,
                                                marginLeft: "-2%"
                                              }}
                                            >
                                              <b> Daftar</b>
                                            </Button>
                                          </Typography>
                                        ) : (
                                          <Typography
                                            style={{
                                              color: "black",
                                              alignItems: "center",
                                              display: "flex"
                                            }}
                                            variant="caption"
                                          >
                                            Sudah terdaftar ?{" "}
                                            <Button
                                              onClick={() => setLogin(!login)}
                                              disableRipple
                                              style={{
                                                color: "#14181B",
                                                background: "transparent",
                                                textTransform: "none",
                                                fontSize: 12,
                                                padding: 0,
                                                marginLeft: "-2%"
                                              }}
                                            >
                                              <b> Masuk</b>
                                            </Button>
                                          </Typography>
                                        )}

                                        <div style={{ marginTop: 40 }}>
                                          <Grid
                                            align="center"
                                            container
                                            spacing={3}
                                          >
                                            <Grid item xs={12}>
                                              <Button
                                                variant="contained"
                                                style={{
                                                  backgroundColor:
                                                    process.env
                                                      .REACT_APP_COLOR_PRIMARY ||
                                                    "#FFD101",
                                                  borderRadius: 4,

                                                  color: "white",
                                                  width: 200,
                                                  textTransform: "none"
                                                }}
                                                className={classes.button}
                                                onClick={signupWithEmail}
                                              >
                                                <b>Daftar</b>
                                              </Button>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ marginTop: -20 }}
                                            >
                                              <Button
                                                style={{
                                                  color: "#9FA3A6",
                                                  width: 200,
                                                  textTransform: "none"
                                                }}
                                                className={classes.button}
                                                onClick={() => setClick(!click)}
                                              >
                                                <b>Batalkan</b>
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        align="center"
                                        style={{
                                          width: "100%",
                                          height: "100vh"
                                        }}
                                      >
                                        <CircularProgress
                                          style={{
                                            color:
                                              process.env
                                                .REACT_APP_COLOR_PRIMARY ||
                                              "#FFD101",
                                            alignSelf: "center"
                                          }}
                                          disableShrink
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Box>
                            </ThemeProvider>
                          )}
                        </ul>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div align="center" style={{ width: "100%" }}>
                <CircularProgress
                  style={{
                    color: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                    alignSelf: "center"
                  }}
                  disableShrink
                />
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default withRouter(FirebaseLogin);
