const styles = (theme) => ({
  container: {
    paddingTop: 64,
    paddingBottom: 169,
    padding: 0,
    minHeight: "100vh",
    height: "100%",
    backgroundColor: "#FAFAFA",
    borderLeft: "1px solid #f1f1f1",
    borderRight: "1px solid #f1f1f1",
  },
  addressCard: {
    height: 200,
    backgroundColor: "white",
    margin: 10,
    borderRadius: 8,
    border: "1px solid #F5F5F5",
  },
});
export default styles;
