import React from "react";

function PesanSekarang() {
  return (
    <svg
      width="256"
      height="197"
      viewBox="0 0 256 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          opacity="0.47"
          d="M0.278458 77.1461C1.50984 65.2656 7.50061 53.3655 17.9087 44.8752C34.1121 31.6366 58.6518 28.3343 80.1716 31.4217C89.1822 32.7211 98.1244 34.9976 107.35 34.2941C120.807 33.2683 132.652 26.1263 144.888 20.8992C161.56 13.7865 180.666 9.97616 198.404 13.1026C218.516 16.6492 235.013 29.9757 237.007 47.6304C237.906 55.6322 228.876 69.711 231.749 77.2927C234.661 84.9818 255.858 108.557 255.36 126.476C254.852 145.039 244.43 165.814 207.715 186.407C171 207 49.3061 191.431 26.9998 172C4.69348 152.569 10.2859 142.684 15.5437 130.022C19.5408 120.389 8.62448 112.426 4.4026 103.447C0.63028 95.4554 -0.620651 85.8513 0.278458 77.1461Z"
          fill="#E1E2FE"
        />
      </g>
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.0002 177.5C42.0002 181.5 182.5 223.5 232.644 170.058C241.106 158.558 240.593 155.257 232.644 149.968C224.696 144.678 191.213 148.175 180.163 140.588C169.113 133 152.962 122.868 143.365 133C133.767 143.132 137.374 156.007 125.744 156.007C114.114 156.007 91.7504 140.553 80.3834 146.208C69.0165 151.863 72.4058 167.356 60.5008 163.938C48.5959 160.52 33.8608 153.626 27.6825 161.558C21.5043 169.489 28.0002 173.5 35.0002 177.5Z"
        fill="#F492A0"
      />
      <path
        d="M195.798 180C207.579 180 217.13 170.124 217.13 157.941C217.13 145.758 207.579 135.882 195.798 135.882C184.017 135.882 174.466 145.758 174.466 157.941C174.466 170.124 184.017 180 195.798 180Z"
        fill="#00386C"
      />
      <path
        d="M195.798 170.91C202.452 170.91 207.846 165.332 207.846 158.45C207.846 151.569 202.452 145.991 195.798 145.991C189.144 145.991 183.75 151.569 183.75 158.45C183.75 165.332 189.144 170.91 195.798 170.91Z"
        fill="white"
      />
      <path
        d="M166.152 126.55H194.925C206.9 126.55 216.596 136.585 216.596 148.972H166.152V126.55Z"
        fill="#689EEC"
      />
      <path
        d="M186.611 111.472L169.036 86.7467C167.509 84.5893 166.394 82.1168 165.764 79.523L165.691 79.1594L171.097 77.802C171.509 77.705 171.751 77.2687 171.654 76.8566L168.673 64.0818C168.285 62.4819 166.733 61.4881 165.158 61.8759L159.849 63.2092C155.462 64.3242 152.771 68.8814 153.862 73.4144C153.886 73.4871 153.91 73.5599 153.91 73.6326L159.122 95.6672L163.291 88.9768V148.972H99.0781V158.062C99.0781 162.547 102.593 166.159 106.908 166.159H158.637C164.164 166.159 168.988 163.056 171.582 158.426L187.774 131.834C191.338 125.41 190.877 117.411 186.611 111.472Z"
        fill="#689EEC"
      />
      <path
        d="M177.109 114.017C176.939 113.387 176.745 112.83 176.527 112.369L163.267 84.9287C158.031 87.5224 155.632 93.8492 157.765 99.4488L160.771 107.351C162.492 111.884 163.388 116.708 163.388 121.58V129.556C163.388 140.27 154.977 148.972 144.626 148.972H124.725V148.924L68.0747 143.785V157.578H99.0784H124.725H154.177C159.607 157.578 166.661 152.342 170.685 144.706C174.612 137.216 176.624 128.028 177.521 122.986C178.03 120.005 177.933 116.926 177.109 114.017Z"
        fill="#508EE7"
      />
      <path
        d="M124.725 148.997L39.4949 141.264C38.7919 141.191 38.2828 140.585 38.3313 139.858C38.404 138.67 38.5495 136.949 38.7434 135.907C41.119 123.132 51.9787 113.484 64.9959 113.484H119.586C121.84 113.484 123.246 116.054 122.082 118.042L118.81 123.641C114.616 130.816 115.78 140.028 121.598 145.845L124.725 148.997Z"
        fill="#00386C"
      />
      <path
        opacity="0.17"
        d="M124.725 113.484H64.9961C52.2941 113.484 41.6767 122.647 38.9375 134.913C38.5012 136.901 38.2588 138.985 38.2588 141.143L83.1765 144.779C83.1765 144.779 76.3407 141.773 76.3407 125.629C76.3407 113.29 92.9454 113.193 92.9454 113.193"
        fill="white"
      />
      <path
        d="M92.7515 113.484H62.9356C53.1177 113.193 40.4162 122.647 38.6951 134.913C38.4042 136.901 38.2588 138.985 38.2588 141.143L65.4082 143.324C65.4082 143.324 61.0206 141.991 62.2812 125.629C63.2265 113.339 72.7531 113.193 72.7531 113.193"
        fill="#689EEC"
      />
      <path
        d="M61.0206 180C72.8018 180 82.3523 170.124 82.3523 157.941C82.3523 145.758 72.8018 135.882 61.0206 135.882C49.2395 135.882 39.689 145.758 39.689 157.941C39.689 170.124 49.2395 180 61.0206 180Z"
        fill="#00386C"
      />
      <path
        d="M61.0207 170.91C67.6744 170.91 73.0682 165.332 73.0682 158.451C73.0682 151.57 67.6744 145.991 61.0207 145.991C54.367 145.991 48.9731 151.57 48.9731 158.451C48.9731 165.332 54.367 170.91 61.0207 170.91Z"
        fill="white"
      />
      <path
        d="M93.8909 164.802H62.1115C58.33 164.802 55.2515 161.626 55.2515 157.699C55.2515 153.772 58.33 150.597 62.1115 150.597H93.9151C97.6966 150.597 100.775 153.772 100.775 157.699C100.775 161.626 97.6966 164.802 93.8909 164.802Z"
        fill="#689EEC"
      />
      <path
        d="M126.567 120.417C126.713 121.532 125.84 122.526 124.749 122.502L81.4798 122.041C77.4559 121.993 73.844 119.278 72.3411 115.157L68.8262 105.461C67.2264 101.025 69.9898 96.2252 74.3046 95.9585L115.732 94.795C120.119 94.6738 123.901 98.2372 124.483 103.013L126.567 120.417Z"
        fill="#689EEC"
      />
      <path
        d="M138.836 12.1933C138.836 12.1933 130.348 2.48582 119.365 8.31029C106.494 18.3662 112.567 32.4042 124.097 37.1611L137.138 17.5697L139.211 18.2759L138.836 12.1933Z"
        fill="#FFA87B"
      />
      <path
        d="M120.496 29.3916L116.652 39.6964L122.393 41.0405L126.137 30.437L120.496 29.3916Z"
        fill="#D97B4B"
      />
      <path
        d="M139.194 17.4197C139.774 22.0648 137.62 28.5449 137.62 28.5449L139.967 29.889C139.967 29.889 140.067 31.3825 138.769 31.9301L137.021 34.3694L131.38 30.9344C131.38 30.9344 130.082 34.469 134.924 36.958C134.924 36.958 132.778 40.9406 127.586 40.6917C127.586 40.6917 119.198 38.7502 120.446 29.441C120.446 29.441 118.949 28.5947 119.348 25.3589C119.797 21.675 122.543 20.4305 124.34 21.4759C126.138 22.5213 126.038 24.7117 126.038 24.7117C126.038 24.7117 131.18 25.5082 132.828 15.8008L139.194 17.4197Z"
        fill="#FFA87B"
      />
      <path
        d="M125.488 67.1749V48.6062C125.488 46.0176 123.391 43.9268 120.795 43.9268C118.199 43.9268 116.103 46.0176 116.103 48.6062V70.0124C116.103 73.3478 118.798 76.0858 122.193 76.0858H154.345V68.5687H126.936C126.137 68.519 125.488 67.9216 125.488 67.1749Z"
        fill="#FA7071"
      />
      <path
        d="M120.429 149.615H122.443L125.539 149.665L139.018 150.063C138.569 148.57 137.371 147.375 135.873 146.977L130.88 145.533L129.383 143.691L135.324 134.681L124.221 133.319L120.429 149.615Z"
        fill={process.env.REACT_APP_COLOR_SECONDARY || "#FFE570"}
      />
      <path
        d="M133.926 86.5908C124.84 86.5908 109.064 86.5908 109.064 86.5908L104.77 104.462C121.045 104.462 130.531 105.807 130.531 105.807L124.74 137.916H129.632L144.31 95.1035C144.31 95.1035 147.505 86.5908 133.926 86.5908Z"
        fill={process.env.REACT_APP_COLOR_SECONDARY || "#FFE570"}
      />
      <path
        d="M123.392 65.2345C124.91 60.01 127.242 52.7012 128.983 47.1139L131.18 41.5383L121.986 36.5809L112.209 35.7139C112.209 35.7139 112.708 38.1034 114.755 39.2484C114.755 39.2484 110.761 42.534 107.765 48.1593C104.92 53.5855 94.9348 82.1105 94.9348 82.1105L89.1436 86.5909H114.755L123.392 65.2345Z"
        fill={process.env.REACT_APP_COLOR_SECONDARY || "#FFE570"}
      />
      <path
        d="M152.198 72.3525H156.791C158.688 72.3525 160.186 73.846 160.186 75.7377V81.5124C160.186 83.3045 158.738 84.6984 156.99 84.6984H155.942C154.694 84.6984 153.695 83.7028 153.695 82.4582L156.391 82.0102C156.791 81.612 156.99 81.1141 156.99 80.5665C156.99 79.4216 156.092 78.5255 154.944 78.5255H152.248V72.3525H152.198Z"
        fill="#FFA87B"
      />
      <path
        d="M155.493 82.5085C156.817 82.5085 157.889 81.4387 157.889 80.119C157.889 78.7993 156.817 77.7295 155.493 77.7295C154.17 77.7295 153.097 78.7993 153.097 80.119C153.097 81.4387 154.17 82.5085 155.493 82.5085Z"
        fill="#00386C"
      />
      <path
        d="M123.392 69.8145V51.2459C123.392 48.6572 121.295 46.5664 118.699 46.5664C116.103 46.5664 114.006 48.6572 114.006 51.2459V72.6521C114.006 75.9874 116.702 78.7254 120.097 78.7254H152.248V71.2084H124.839C123.991 71.2084 123.392 70.5612 123.392 69.8145Z"
        fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
      />
      <path
        d="M124.69 25.0108C124.69 25.0108 123.491 21.3767 121.395 24.2641"
        stroke="#142530"
        strokeWidth="0.595"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.416 57.5134L131.706 56.4225C135.657 55.4287 139.584 57.5861 140.457 61.2706C141.33 64.9552 138.833 68.761 134.857 69.7548L131.949 70.4821C130.009 70.9669 128.07 69.9003 127.634 68.0822L125.719 60.0101C125.476 58.9436 126.228 57.8043 127.416 57.5134Z"
        fill="#D0393B"
      />
      <path
        d="M153.619 72.0087C153.45 72.0087 153.304 71.9117 153.207 71.7663L148.335 63.0397C147.438 61.4156 145.572 60.5914 143.826 61.052L136.433 62.9185C136.191 62.9912 135.924 62.8215 135.875 62.5549C135.803 62.2882 135.972 62.0216 136.215 61.9731L143.608 60.1066C145.766 59.549 148.044 60.5671 149.159 62.5549L154.032 71.2815C154.153 71.5239 154.08 71.8148 153.862 71.936C153.765 71.9844 153.692 72.0087 153.619 72.0087Z"
        fill="#D0393B"
      />
      <path
        d="M157.908 150.804H128.961L129.358 108.847L159.096 108.299L157.908 150.804Z"
        fill="#FA7071"
      />
      <path
        d="M158.822 117.714L156.262 114.392L153.611 114.057L155.836 126.336L156.079 126.367L158.639 124.386L158.822 117.714Z"
        fill="#C3CFCE"
      />
      <path
        d="M159.096 108.299L162.143 147.757L157.908 150.804L159.096 108.299Z"
        fill="#2E3054"
      />
      <path
        d="M150.016 114.027C150.016 114.423 149.711 114.728 149.315 114.728C148.919 114.728 148.614 114.423 148.614 114.027C148.614 113.631 148.919 113.326 149.315 113.326C149.711 113.326 150.016 113.631 150.016 114.027Z"
        fill="#F9E0E9"
      />
      <path
        d="M136.396 113.935C136.396 114.331 136.092 114.636 135.695 114.636C135.299 114.636 134.995 114.331 134.995 113.935C134.995 113.539 135.299 113.234 135.695 113.234C136.092 113.234 136.396 113.57 136.396 113.935Z"
        fill="#F9E0E9"
      />
      <path
        d="M152.332 115.611C152.118 115.763 152.088 116.037 152.24 116.251C152.393 116.464 152.667 116.494 152.88 116.342C153.093 116.19 153.124 115.916 152.972 115.702C152.819 115.489 152.545 115.458 152.332 115.611ZM152.789 116.342C152.636 116.434 152.454 116.434 152.362 116.281C152.271 116.129 152.271 115.946 152.423 115.855C152.575 115.763 152.758 115.763 152.85 115.916C152.941 116.037 152.911 116.251 152.789 116.342Z"
        fill="#C3CFCE"
      />
      <path
        d="M153.307 113.996L150.564 116.129L150.26 118.688L155.775 125.818L161.381 121.492L155.836 114.331L153.307 113.996ZM152.819 116.22C152.667 116.312 152.484 116.312 152.393 116.159C152.301 116.038 152.301 115.824 152.454 115.733C152.606 115.641 152.789 115.641 152.88 115.794C152.972 115.916 152.941 116.099 152.819 116.22Z"
        fill="white"
      />
      <path
        d="M152.24 115.458C151.966 115.672 151.905 116.098 152.118 116.372C152.332 116.647 152.758 116.708 153.033 116.494C153.307 116.281 153.368 115.885 153.154 115.58C152.911 115.306 152.515 115.245 152.24 115.458ZM152.819 116.22C152.667 116.311 152.484 116.311 152.393 116.159C152.301 116.037 152.301 115.824 152.454 115.733C152.606 115.641 152.789 115.641 152.88 115.794C152.972 115.915 152.941 116.098 152.819 116.22Z"
        fill="#3D3942"
      />
      <path
        d="M149.132 111.132L149.011 111.071L148.95 111.193C148.614 111.711 149.224 112.99 149.498 113.508C149.864 114.209 150.504 115.123 151.113 115.733L151.326 115.55C150.808 115.062 150.229 114.27 149.742 113.387C149.285 112.564 149.072 111.833 149.132 111.497C149.864 111.985 153.094 114.27 152.515 115.763C152.454 115.915 152.393 115.976 152.332 116.007C152.332 116.068 152.332 116.098 152.362 116.159C152.362 116.19 152.362 116.22 152.362 116.251C152.362 116.281 152.362 116.281 152.362 116.312C152.515 116.251 152.667 116.159 152.758 115.885C153.52 113.935 149.315 111.223 149.132 111.132Z"
        fill="#D2E0E9"
      />
      <path
        d="M149.589 114.179H149.254C149.254 114.179 149.254 113.935 149.254 113.509C149.254 113.235 149.224 112.869 149.224 112.442C149.041 108.938 148.005 100.803 142.795 100.59C141.576 100.529 140.479 100.955 139.565 101.839C136.091 105.16 135.969 113.966 135.969 114.057H135.634C135.634 113.692 135.756 105.038 139.352 101.595C140.357 100.651 141.515 100.193 142.825 100.254C148.34 100.468 149.376 108.816 149.589 112.412C149.62 112.838 149.62 113.204 149.62 113.478C149.589 113.935 149.589 114.179 149.589 114.179Z"
        fill="#0B1238"
      />
      <path
        d="M155.531 117.104C155.866 116.8 156.262 116.861 156.658 117.287C157.024 117.714 157.055 118.11 156.689 118.415L155.562 119.39C155.226 119.694 154.83 119.633 154.434 119.207C154.068 118.78 154.038 118.384 154.404 118.08L155.531 117.104ZM154.769 118.537C154.617 118.658 154.617 118.811 154.739 118.933C154.861 119.055 154.983 119.085 155.135 118.963L156.293 117.927C156.445 117.805 156.445 117.653 156.323 117.531C156.201 117.379 156.079 117.379 155.927 117.501L154.769 118.537ZM158.121 118.994L158.487 119.39L153.581 120.669L153.215 120.273L158.121 118.994ZM156.171 120.334C156.506 120.03 156.902 120.091 157.298 120.517C157.664 120.944 157.694 121.34 157.329 121.644L156.201 122.62C155.866 122.924 155.47 122.863 155.074 122.437C154.708 122.01 154.678 121.614 155.044 121.309L156.171 120.334ZM155.409 121.766C155.257 121.888 155.257 122.041 155.379 122.162C155.501 122.284 155.653 122.315 155.775 122.193L156.933 121.157C157.085 121.035 157.085 120.883 156.963 120.761C156.841 120.609 156.719 120.609 156.567 120.73L155.409 121.766Z"
        fill="#2E3054"
      />
      <path
        d="M149.742 113.326C149.559 112.991 149.285 112.473 149.193 111.924C149.102 111.924 149.102 112.32 149.041 112.381C149.193 112.747 149.437 113.326 149.529 113.57C149.559 113.631 149.589 113.692 149.62 113.753C149.711 113.722 149.772 113.631 149.833 113.57C149.803 113.539 149.742 113.387 149.742 113.326Z"
        fill="#D2E0E9"
      />
      <path
        d="M135.909 114.027C135.909 114.118 135.817 114.21 135.726 114.21C135.634 114.21 135.543 114.118 135.543 114.027C135.543 113.936 135.634 113.844 135.726 113.844C135.817 113.875 135.909 113.936 135.909 114.027Z"
        fill="#0B1238"
      />
      <path
        d="M149.589 114.149C149.589 114.24 149.498 114.331 149.406 114.331C149.315 114.331 149.224 114.24 149.224 114.149C149.224 114.057 149.315 113.966 149.406 113.966C149.498 113.966 149.589 114.057 149.589 114.149Z"
        fill="#0B1238"
      />
      <path
        d="M139.567 86.5897C130.481 86.5897 114.705 86.5897 114.705 86.5897L98.6793 82.1094C98.0303 83.9015 94.2361 83.9513 93.7368 85.1958C90.6415 93.5094 88.4948 104.461 104.77 104.461C116.053 104.461 127.286 103.217 133.127 102.42C135.174 102.171 136.822 103.964 136.422 105.955L130.731 132.737L138.419 133.683L148.853 98.6867C148.903 98.6867 153.147 86.5897 139.567 86.5897Z"
        fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
      />
      <path
        d="M125.738 149.167L128.384 149.565L131.579 149.615L145.408 150.063C144.959 148.519 143.711 147.325 142.163 146.877L137.071 145.383L135.523 143.491L138.916 133.319L130.384 132.371L125.738 149.167Z"
        fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
      />
      <path
        d="M142.701 22.6496C139.913 22.6569 134.173 18.4138 131.652 16.2913L131.646 13.9688L137.452 13.9536L142.69 18.5851C143.855 19.9369 145.488 22.6423 142.701 22.6496Z"
        fill="#00386C"
      />
      <path
        d="M129.5 21.5C129.1 20.7 131 19 130 18.5L126.5 17L115.5 34C115.5 34.5 115.5 35.5 119.5 36C125.776 36.7845 130.5 23.5 129.5 21.5Z"
        fill="#00386C"
      />
      <path
        d="M118.871 35.4192C117.013 35.8837 115.18 35.1138 111.323 30.774C106.678 25.5482 106.858 12.2288 117.71 6.96721C136.871 -2.32313 147.903 24.3869 140.355 19.7418C132.807 15.0966 128.161 17.9997 128.161 22.6449C128.161 27.2901 121.194 34.8386 118.871 35.4192Z"
        fill="#D0393B"
      />
      <path
        d="M143.399 18.9779C143.353 18.9507 143.306 18.9222 143.258 18.8924C133.968 13.0861 127.581 15.9461 127 23.8065C127 27.8655 121.519 33.8485 118.291 35.4195C119.409 36.0367 118.553 35.9559 119.452 35.7312C121.774 35.1506 128.742 27.6021 128.742 22.9569C128.742 18.3118 133.387 15.4086 140.936 20.0538C142.69 21.1333 143.472 20.488 143.399 18.9779Z"
        fill="#AA2527"
      />
      <path
        d="M118.291 16.8387C114.226 19.1613 113.645 26.71 110.161 24.3875C108.42 23.2262 108.337 19.1616 111.323 13.9357C118.928 0.625844 137.452 7.54887 136.291 11.6132C135.36 14.8684 122.355 14.5161 118.291 16.8387Z"
        fill={process.env.REACT_APP_COLOR_SECONDARY || "#FFE570"}
      />
      <path
        d="M173.133 68.7846C174.2 73.3176 173.303 77.4385 171.097 77.996C168.916 78.5535 166.249 75.3295 165.183 70.7965C164.116 66.2636 165.013 62.1427 167.219 61.5851C169.425 61.0276 172.067 64.2516 173.133 68.7846Z"
        fill="#9AC3FF"
      />
      <path
        d="M170.637 65.7305C171.243 68.3485 170.734 70.7241 169.473 71.0392C168.213 71.3543 166.686 69.4878 166.08 66.8941C165.474 64.2761 165.983 61.9005 167.243 61.5854C168.504 61.2702 170.031 63.1125 170.637 65.7305Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.7268 1.50482e-06C57 0.00145062 63.1319 1.86299 68.3472 5.34925C73.5624 8.83551 77.6269 13.7899 80.0265 19.586C82.4262 25.3821 83.0533 31.7596 81.8286 37.9121C80.6038 44.0646 77.5823 49.7158 73.1459 54.1511C68.7096 58.5864 63.0577 61.6067 56.9049 62.83C50.7521 64.0533 44.3748 63.4247 38.5792 61.0237C32.7837 58.6227 27.8303 54.5572 24.3452 49.3411C20.8601 44.125 19 37.9927 19 31.7195C18.9997 27.5533 19.8202 23.4279 21.4146 19.5789C23.009 15.7299 25.346 12.2327 28.2923 9.2871C31.2386 6.34152 34.7363 4.00525 38.5857 2.41174C42.435 0.818231 46.5606 -0.00128179 50.7268 1.50482e-06Z"
        fill="#689EEC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.8989 15.6618L48.1524 37.0059L39.7798 28.5015C39.0839 27.8254 38.151 27.4487 37.1808 27.4518C36.2106 27.455 35.2801 27.8379 34.5887 28.5185C33.8972 29.1991 33.4997 30.1233 33.4811 31.0934C33.4626 32.0634 33.8246 33.0022 34.4896 33.7087L46.3181 45.7253C46.6811 46.0946 47.1176 46.3835 47.5994 46.5732C48.0811 46.763 48.5974 46.8494 49.1147 46.8268C50.6387 46.8122 51.9674 45.7229 52.7172 44.2306L65.977 17.8722C66.8953 16.0501 65.4885 13.8324 63.5346 13.2316C61.5807 12.6308 59.8026 13.8471 58.894 15.674"
        fill="#FEFEFE"
      />
    </svg>
  );
}

export default PesanSekarang;
