import React from "react";

const Email = () => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.90808 0.980006C2.29768 0.980006 1.74825 1.13336 1.36433 1.51626C0.980418 1.89915 0.819946 2.45369 0.819946 3.06001V12.94C0.819946 13.5463 0.978385 14.0938 1.36433 14.4756C1.75028 14.8575 2.30886 15.02 2.92433 15.02H21.1082C21.7105 15.02 22.263 14.8534 22.6438 14.4675C23.0247 14.0805 23.1801 13.5311 23.1801 12.9156V3.03562C23.1801 2.43842 23.0227 1.89508 22.6438 1.51623C22.265 1.13842 21.7216 0.97998 21.1244 0.97998L2.90808 0.980006ZM1.86808 2.89751L8.11614 7.96751L1.8842 13.2731C1.85881 9.66141 1.85983 6.18495 1.86795 2.89751H1.86808ZM22.132 2.89751C22.1401 6.44521 22.1401 10.0852 22.1076 13.2731L15.8837 7.96751L22.132 2.89751ZM8.94502 8.63363C9.62144 9.17801 10.309 9.75183 10.9844 10.283C11.6283 10.605 12.3717 10.605 13.0157 10.283C13.6961 9.75082 14.3786 9.18308 15.0551 8.63363L21.3195 13.9717C15.1201 13.9849 8.29219 13.9717 2.68061 13.9636L8.94502 8.63363Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
  </svg>
);
export default Email;
