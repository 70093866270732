/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import currencyFormatter from "../../utilities/currency-formatter";

import {
  Paper,
  Container,
  CssBaseline,
  Typography,
  InputBase
} from "@material-ui/core";

import AppBar from "../../components/app-bar";
import addVoucher from "../../vector/addVoucher.svg";

import Loading from "../../components/loading";
import { calculateOrder } from "../../services/orders";
import { useLocation } from "react-router-dom";
import { withTransaction } from "@elastic/apm-rum-react";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Voucher(props) {
  const { classes, history } = props;
  const [isLoading, setIsLoading] = useState(true);
  console.log(props.usedVoucher);
  const [input, setInput] = useState(" ");
  const query = useQuery();
  const tabs = query.get("tabs");

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setInput(localStorage.getItem("usedVoucher") || "");
  }, []);
  console.log(input);
  return (
    <React.Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <Container
          elevation={0}
          component="main"
          maxWidth="xs"
          className={classes.container}
        >
          <CssBaseline />
          {/* <AppBar title="Kode Promo" customBack={() => props.goBack()} /> */}
          <AppBar title="Kode Promo" goBack={true} />

          <Paper elevation={0} className={classes.body}>
            <img
              src={addVoucher}
              alt="addVoucher"
              className={classes.addVoucher}
            />
            <div className={classes.content}>
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 16,
                  fontWeight: 600
                }}
              >
                Punya Kode Voucher?
              </Typography>
              <Typography
                style={{
                  marginTop: 16,
                  fontSize: 12,
                  fontWeight: 400,
                  textAlign: "center"
                }}
              >
                Masukkan kode vouchernya dibawah ini!
              </Typography>
            </div>
            <div className={classes.searchWrapper}>
              <div className={classes.searchDiv}>
                <InputBase
                  value={input}
                  placeholder="Kode voucher"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  onChange={event => setInput(event.target.value)}
                />
              </div>
            </div>
            <div
              style={{
                padding: "165px 16px 0px"
              }}
            >
              <div
                className={classes.button}
                style={{
                  backgroundColor:
                    input?.length > 0
                      ? process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                      : "#A6A6A6"
                }}
                onClick={() => {
                  // props.goBack(input);
                  localStorage.setItem("usedVoucher", input);
                  localStorage.setItem("isUsed", "true");
                  tabs === "1"
                    ? history.push("/cart-shipment?tabs=1")
                    : history.push("/cart-shipment?tabs=0");
                }}
              >
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: process.env.REACT_APP_COLOR_FONT || "#000000"
                  }}
                >
                  Submit
                </Typography>
              </div>
            </div>
          </Paper>
        </Container>
      )}
    </React.Fragment>
  );
}
export default withTransaction("Voucher", "component")(Voucher);
