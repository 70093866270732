import React from "react";

const MasukEmail = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M120 60C120 93.1366 93.1366 120 60 120C26.8634 120 0 93.1366 0 60C0 26.8634 26.8634 0 60 0C93.1366 0 120 26.8634 120 60Z"
      fill={process.env.REACT_APP_COLOR_SECONDARY || "#FFE570"}
    />
    <path
      d="M108.168 60.084C108.168 86.6473 86.6473 108.168 60.084 108.168C33.5208 108.168 12 86.6473 12 60.084C12 33.5208 33.5208 12 60.084 12C86.6473 12 108.168 33.5208 108.168 60.084Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.207 65.9957C52.5405 66.3956 51.6925 65.9155 51.6925 65.1382V62.5581H44C43.4477 62.5581 43 62.1104 43 61.5581V59.2119C43 58.6596 43.4477 58.2119 44 58.2119H51.6925V55.6318C51.6925 54.8545 52.5405 54.3744 53.207 54.7743L61.129 59.5275C61.7763 59.9159 61.7763 60.8541 61.129 61.2425L53.207 65.9957ZM77.77 44C77.77 43.4477 77.3223 43 76.77 43H52.6925C52.1402 43 51.6925 43.4477 51.6925 44V48.5194C51.6925 49.0717 52.1402 49.5194 52.6925 49.5194H55.0387C55.591 49.5194 56.0387 49.0717 56.0387 48.5194V48.3462C56.0387 47.794 56.4865 47.3463 57.0387 47.3463H71.0095C71.9004 47.3463 72.3466 48.4234 71.7166 49.0534L67.1973 53.5727C67.0097 53.7603 66.9044 54.0146 66.9044 54.2798V72.4237C66.9044 72.976 66.4567 73.4237 65.9044 73.4237H57.0387C56.4865 73.4237 56.0387 72.976 56.0387 72.4237V72.2506C56.0387 71.6983 55.591 71.2506 55.0387 71.2506H52.6925C52.1402 71.2506 51.6925 71.6983 51.6925 72.2506V76.77C51.6925 77.3223 52.1402 77.77 52.6925 77.77H66.9044H76.77C77.3223 77.77 77.77 77.3223 77.77 76.77V44Z"
      fill="white"
    />
  </svg>
);
export default MasukEmail;
