const styles = theme => ({
  card: {
    minWidth: 150,
    // height: 300,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
    borderRadius: 7,
    display: "flex",
    flexDirection: "column",
    marginRight: "16px",
    cursor: "pointer"
  },
  image: {
    height: 129,
    width: "100%",
    borderRadius: "7px 7px 0 0",
    objectFit: "cover"
  },
  badge: {
    width: "50%",
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
    borderRadius: "5px 0 5px",
    padding: "5px 3px",
    textAlign: "center"
  },
  badgeText: {
    fontSize: 12,
    fontWeight: 600,
    color: process.env.REACT_APP_COLOR_FONT || "#000000"
  },
  content: {
    // height: 150,
    width: "100%",
    borderRadius: "0 0 7px 7px",
    objectFit: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 16
  },
  titleText: {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: 30
  },
  regularPriceText: {
    fontSize: 10,
    color: "#252525"
  },
  salePriceText: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#25282B"
  },
  unitText: {
    fontSize: 10,
    color: "#777777"
  },
  button: {
    height: 30,
    width: "100%",
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
    color: process.env.REACT_APP_COLOR_FONT || "#000000",
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    fontSize: "10px !important",
    "&:hover": {
      backgroundColor: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
    },
    fontFamily:
      process.env.REACT_APP_FONT_URL_BUTTON ||
      "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
  },
  cardMediaDigital: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    width: "100%",
    padding: "5px 10px",
    textAlign: "center",
    fontWeight: 600,
    fontSize: 8,
    borderRadius: "0px 0px 5px 5px"
  },
  cardMedia: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  }
});

export default styles;
