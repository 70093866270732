import React from "react";

const Shipping = () => (
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4062 0.344238C12.7935 0.666138 13.3241 1.47729 13.9894 2.59375H11.4062V0.344238ZM3.29932 2.6875C3.57092 2.6875 3.76807 2.8186 3.76807 3.125C3.76807 3.38342 3.55896 3.59302 3.30066 3.59375H0.46875C0.209839 3.59375 0 3.80371 0 4.0625C0 4.32141 0.209839 4.53125 0.46875 4.53125H4.6875C4.94885 4.53125 5.15808 4.74097 5.15808 5C5.15808 5.25891 4.94824 5.46875 4.68933 5.46875H0.46875C0.209839 5.46875 0 5.67859 0 5.9375C0 6.19641 0.209839 6.40625 0.46875 6.40625H1.5V7.8125C1.5 8.07141 1.70984 8.28125 1.96875 8.28125H2.91101C3.07898 9.10864 3.81006 9.71875 4.67188 9.71875C5.53369 9.71875 6.26477 9.10864 6.43274 8.28125H11.1923C11.3602 9.10864 12.0913 9.71875 12.9531 9.71875C13.8149 9.71875 14.546 9.10864 14.714 8.28125H15.5312C15.7902 8.28125 16 8.07141 16 7.8125V5C16 3.62463 14.5493 3.53333 14.548 3.53125H10.9375C10.6786 3.53125 10.4688 3.32141 10.4688 3.0625V0.25H1.96875C1.70984 0.25 1.5 0.459839 1.5 0.71875V1.75H0.9375C0.678589 1.75 0.46875 1.95984 0.46875 2.21875C0.46875 2.47766 0.678589 2.6875 0.9375 2.6875H3.29932ZM13.5608 7.31421C13.8964 7.64978 13.8964 8.19409 13.5608 8.52966C13.021 9.06946 12.0938 8.68542 12.0938 7.92188C12.0938 7.15845 13.021 6.77441 13.5608 7.31421V7.31421ZM5.27954 7.31421C5.61511 7.64978 5.61511 8.19409 5.27954 8.52966C4.73975 9.06946 3.8125 8.68542 3.8125 7.92188C3.8125 7.15845 4.73975 6.77441 5.27954 7.31421V7.31421Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
  </svg>
);
export default Shipping;
