/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Button, ButtonGroup, CardMedia } from "@material-ui/core";
import currencyFormatter from "../../utilities/currency-formatter";
import { CartContext } from "../../context/cart";

function Component(props) {
  const { classes, top } = props;
  const { addCart, increaseCart, decreaseCart, cart, cartUpdated } = useContext(
    CartContext
  );
  const [qty, setQty] = useState(0);

  const discountPercent =
    top.promoPriceType === "PERCENTAGE"
      ? top.promoPrice
      : (100 - (top.promoPrice / top.regularPrice) * 100) % 1 === 0
      ? 100 - (top.promoPrice / top.regularPrice) * 100
      : (100 - (top.promoPrice / top.regularPrice) * 100).toFixed(0);

  useEffect(() => {
    const selectedItem = cart.find(item => item.id === top.id);
    if (selectedItem) {
      setQty(selectedItem.total);
    } else {
      setQty(0);
    }
  }, [cartUpdated]);

  const cardMedia = () => {
    if (top.isPromo && top.size === null) {
      return "space-between";
    }
    if (top.isPromo) {
      return "flex-start";
    }
    if (top.size === null) {
      return "flex-end";
    }
  };

  return (
    <>
      <div className={classes.card}>
        <CardMedia
          image={
            top.image.url ? top.image.url : "https://via.placeholder.com/150"
          }
          className={classes.image}
          onClick={() => {
            props.history.push(`/product/${top.id}?from=/`);
          }}
        >
          <div
            className={classes.cardMedia}
            style={{ justifyContent: cardMedia() }}
          >
            {top.isPromo && (
              <span className={classes.badge}>
                <span className={classes.badgeText}>
                  Disk. {discountPercent}%
                </span>
              </span>
            )}
            {top.size === null && (
              <div className={classes.cardMediaDigital}>Produk Digital</div>
            )}
          </div>
        </CardMedia>
        <div className={classes.content}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%"
            }}
            onClick={() => {
              props.history.push(`/product/${top.id}?from=/`);
            }}
          >
            <div className={classes.titleText}>{top.name}</div>
            <div
              style={{
                justifyContent: "center",
                height: "100%",
                marginBottom: 8
              }}
            >
              {!top.isPromo ? (
                <>
                  <br />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className={classes.salePriceText}>
                      {currencyFormatter.format(top.regularPrice)}
                    </div>
                    <b style={{ color: "#C7C7C9", fontSize: 10 }}>
                      {top.unit ? `/${top.unit}` : ""}
                    </b>
                  </div>
                </>
              ) : (
                <>
                  <strike className={classes.regularPriceText}>
                    {currencyFormatter.format(top.regularPrice)}
                  </strike>
                  <br />
                </>
              )}

              {top.isPromo ? (
                <>
                  <div className={classes.salePriceText}>
                    {currencyFormatter.format(top.price)}
                    <b style={{ color: "#C7C7C9", fontSize: 10 }}>
                      {top.unit ? `/${top.unit}` : ""}
                    </b>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          {qty > 0 ? (
            <ButtonGroup
              size="small"
              aria-label="Small outlined button group"
              style={{
                justifyContent: "flex-end",
                width: "-webkit-fill-available",
                marginRight: 1
              }}
            >
              <Button
                size="small"
                onClick={event => {
                  decreaseCart(top);
                }}
                style={{
                  color: "#153b50",
                  borderRadius: 4,
                  maxWidth: 30,
                  minWidth: 30,
                  maxHeight: 30,
                  minHeight: 30,
                  padding: 1,
                  border: "1px solid silver"
                }}
              >
                -
              </Button>

              <Button
                size="small"
                style={{
                  border: "0",
                  fontWeight: "bold",
                  fontSize: 12,
                  maxWidth: 58,
                  minWidth: 58,
                  maxHeight: 30,
                  minHeight: 30,
                  padding: 1
                }}
              >
                {qty || 0}
              </Button>
              <Button
                size="small"
                onClick={event => {
                  increaseCart(top);
                }}
                style={{
                  color: process.env.REACT_APP_COLOR_FONT || "#000000",
                  backgroundColor:
                    process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                  border: `1px solid ${process.env.REACT_APP_COLOR_PRIMARY ||
                    "#FFD101"}`,
                  borderRadius: 4,
                  maxWidth: 30,
                  minWidth: 30,
                  maxHeight: 30,
                  minHeight: 30,
                  padding: 1,
                  fontWeight: "bold"
                }}
              >
                +
              </Button>
            </ButtonGroup>
          ) : (
            <div>
              {top.onStock ? (
                <Button
                  onClick={event => {
                    addCart(top);
                  }}
                  className={classes.button}
                >
                  <b>Tambahkan</b>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled
                  style={{
                    borderRadius: 4,
                    width: "100%",
                    padding: 0,
                    height: 30,
                    fontSize: 8
                  }}
                >
                  <b>Stok Habis</b>
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default withRouter(Component);
