import React from "react";

const Daftar = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M120 60C120 93.1366 93.1366 120 60 120C26.8634 120 0 93.1366 0 60C0 26.8634 26.8634 0 60 0C93.1366 0 120 26.8634 120 60Z"
      fill={process.env.REACT_APP_COLOR_SECONDARY || "#FFE570"}
    />
    <path
      d="M108.084 60.0001C108.084 86.5633 86.5632 108.084 60 108.084C33.4367 108.084 11.9159 86.5633 11.9159 60.0001C11.9159 33.4368 33.4367 11.916 60 11.916C86.5632 11.916 108.084 33.4368 108.084 60.0001Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
    <path
      d="M45.4373 46.4604H58.6549V48.9617H45.4933V75.2849H71.8085V62.1233H74.3098V75.3329C74.3098 75.6605 74.2459 75.9802 74.126 76.2679C73.9981 76.5715 73.8223 76.8353 73.5986 77.059C73.3748 77.2828 73.1031 77.4666 72.8074 77.5864C72.5197 77.7063 72.2001 77.7702 71.8724 77.7702H45.4373C45.1097 77.7702 44.79 77.7063 44.5024 77.5864C44.1987 77.4586 43.935 77.2828 43.7112 77.059C43.4875 76.8353 43.3037 76.5635 43.1838 76.2679C43.0639 75.9802 43 75.6605 43 75.3329V48.9138C43 48.5861 43.0639 48.2665 43.1838 47.9788C43.3117 47.6751 43.4875 47.4114 43.7112 47.1877C43.935 46.9639 44.2067 46.7801 44.5024 46.6602C44.798 46.5324 45.1097 46.4604 45.4373 46.4604Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.2295 47.9626L55.4184 59.7817L53.1569 66.3745C52.9172 67.2455 53.5884 67.8928 54.4035 67.6211L60.9963 65.3596C66.8619 59.494 66.9418 59.4141 72.8074 53.5485C70.9455 51.6785 69.0915 49.8245 67.2295 47.9626ZM73.3908 52.9491L77.3864 48.9535C77.6421 48.6978 77.77 48.3621 77.77 48.0265C77.77 47.6909 77.6421 47.3552 77.3864 47.0995L73.6625 43.3836C73.4068 43.1279 73.0711 43 72.7355 43C72.3999 43 72.0642 43.1279 71.8085 43.3836C70.474 44.7181 69.1474 46.0447 67.8129 47.3792C69.6748 49.2332 71.5368 51.0952 73.3908 52.9491Z"
      fill="white"
    />
  </svg>
);

export default Daftar;
