import React, { useState, useEffect } from "react";
import {
  Container,
  makeStyles,
  CssBaseline,
  TextField,
  Paper,
  Typography,
  InputAdornment,
  InputBase,
  Button,
  Dialog,
  Divider,
  AppBar,
  Toolbar
} from "@material-ui/core";
// import AppBar from "../../components/app-bar";
import Skeleton from "@material-ui/lab/Skeleton";
import ReactFlagsSelect from "react-flags-select";
import CountryData from "../../utilities/country-code";
import BackButton from "@material-ui/icons/ArrowBackIos";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import {
  getProvinces,
  getCities,
  searchProvince,
  searchCity,
  createAddress,
  getUserAddrres
} from "../../services/address";
import { useHistory, useLocation } from "react-router-dom";
import Minimap from "./../../components/minimap/components";
import { withTransaction } from "@elastic/apm-rum-react";

const useStyles = makeStyles({
  container: {
    padding: 0,
    paddingTop: 64,
    backgroundColor: "#FAFAFA",
    borderLeft: "1px solid #f1f1f1",
    borderRight: "1px solid #f1f1f1",
    paddingBottom: 64
  },
  appBar: {
    left: "auto",
    right: "auto"
  },
  searchDiv: {
    height: 45,
    borderRadius: 8,
    backgroundColor: "#FAFAFA",
    padding: "12px 16px",
    display: "flex",
    alignItems: "center",
    margin: "0px 16px 16px"
  },
  searchIcon: {
    marginRight: 10
  },
  inputRoot: {
    marginLeft: 0,
    color: "inherit",
    width: "100%",
    height: "100%"
  },
  inputInput: {
    width: "100%",
    fontSize: "14px !important"
  },

  flagButton: {
    border: "unset",
    padding: "5px 0px",
    "&:focus": {
      outline: "unset"
    }
  },
  navButton: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    maxWidth: 444,
    padding: 16,
    backgroundColor: "white",
    borderLeft: "1px solid #f1f1f1",
    borderRight: "1px solid #f1f1f1",
    zIndex: 100
  },
  button: {
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      backgroundColor: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
    },
    color: process.env.REACT_APP_COLOR_FONT || "#000000",
    fontWeight: "bold",
    fontFamily: process.env.REACT_APP_FONT_FAMILY_BUTTON || "Open Sans"
  },
  noBorder: {
    border: "none"
  },
  input: {
    padding: 14
  },
  paperFullScreen: {
    maxWidth: 444,
    height: "75vh",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10
  },
  card: {
    display: "flex",
    alignItems: "flex-end"
  },

  list: {
    margin: "70px 0px 0px",
    overflow: "auto"
  }
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddAddress = () => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const lat = query.get("lat");
  const long = query.get("long");
  const [selectedCountryCode, setSelectedCountryCode] = useState("ID");
  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchProvinceData, setSearchProvinceData] = useState("");
  const [searchCityData, setSearchCityData] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [address, setAddress] = useState([]);
  const user = JSON.parse(localStorage.getItem("users"));
  const [isLoading, setIsLoading] = useState(false);
  const [isNeedCompleteAddress, setIsNeedCompleteAddress] = useState(
    localStorage.getItem("isNeedCompleteAddress")
  );
  const [disableSave, setDisableSave] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    label: "",
    provinceId: "",
    cityId: "",
    postcode: "",
    address: "",
    latitude: "",
    longitude: "",
    country: "indonesia"
  });

  const countryCodeNumber = CountryData[selectedCountryCode].secondary.slice(
    1,
    CountryData[selectedCountryCode].secondary.length
  );

  const validPhone = data?.phone
    ? data?.phone.slice(0, 1) === "0"
      ? countryCodeNumber + data?.phone.slice(1, data?.phone.length)
      : data?.phone.slice(0, 2) === countryCodeNumber
      ? data?.phone
      : countryCodeNumber + data?.phone
    : null;

  const handleClickOpen = key => {
    setOpen(key);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeProvice = e => {
    setSearchProvinceData(e.target.value);
    setIsLoading(true);
    if (e.target.value < 1) {
      resetProvince();
    }
  };
  const handleChangeCity = e => {
    setSearchCityData(e.target.value);
    setIsLoading(true);
    if (e.target.value < 1) {
      resetCity();
    }
  };

  const resetProvince = () => {
    setSearchProvinceData("");
    async function fetchAPI() {
      const res = await searchProvince("");
      setProvince(res.data);
      if (res.meta.statusCode === 200) {
        setIsLoading(false);
      }
    }
    fetchAPI();
  };

  const resetCity = () => {
    setSearchCityData("");
    async function fetchAPI() {
      const res = await searchCity(data.provinceId, "");
      setCity(res.data);
      if (res.meta.statusCode === 200) {
        setIsLoading(false);
      }
    }
    fetchAPI();
  };

  const handleSave = async () => {
    setIsLoading(true);

    const response = await createAddress({
      ...data,
      phone: (countryCodeNumber + data.phone).replace("+", ""),
      default: address.length < 1 ? true : false
    });

    if (response?.meta?.statusCode === 200 && address.length > 0) {
      setIsLoading(false);
      history.push("/cart-shipment/address");
      localStorage.removeItem("temp_data_address");
    } else {
      setIsLoading(false);
      history.push("/cart-shipment?tabs=1");
      localStorage.removeItem("temp_data_address");
    }
  };
  const handleSaveGuest = () => {
    localStorage.setItem("selectedAddress", JSON.stringify(data));
    localStorage.removeItem("temp_data_address");
    history.push("/cart-shipment?tabs=1");
  };

  const saveAddress = () => {
    if (user) {
      handleSave();
    } else {
      handleSaveGuest();
    }
  };

  const multipleData = newData => {
    setData({
      ...data,
      ...newData
    });
  };

  const changeData = (key, value) => {
    setData({ ...data, [key]: value });
  };

  useEffect(() => {
    setIsNeedCompleteAddress(localStorage.getItem("isNeedCompleteAddress"));
    async function fetchAPI() {
      const res = await getProvinces();
      if (user) {
        const listAddress = await getUserAddrres();
        setAddress(listAddress.data);
      }

      setProvince(res.data);
    }
    if (isNeedCompleteAddress === "true") {
      fetchAPI();
    }
    getCoordinates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cityAPI = async () => {
      const response = await getCities(data.provinceId);
      setCity(response.data);
    };
    if (isNeedCompleteAddress === "true" && selectedProvince) {
      cityAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProvince]);

  const getCoordinates = () => {
    const lat = query.get("lat");
    const long = query.get("long");

    if (lat && long) {
      // console.log({ ...data, latitude: lat, longitude: long });

      const localData = JSON.parse(localStorage.getItem("temp_data_address"));
      if (user) {
        setData({
          ...localData,
          latitude: lat,
          longitude: long,
          country: "Indonesia"
        });
        localStorage.setItem(
          "temp_data_address",
          JSON.stringify({
            country: "Indonesia",
            name: "",
            email: user ? user.email : "",
            phone: validPhone || "",
            label: "",
            provinceId: "",
            cityId: "",
            postcode: "",
            address: "",
            ...data,
            latitude: lat,
            longitude: long
          })
        );
      } else {
        setData({
          ...localData,
          latitude: lat,
          longitude: long,
          country: "Indonesia"
        });
        localStorage.setItem(
          "temp_data_address",
          JSON.stringify({
            country: "Indonesia",
            name: "",
            email: "",
            phone: validPhone || "",
            label: "",
            provinceId: "",
            cityId: "",
            postcode: "",
            address: "",
            ...data,
            latitude: lat,
            longitude: long,
            province: "",
            city: ""
          })
        );
      }
    } else {
      const localData = JSON.parse(localStorage.getItem("temp_data_address"));

      if (user) {
        setData({
          latitude: 0,
          longitude: 0,
          country: "Indonesia",
          name: "",
          email: user ? user.email : "",
          phone: validPhone || "",
          label: "",
          provinceId: "",
          cityId: "",
          postcode: "",
          address: "",

          ...localData
        });
        localStorage.setItem(
          "temp_data_address",
          JSON.stringify({
            latitude: 0,
            longitude: 0,
            country: "Indonesia",
            name: "",
            email: user ? user.email : "",
            phone: validPhone || "",
            label: "",
            provinceId: "",
            cityId: "",
            postcode: "",
            address: "",

            ...localData
          })
        );
      } else {
        setData({
          latitude: 0,
          longitude: 0,
          country: "Indonesia",
          name: "",
          email: "",
          phone: validPhone || "",
          label: "",
          provinceId: "",
          cityId: "",
          postcode: "",
          address: "",
          province: "",
          city: "",
          ...localData
        });
        localStorage.setItem(
          "temp_data_address",
          JSON.stringify({
            latitude: 0,
            longitude: 0,
            country: "Indonesia",
            name: "",
            email: "",
            phone: validPhone || "",
            label: "",
            provinceId: "",
            cityId: "",
            postcode: "",
            address: "",
            province: "",
            city: "",
            ...localData
          })
        );
      }
    }
  };

  const validateSave = () => {
    if (
      data.name?.length < 3 ||
      data.email?.length < 3 ||
      data.phone?.length < 5 ||
      data.label?.length < 1 ||
      data.provinceId?.length <= 1 ||
      data.cityId?.length <= 1 ||
      data.postcode?.length !== 5 ||
      data.address?.length < 3 ||
      data?.latitude === "" ||
      data?.longitude === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validateSaveSimple = () => {
    if (
      data.name?.length < 3 ||
      data.email?.length < 3 ||
      data.phone?.length < 5 ||
      data.address?.length < 3 ||
      data?.latitude === "" ||
      data?.longitude === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (isNeedCompleteAddress === "true") {
      setDisableSave(!validateSave());
    } else {
      setDisableSave(!validateSaveSimple());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (searchProvinceData) {
      const searchAPI = async () => {
        const res = await searchProvince(searchProvinceData);
        setProvince(res.data);
        if (res.meta.statusCode === 200) {
          setIsLoading(false);
        }
      };
      const timer = setTimeout(() => {
        searchAPI();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [searchProvinceData]);

  useEffect(() => {
    if (searchCityData) {
      const searchAPI = async () => {
        const response = await searchCity(data.provinceId, searchCityData);
        setCity(response.data);
        if (response.meta.statusCode === 200) {
          setIsLoading(false);
        }
      };
      const timer = setTimeout(() => {
        searchAPI();
      }, 500);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCityData]);

  useEffect(() => {
    localStorage.setItem("temp_data_address", JSON.stringify(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("temp_data_address"));
    const localSelectedProvince = province.find(
      item => item?.id === Number(data?.provinceId)
    );

    const localSelectedCity = city.find(
      item => item?.id === Number(data?.cityId)
    );

    if (localSelectedProvince && !selectedProvince) {
      setSelectedProvince(localSelectedProvince.name);
    }

    if (localSelectedCity && !selectedCity) {
      setSelectedCity(localSelectedCity.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(province), JSON.stringify(city)]);

  const back = () => {
    address.length < 1
      ? history.push("/cart-shipment?tabs=1")
      : user
      ? history.push("/cart-shipment/address")
      : history.push("/cart-shipment?tabs=1");
  };

  console.log(province, city);
  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />
      <Dialog
        open={open}
        fullScreen
        onClose={handleClose}
        classes={{
          paperFullScreen: classes.paperFullScreen,
          container: classes.card
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "8px 0px 16px"
          }}
        >
          <div
            style={{
              backgroundColor: "#f5f5f5",
              height: 4,
              width: "20%",
              borderRadius: 5
            }}
          ></div>
        </div>

        {open === "provinsi" && (
          <>
            <div
              style={{
                position: "fixed",
                width: "100%",
                maxWidth: 444,
                padding: "25px 0px 0px",
                backgroundColor: "white",
                borderRadius: 8
              }}
            >
              <div className={classes.searchDiv}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Cari Provinsi"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  InputProps={{
                    "aria-label": "search"
                  }}
                  onChange={handleChangeProvice}
                  value={searchProvinceData}
                />
                {searchProvinceData.length > 0 && (
                  <CloseIcon
                    onClick={resetProvince}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            </div>
            <div className={classes.list} onClick={handleClose}>
              {isLoading ? (
                <div style={{ padding: "0px 16px" }}>
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                </div>
              ) : (
                <>
                  {province.map(province => (
                    <div
                      onClick={() => {
                        setSelectedProvince(province.name);

                        user
                          ? changeData("provinceId", province.id.toString())
                          : multipleData({
                              provinceId: province.id.toString(),
                              province: province.name
                            });
                      }}
                    >
                      <div style={{ padding: 16 }}>{province.name}</div>
                      <Divider />
                    </div>
                  ))}
                </>
              )}
            </div>
            {province.length < 1 && (
              <p style={{ textAlign: "center" }}>Provinsi tidak ditemukan</p>
            )}
          </>
        )}

        {open === "kota" && (
          <>
            <div
              style={{
                position: "fixed",
                width: "100%",
                maxWidth: 444,
                padding: "25px 0px 0px",
                backgroundColor: "white",
                borderRadius: 8
              }}
            >
              <div className={classes.searchDiv}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Cari Kota/Kabupaten"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  InputProps={{
                    "aria-label": "search"
                  }}
                  onChange={handleChangeCity}
                  value={searchCityData}
                />
                {searchCityData.length > 0 && (
                  <CloseIcon
                    onClick={resetCity}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            </div>
            <div className={classes.list} onClick={handleClose}>
              {isLoading ? (
                <div style={{ padding: "0px 16px" }}>
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                </div>
              ) : (
                <>
                  {" "}
                  {city.map(city => (
                    <div
                      onClick={e => {
                        setSelectedCity(city.name);
                        user
                          ? changeData("cityId", city.id.toString())
                          : multipleData({
                              cityId: city.id.toString(),
                              city: city.name
                            });
                      }}
                    >
                      <div style={{ padding: 16 }}>{city.name}</div>
                      <Divider />
                    </div>
                  ))}
                </>
              )}
            </div>
            {city.length < 1 && (
              <p style={{ textAlign: "center" }}>Kota tidak ditemukan</p>
            )}
          </>
        )}
      </Dialog>
      {/* <AppBar title="Tambah Alamat" goBack={true} /> */}
      <AppBar
        style={{ width: "100%", maxWidth: 444, backgroundColor: "white" }}
        classes={{ positionFixed: classes.appBar }}
        elevation={1}
      >
        <Toolbar>
          <div style={{ display: "flex", alignItems: "center" }}>
            <BackButton
              style={{
                color: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                cursor: "pointer",
                marginRight: 10
              }}
              onClick={() => back()}
            />

            <strong style={{ color: "black" }}>Tambah Alamat</strong>
          </div>
        </Toolbar>
      </AppBar>
      <Paper style={{ padding: "16px 0px 25px" }}>
        <div
          style={{
            fontWeight: 500,
            fontSize: 12,
            marginBottom: 8,
            padding: "0px 16px"
          }}
        >
          Nama Penerima
        </div>
        <div className={classes.searchDiv}>
          <InputBase
            type="text"
            required
            placeholder="Masukkan Nama Lengkap"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            InputProps={{
              "aria-label": "search"
            }}
            onChange={e => changeData("name", e.target.value)}
            value={data.name}
          />
        </div>
        {data.name.length < 3 && (
          <div style={{ margin: "10px 25px", color: "red", fontSize: 11 }}>
            Isi bidang setidaknya 3 karakter
          </div>
        )}

        <div
          style={{
            fontWeight: 500,
            fontSize: 12,
            marginBottom: 8,
            padding: "0px 16px"
          }}
        >
          Nomor Telepon
        </div>
        <div className={classes.searchDiv}>
          <div className={classes.searchIcon}>
            <ReactFlagsSelect
              selected={selectedCountryCode}
              showSelectedLabel={false}
              fullWidth={false}
              selectButtonClassName={classes.flagButton}
              customLabels={CountryData}
              onSelect={code => setSelectedCountryCode(code)}
            />
          </div>
          <InputBase
            type="number"
            required
            placeholder="Masukkan Nomor Telepon"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            InputProps={{
              "aria-label": "search"
            }}
            onChange={e => changeData("phone", e.target.value)}
            value={data.phone}
            error={data.phone?.length < 5 ? true : false}
            helperText={data.phone?.length < 5 ? "Mohon isi telepon anda" : ""}
          />
        </div>
        {data.phone.length < 5 && (
          <div style={{ margin: "10px 25px", color: "red", fontSize: 11 }}>
            Nomor Telepon tidak valid
          </div>
        )}

        <div
          style={{
            fontWeight: 500,
            fontSize: 12,
            marginBottom: 8,
            padding: "0px 16px"
          }}
        >
          Email
        </div>
        <div className={classes.searchDiv}>
          <InputBase
            id="email"
            type="email"
            required
            placeholder="Masukkan Email Aktif"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            InputProps={{
              "aria-label": "search"
            }}
            onChange={e => changeData("email", e.target.value)}
            value={data.email}
          />
        </div>
        {data.email.length < 6 && (
          <div style={{ margin: "10px 25px", color: "red", fontSize: 11 }}>
            Email tidak valid
          </div>
        )}

        {isNeedCompleteAddress === "true" && (
          <>
            <div
              style={{
                fontWeight: 500,
                fontSize: 12,
                marginBottom: 8,
                padding: "0px 16px"
              }}
            >
              Nama Tempat
            </div>
            <div className={classes.searchDiv}>
              <InputBase
                type="text"
                required
                placeholder="Contoh: Rumah, Kantor, dll"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                InputProps={{
                  "aria-label": "search"
                }}
                onChange={e => changeData("label", e.target.value)}
                value={data.label}
                error={data.label?.length <= 1 ? true : false}
                helperText={
                  data.label?.length <= 1
                    ? "Isi bidang setidaknya 3 karakter"
                    : ""
                }
              />
            </div>
            {data.label.length < 3 && (
              <div style={{ margin: "10px 25px", color: "red", fontSize: 11 }}>
                Isi bidang setidaknya 3 karakter
              </div>
            )}
          </>
        )}
        <div
          style={{ height: 8, backgroundColor: "#FAFAFA", marginBottom: 16 }}
        ></div>
        {isNeedCompleteAddress === "true" && (
          <>
            <div style={{ padding: "0px 16px", marginBottom: 24 }}>
              <Typography
                style={{ fontWeight: 500, fontSize: 12, marginBottom: 8 }}
              >
                Provinsi
              </Typography>
              <TextField
                required
                variant="outlined"
                fullWidth
                disabled
                value={selectedProvince || data.province}
                onChange={e => changeData("province", e.target.value)}
                placeholder="Pilih Provinsi"
                style={{
                  backgroundColor: "#FAFAFA",
                  borderRadius: 8,
                  height: 45
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDown onClick={() => handleClickOpen("provinsi")} />
                    </InputAdornment>
                  ),
                  style: { cursor: "pointer" },
                  classes: {
                    notchedOutline: classes.noBorder,
                    input: classes.input
                  }
                }}
                onClick={() => handleClickOpen("provinsi")}
                // eslint-disable-next-line
                inputProps={{
                  style: {
                    cursor: "pointer",
                    color: "#333333",
                    fontSize: 12,
                    fontWeight: 500
                  }
                }}
              />
              {data.provinceId.length <= 1 && (
                <div
                  style={{ margin: "10px 10px", color: "red", fontSize: 11 }}
                >
                  Bidang ini wajib diisi!
                </div>
              )}
            </div>
          </>
        )}
        {isNeedCompleteAddress === "true" && (
          <>
            <div style={{ padding: "0px 16px", marginBottom: 16 }}>
              <Typography
                style={{ fontWeight: 500, fontSize: 12, marginBottom: 8 }}
              >
                Kota
              </Typography>
              <TextField
                variant="outlined"
                required
                fullWidth
                disabled
                value={selectedCity || data.city}
                onChange={e => changeData("city", e.target.value)}
                placeholder="Pilih Kota/Kabupaten"
                style={{
                  backgroundColor: "#FAFAFA",
                  borderRadius: 8,
                  height: 45
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDown
                        onClick={() =>
                          selectedProvince && handleClickOpen("kota")
                        }
                      />
                    </InputAdornment>
                  ),
                  style: { cursor: "pointer" },
                  classes: {
                    notchedOutline: classes.noBorder,
                    input: classes.input
                  }
                }}
                onClick={() => selectedProvince && handleClickOpen("kota")}
                // eslint-disable-next-line
                inputProps={{
                  style: {
                    cursor: "pointer",
                    color: "#333333",
                    fontSize: 12,
                    fontWeight: 500
                  }
                }}
              />
              {data.cityId.length <= 1 && (
                <div
                  style={{ margin: "10px 10px ", color: "red", fontSize: 11 }}
                >
                  Bagian ini wajib diisi!
                </div>
              )}
            </div>
          </>
        )}

        {isNeedCompleteAddress === "true" && (
          <>
            <div
              style={{
                fontWeight: 500,
                fontSize: 12,
                marginBottom: 8,
                padding: "0px 16px"
              }}
            >
              Kode pos
            </div>
            <div className={classes.searchDiv}>
              <InputBase
                type="number"
                required
                placeholder="Masukkan Kode Pos"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                InputProps={{
                  "aria-label": "search"
                }}
                onChange={e => changeData("postcode", e.target.value)}
                value={data.postcode}
              />
            </div>
            {data.postcode.length !== 5 && (
              <div style={{ margin: "10px 25px", color: "red", fontSize: 11 }}>
                Kode pos tidak valid
              </div>
            )}
          </>
        )}

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            padding: "0px 16px 8px"
          }}
        >
          <div>
            <div style={{ fontSize: 12, fontWeight: 500 }}>Peta Lokasi</div>
            <div
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: "grey",
                marginBottom: 8
              }}
            >
              Opsional untuk pengiriman kurir instan
            </div>
          </div>
          {lat && long && (
            <div>
              <Button
                onClick={() => {
                  history.push(`/new-address/detail`);
                  localStorage.setItem("temporaryData", JSON.stringify(data));
                }}
                color="primary"
                variant="contained"
                className={classes.button}
              >
                Ubah
              </Button>
            </div>
          )}
        </div>
        <div
          style={{ padding: "0px 16px" }}
          onClick={() => history.push("/new-address/detail")}
        >
          {long && lat ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer"
              }}
            >
              <Minimap lat={lat} lng={long} />
            </div>
          ) : (
            <>
              <div
                style={{
                  height: 120,
                  borderRadius: 8,
                  backgroundColor: "#fafafa",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px dashed grey",
                  fontFamily:
                    process.env.REACT_APP_FONT_URL ||
                    "https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
                }}
              >
                Pilih Lokasi Anda
              </div>

              {data.latitude === "" ||
                (data.longitude === "" && (
                  <p>Isi bidang setidaknya 3 karakter</p>
                ))}
            </>
          )}
        </div>
        <div
          style={{ height: 8, backgroundColor: "#FAFAFA", marginBottom: 16 }}
        ></div>
        <div
          style={{
            fontWeight: 500,
            fontSize: 12,
            marginBottom: 8,
            padding: "0px 16px"
          }}
        >
          Detail alamat
        </div>
        <div className={classes.searchDiv}>
          <InputBase
            type="text"
            required
            placeholder="Nama Jalan, Gedung, No.Rumah"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            InputProps={{
              "aria-label": "search"
            }}
            onChange={e => changeData("address", e.target.value)}
            value={data.address}
          />
        </div>
        {data.address.length < 3 && (
          <div style={{ margin: "10px 25px", color: "red", fontSize: 11 }}>
            Isi bidang setidaknya 3 karakter
          </div>
        )}
        <div className={classes.navButton}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => saveAddress()}
            disabled={disableSave}
          >
            Simpan
          </Button>
        </div>
      </Paper>
    </Container>
  );
};

export default withTransaction("AddAddress", "component")(AddAddress);
