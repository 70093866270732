const styles = (theme) => ({
  container: {
    padding: 0,
    minHeight: "100vh",
    height: "100%",
    maxWidth: 444,
    backgroundColor: "#FAFAFA",
    borderLeft: "1px solid #f1f1f1",
    borderRight: "1px solid #f1f1f1",
    paddingBottom: 56,
  },
  gridSlider: {
    marginTop: 8,
    width: "100%",
    backgroundColor: "#fff",
  },
  gridCategories: {
    marginTop: 8,
    width: "100%",
    padding: 16,
    backgroundColor: "#fff",
  },
  gridCategory: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "flex-start",
    justifyContent: "flex-start",
  },
  gridTopSeller: {
    minHeight: 390,
    margin: "8px 0 0",
    backgroundColor: "#fff",
    paddingBottom: 16,
  },
  scrollingWrapper: {
    padding: "8px 16px",
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    "-webkit-overflow-scrolling": "touch",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    backgroundColor: "#fff",
    height: "110%",
  },
  sliderMarginEnd: {
    flex: "0 0 auto",
    width: "1px",
  },
  moreText: {
    color: "#2F9CF1",
    fontSize: 10,
    fontWeight: "bold",
    "@media (max-width:375px)": {
      fontSize: "9px !important",
    },
    cursor: "pointer",
  },
  fullPaper: {
    maxWidth: 442,
    marginLeft: "-0.5%",
    bottom: 0,
    maxHeight: "70vh",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  header: {
    position: "fixed",
    height: 70,
    maxWidth: 420,
    backgroundColor: "white",
    margin: "10px 10px 0px",
    width: "100%",
  },
});

export default styles;
