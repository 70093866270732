import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import Carousel from "../carousel";

function Component(props) {
  const { classes } = props;
  return (
    <>
      <Grid container className={classes.container} item xs={12}>
        <Typography style={{ fontSize: 13, fontWeight: 700 }}>
          Informasi Terbaru
        </Typography>
        <Typography style={{ fontSize: 10, fontWeight: 400, color: "#52575C" }}>
          Informasi Terbaru hanya untukmu
        </Typography>
        <div style={{ marginTop: 12 }}>
          <Carousel />
        </div>
      </Grid>
    </>
  );
}

export default withRouter(Component);
