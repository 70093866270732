const styles = (theme) => ({
  container: {
    paddingTop: 64,
    marginBottom: 0,
    padding: 0,
    maxWidth: 444,
    minHeight: '100vh',
    height: '100%',
    backgroundColor: '#FAFAFA',
    borderLeft: '1px solid #f1f1f1',
    borderRight: '1px solid #f1f1f1',
  },
  typography: {
    padding: 10,
  },
  paper: {
    borderRadius: 0,
  },
});
export default styles;
