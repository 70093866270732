import React, { useState, useEffect } from "react";
import {
  Container,
  makeStyles,
  CssBaseline,
  TextField,
  Paper,
  Typography,
  InputAdornment,
  InputBase,
  Button,
  Dialog,
  Divider,
  AppBar,
  Toolbar
} from "@material-ui/core";
// import AppBar from "../../components/app-bar";
import Skeleton from "@material-ui/lab/Skeleton";
import ReactFlagsSelect from "react-flags-select";
import CountryData from "../../utilities/country-code";
import BackButton from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import {
  getProvinces,
  getCities,
  searchProvince,
  searchCity,
  updateAddress,
  getAddressById,
  deleteAddress
} from "../../services/address";
import { useHistory, useLocation } from "react-router-dom";
import DataCountry from "../../utilities/country-data";
import Minimap from "./../../components/minimap/index";
import { withTransaction } from "@elastic/apm-rum-react";

const useStyles = makeStyles({
  container: {
    padding: 0,
    paddingTop: 64,
    backgroundColor: "#FAFAFA",
    borderLeft: "1px solid #f1f1f1",
    borderRight: "1px solid #f1f1f1",
    paddingBottom: 64
  },
  appBar: {
    left: "auto",
    right: "auto"
  },
  searchDiv: {
    height: 45,
    backgroundColor: "#FAFAFA",
    borderRadius: 8,
    padding: "12px 16px",
    display: "flex",
    alignItems: "center",
    margin: "0px 16px 24px"
  },
  searchIcon: {
    marginRight: 10
  },
  inputRoot: {
    marginLeft: 0,
    color: "inherit",
    width: "100%",
    height: "100%"
  },
  inputSearch: {
    marginLeft: 0,
    color: "inherit",
    width: "100%",
    height: "100%"
  },
  inputInput: {
    width: "100%",
    fontSize: "14px !important"
  },

  flagButton: {
    border: "unset",
    padding: "5px 0px",
    "&:focus": {
      outline: "unset"
    }
  },
  navButton: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    maxWidth: 444,
    padding: 16,
    backgroundColor: "white",
    borderLeft: "1px solid #f1f1f1",
    borderRight: "1px solid #f1f1f1"
  },
  button: {
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      backgroundColor: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
    },
    color: process.env.REACT_APP_COLOR_FONT || "#000000",
    fontWeight: "bold",
    fontFamily: process.env.REACT_APP_FONT_FAMILY_BUTTON || "Open Sans"
  },
  noBorder: {
    border: "none"
  },
  input: {
    padding: 14
  },
  paperFullScreen: {
    maxWidth: 444,
    height: "75vh",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10
  },
  card: {
    display: "flex",
    alignItems: "flex-end"
  },

  list: {
    margin: "70px 0px 0px",
    overflow: "auto"
  }
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const UpdateAddress = () => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const [lat, setLat] = useState(query.get("lat"));
  const [long, setLong] = useState(query.get("long"));
  const latLng = query.get("lat") && query.get("long") ? true : false;
  const user = JSON.parse(localStorage.getItem("users"));
  const address = JSON.parse(localStorage.getItem("selectedAddress"));
  const [selectedCountryCode, setSelectedCountryCode] = useState("ID");

  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchProvinceData, setSearchProvinceData] = useState("");
  const [searchCityData, setSearchCityData] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  // const [currentLocation, setCurrentLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isNeedCompleteAddress, setIsNeedCompleteAddress] = useState(
    localStorage.getItem("isNeedCompleteAddress")
  );
  const [disableSave, setDisableSave] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    label: "",
    provinceId: "",
    cityId: "",
    postcode: "",
    address: "",
    latitude: "",
    longitude: "",
    country: "Indonesia"
  });
  const id = query.get("id");
  const countryCodeNumber = CountryData[selectedCountryCode]?.secondary.slice(
    1,
    CountryData[selectedCountryCode]?.secondary.length
  );

  //eslint-disable-next-line
  let newData = new Object();
  newData.name = data.name;
  newData.email = data.email;
  newData.phone = data.phone;
  newData.label = data.label;
  newData.provinceId = data.provinceId;
  newData.cityId = data.cityId;
  newData.postcode = data.postcode;
  newData.address = data.address;
  newData.latitude = data.latitude;
  newData.longitude = data.longitude;
  newData.country = "Indonesia";
  data.default ? (newData.default = true) : (newData.default = false);

  const handleClickOpen = key => {
    setOpen(key);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeProvice = e => {
    setSearchProvinceData(e.target.value);
    setIsLoading(true);
    if (e.target.value < 1) {
      resetProvince();
    }
  };
  const handleChangeCity = e => {
    setSearchCityData(e.target.value);
    setIsLoading(true);
    if (e.target.value < 1) {
      resetCity();
    }
  };

  const resetProvince = () => {
    setSearchProvinceData("");
    async function fetchAPI() {
      const res = await searchProvince("");
      setProvince(res.data);
      if (res.meta.statusCode === 200) {
        setIsLoading(false);
      }
    }
    fetchAPI();
  };

  const resetCity = () => {
    setSearchCityData("");
    async function fetchAPI() {
      const res = await searchCity(data.provinceId, "");
      setCity(res.data);
      if (res.meta.statusCode === 200) {
        setIsLoading(false);
      }
    }
    fetchAPI();
  };

  const handleSave = async () => {
    setIsLoading(true);
    const response = await updateAddress(id, {
      ...newData,
      phone: (countryCodeNumber + data.phone).replace("+", "")
      // phone: phoneCode.dial_code.replace("+", "") + data.phone,
    });

    if (response.meta.statusCode === 200) {
      setIsLoading(false);
      history.push("/cart-shipment/address");
      localStorage.removeItem("edit_data_address");
    }
  };

  const handleSaveGuest = () => {
    localStorage.setItem("selectedAddress", JSON.stringify(data));
    localStorage.removeItem("edit_data_address");
    localStorage.removeItem("temporaryData");
    localStorage.removeItem("selectedPayment");
    localStorage.removeItem("selectedShipping");
    history.push("/cart-shipment?tabs=1");
  };

  const multipleData = newData => {
    setData({
      ...data,
      ...newData
    });
  };

  const changeData = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const handleRemove = async () => {
    setIsLoading(true);
    const id = query.get("id");
    const response = await deleteAddress(id);
    if (response.meta.statusCode === 200) {
      setIsLoading(false);
      history.push("/cart-shipment/address");
      localStorage.removeItem("edit_data_address");
    }
  };

  const validateSave = () => {
    if (
      data.name?.length < 3 ||
      data.name?.length < 3 ||
      data.phone?.length < 5 ||
      data.label?.length < 1 ||
      data.provinceId?.length < 0 ||
      data.cityId?.length < 0 ||
      data.postcode?.length < 2 ||
      data.address?.length < 3 ||
      data?.latitude === 0 ||
      data?.longitude === 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validateSaveSimple = () => {
    if (
      data.name?.length < 3 ||
      data.email?.length < 3 ||
      data.phone?.length < 5 ||
      data.address?.length < 3 ||
      data?.latitude === "" ||
      data?.longitude === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (isNeedCompleteAddress === "true") {
      setDisableSave(!validateSave());
    } else {
      setDisableSave(!validateSaveSimple());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getCurrentDataCountry = phone => {
    let code = "+" + phone;
    DataCountry.forEach(res => {
      if (res.dial_code === code.slice(0, res.dial_code.length))
        code = code.slice(0, res.dial_code.length);
    });
    // return setPhoneCode(DataCountry.filter((val) => val.dial_code === code)[0]);
  };

  const removePhoneCode = phone => {
    let number = "+" + phone;
    DataCountry.forEach(res => {
      if (res.dial_code === number.slice(0, res.dial_code.length))
        number = number.slice(res.dial_code.length);
    });
    return number;
  };

  useEffect(() => {
    setIsNeedCompleteAddress(localStorage.getItem("isNeedCompleteAddress"));
    const initialData = async () => {
      if (localStorage.getItem("temporaryData")) {
        const temporaryData = JSON.parse(localStorage.getItem("temporaryData"));
        if (!latLng) {
          setLong(temporaryData.longitude);
          setLat(temporaryData.latitude);
        }
        setData(temporaryData);
      } else {
        if (user) {
          const response = await getAddressById(id);
          if (response.meta.statusCode === 200) {
            if (!latLng) {
              setLong(response.data.longitude);
              setLat(response.data.latitude);
            }
            setData({
              ...response.data,
              phone: removePhoneCode(response.data.phone)
            });
            getCurrentDataCountry(response.data.phone);
          }
        } else {
          const address = JSON.parse(localStorage.getItem("selectedAddress"));
          setData(address);
        }
      }
    };
    initialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchAPI() {
      const res = await getProvinces();
      if (res.meta.statusCode === 200) {
        setIsLoading(false);
        setProvince(res.data);
      }
    }
    if (isNeedCompleteAddress === "true" && open === "provinsi") {
      fetchAPI();
      getCoordinates();
    }
    setIsLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open === "provinsi"]);

  useEffect(() => {
    async function fetchAPI() {
      const response = await getCities(data.provinceId);

      if (response.meta.statusCode === 200) {
        setIsLoading(false);
        setCity(response.data);
      }
    }
    if (isNeedCompleteAddress === "true" && open === "kota") {
      fetchAPI();
      getCoordinates();
    }
    setIsLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open === "kota"]);

  const getCoordinates = () => {
    const lat = query.get("lat");
    const long = query.get("long");

    if (lat && long) {
      const localData = JSON.parse(localStorage.getItem("edit_data_address"));
      setData({ ...localData, latitude: lat, longitude: long });
      localStorage.setItem(
        "edit_data_address",
        JSON.stringify({ ...localData, latitude: lat, longitude: long })
      );
    }
  };

  useEffect(() => {
    localStorage.setItem("edit_data_address", JSON.stringify(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (searchProvinceData) {
      const searchAPI = async () => {
        const res = await searchProvince(searchProvinceData);
        setProvince(res.data);
        if (res.meta.statusCode === 200) {
          setIsLoading(false);
        }
      };
      const timer = setTimeout(() => {
        searchAPI();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [searchProvinceData]);

  useEffect(() => {
    if (searchCityData) {
      const searchAPI = async () => {
        const response = await searchCity(data.provinceId, searchCityData);
        setCity(response.data);
        if (response.meta.statusCode === 200) {
          setIsLoading(false);
        }
      };
      const timer = setTimeout(() => {
        searchAPI();
      }, 500);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCityData]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("temporaryData"));
    const localSelectedProvince = province.find(
      item => item?.id === Number(data?.provinceId)
    );

    const localSelectedCity = city.find(
      item => item?.id === Number(data?.cityId)
    );

    if (localSelectedProvince) {
      setSelectedProvince(localSelectedProvince.name);
    }

    if (localSelectedCity) {
      setSelectedCity(localSelectedCity.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(province), JSON.stringify(city)]);

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />
      <Dialog
        open={open}
        fullScreen
        onClose={handleClose}
        classes={{
          paperFullScreen: classes.paperFullScreen,
          container: classes.card
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "8px 0px 16px"
          }}
        >
          <div
            style={{
              backgroundColor: "#f5f5f5",
              height: 4,
              width: "20%",
              borderRadius: 5
            }}
          ></div>
        </div>

        {open === "provinsi" && (
          <>
            <div
              style={{
                position: "fixed",
                width: "100%",
                maxWidth: 444,
                padding: "25px 0px 0px",
                backgroundColor: "white",
                borderRadius: 8
              }}
            >
              <div className={classes.searchDiv}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Cari Provinsi"
                  classes={{
                    root: classes.inputSearch,
                    input: classes.inputInput
                  }}
                  InputProps={{
                    "aria-label": "search"
                  }}
                  onChange={handleChangeProvice}
                  value={searchProvinceData}
                />
                {searchProvinceData.length > 0 && (
                  <CloseIcon
                    onClick={resetProvince}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            </div>
            <div className={classes.list} onClick={handleClose}>
              {isLoading ? (
                <div style={{ padding: "0px 16px" }}>
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                </div>
              ) : (
                <>
                  {" "}
                  {province.map(province => (
                    <div
                      onClick={e => {
                        setSelectedProvince(province.name);
                        multipleData({
                          provinceId: province.id.toString(),
                          province: province.name
                        });
                      }}
                    >
                      <div style={{ padding: 16 }}>{province.name}</div>
                      <Divider />
                    </div>
                  ))}
                  {province.length < 1 && (
                    <p style={{ textAlign: "center" }}>
                      Provinsi tidak ditemukan
                    </p>
                  )}
                </>
              )}
            </div>
          </>
        )}

        {open === "kota" && (
          <>
            <div
              style={{
                position: "fixed",
                width: "100%",
                maxWidth: 444,
                padding: "25px 0px 0px",
                backgroundColor: "white",
                borderRadius: 8
              }}
            >
              <div className={classes.searchDiv}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Cari Kota/Kabupaten"
                  classes={{
                    root: classes.inputSearch,
                    input: classes.inputInput
                  }}
                  InputProps={{
                    "aria-label": "search"
                  }}
                  onChange={handleChangeCity}
                  value={searchCityData}
                />
                {searchCityData.length > 0 && (
                  <CloseIcon
                    onClick={resetCity}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            </div>
            <div className={classes.list} onClick={handleClose}>
              {isLoading ? (
                <div style={{ padding: "0px 16px" }}>
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                  <Skeleton animation="wave" height={60} />
                </div>
              ) : (
                <>
                  {" "}
                  {city.map(city => (
                    <div
                      onClick={e => {
                        setSelectedCity(city.name);
                        multipleData({
                          cityId: city.id.toString(),
                          city: city.name
                        });
                      }}
                    >
                      <div style={{ padding: 16 }}>{city.name}</div>
                      <Divider />
                    </div>
                  ))}
                  {city.length < 1 && (
                    <p style={{ textAlign: "center" }}>Kota tidak ditemukan</p>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </Dialog>
      {/* <AppBar title="Ubah Alamat" goBack={true} /> */}
      <AppBar
        style={{ width: "100%", maxWidth: 444, backgroundColor: "white" }}
        classes={{ positionFixed: classes.appBar }}
        elevation={1}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <BackButton
              style={{
                color: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                cursor: "pointer",
                marginRight: 10
              }}
              onClick={() =>
                address
                  ? history.push("/cart-shipment?tabs=1")
                  : history.push("/cart-shipment/address")
              }
            />

            <strong style={{ color: "black" }}>Ubah Alamat</strong>
          </div>
          <DeleteIcon
            style={{
              color: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
              cursor: "pointer",
              display: address ? "none" : data.default ? "none" : ""
            }}
            onClick={handleRemove}
          />
        </Toolbar>
      </AppBar>
      <Paper style={{ padding: "16px 0px 25px", minHeight: "90vh" }}>
        <div
          style={{
            fontWeight: 500,
            fontSize: 12,
            marginBottom: 8,
            padding: "0px 16px"
          }}
        >
          Nama Penerima
        </div>
        <div className={classes.searchDiv}>
          <InputBase
            type="text"
            placeholder="Masukkan Nama Lengkap"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            InputProps={{
              "aria-label": "search"
            }}
            onChange={e => changeData("name", e.target.value)}
            value={data.name}
          />
        </div>

        <div
          style={{
            fontWeight: 500,
            fontSize: 12,
            marginBottom: 8,
            padding: "0px 16px"
          }}
        >
          Nomor Telepon
        </div>
        <div className={classes.searchDiv}>
          <div className={classes.searchIcon}>
            <ReactFlagsSelect
              selected={selectedCountryCode}
              showSelectedLabel={false}
              fullWidth={false}
              selectButtonClassName={classes.flagButton}
              customLabels={CountryData}
              onSelect={code => setSelectedCountryCode(code)}
            />
          </div>
          <InputBase
            type="number"
            placeholder="Masukkan Nomor Telepon"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            InputProps={{
              "aria-label": "search"
            }}
            onChange={e => changeData("phone", e.target.value)}
            value={data.phone}
          />
        </div>
        <div
          style={{
            fontWeight: 500,
            fontSize: 12,
            marginBottom: 8,
            padding: "0px 16px"
          }}
        >
          Email
        </div>
        <div className={classes.searchDiv}>
          <InputBase
            type="text"
            placeholder="Masukkan Email Aktif"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            InputProps={{
              "aria-label": "search"
            }}
            onChange={e => changeData("email", e.target.value)}
            value={data.email}
          />
        </div>
        {isNeedCompleteAddress === "true" && (
          <>
            <div
              style={{
                fontWeight: 500,
                fontSize: 12,
                marginBottom: 8,
                padding: "0px 16px"
              }}
            >
              Nama Tempat
            </div>
            <div className={classes.searchDiv}>
              <InputBase
                type="text"
                placeholder="Contoh: Rumah, Kantor, dll"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                InputProps={{
                  "aria-label": "search"
                }}
                onChange={e => changeData("label", e.target.value)}
                value={data.label}
              />
            </div>
            <div
              style={{
                height: 8,
                backgroundColor: "#FAFAFA",
                marginBottom: 16
              }}
            ></div>
          </>
        )}
        {isNeedCompleteAddress === "true" && (
          <>
            <div style={{ padding: "0px 16px", marginBottom: 24 }}>
              <Typography
                style={{ fontWeight: 500, fontSize: 12, marginBottom: 8 }}
              >
                Provinsi
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                disabled
                value={selectedProvince || data.province}
                onChange={e => changeData("province", e.target.value)}
                placeholder="Pilih Provinsi"
                style={{
                  backgroundColor: "#FAFAFA",
                  borderRadius: 8,
                  height: 45
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDown onClick={() => handleClickOpen("provinsi")} />
                    </InputAdornment>
                  ),
                  style: { cursor: "pointer" },
                  classes: {
                    notchedOutline: classes.noBorder,
                    input: classes.input
                  }
                }}
                onClick={() => handleClickOpen("provinsi")}
                // eslint-disable-next-line
                inputProps={{
                  style: {
                    cursor: "pointer",
                    color: "#333333",
                    fontSize: 12,
                    fontWeight: 500
                  }
                }}
                // onClick={onClick}
              />
            </div>
          </>
        )}
        {isNeedCompleteAddress === "true" && (
          <>
            <div style={{ padding: "0px 16px", marginBottom: 24 }}>
              <Typography
                style={{ fontWeight: 500, fontSize: 12, marginBottom: 8 }}
              >
                Kota
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                disabled
                value={selectedCity || data.city}
                onChange={e => changeData("city", e.target.value)}
                placeholder="Pilih Kota/Kabupaten"
                style={{
                  backgroundColor: "#FAFAFA",
                  borderRadius: 8,
                  height: 45
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDown onClick={() => handleClickOpen("kota")} />
                    </InputAdornment>
                  ),
                  style: { cursor: "pointer" },
                  classes: {
                    notchedOutline: classes.noBorder,
                    input: classes.input
                  }
                }}
                onClick={() => handleClickOpen("kota")}
                // eslint-disable-next-line
                inputProps={{
                  style: {
                    cursor: "pointer",
                    color: "#333333",
                    fontSize: 12,
                    fontWeight: 500
                  }
                }}
                // onClick={onClick}
              />
            </div>
          </>
        )}

        {isNeedCompleteAddress === "true" && (
          <>
            <div
              style={{
                fontWeight: 500,
                fontSize: 12,
                marginBottom: 8,
                padding: "0px 16px"
              }}
            >
              Kode pos
            </div>
            <div className={classes.searchDiv}>
              <InputBase
                type="number"
                placeholder="Masukkan Kode Pos"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                InputProps={{
                  "aria-label": "search"
                }}
                onChange={e => changeData("postcode", e.target.value)}
                value={data.postcode}
              />
            </div>
          </>
        )}

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            padding: "0px 16px 8px"
          }}
        >
          <div>
            <div style={{ fontSize: 12, fontWeight: 500 }}>Peta Lokasi</div>
            <div
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: "grey",
                marginBottom: 8
              }}
            >
              Opsional untuk pengiriman kurir instan
            </div>
          </div>
          {lat && long && (
            <div>
              <Button
                onClick={() => {
                  user
                    ? history.push(`/new-address/detail?id=${data.id}`)
                    : history.push(`/new-address/detail`);

                  localStorage.setItem("temporaryData", JSON.stringify(data));
                }}
                color="primary"
                variant="contained"
                className={classes.button}
              >
                Ubah
              </Button>
            </div>
          )}

          {!id && !lat && !long && (
            <div>
              <Button
                onClick={() => {
                  user
                    ? history.push(`/new-address/detail?id=${data.id}`)
                    : history.push(`/new-address/detail`);

                  localStorage.setItem("temporaryData", JSON.stringify(data));
                }}
                color="primary"
                variant="contained"
                className={classes.button}
              >
                Ubah
              </Button>
            </div>
          )}
        </div>

        <div
          style={{ padding: "0px 16px" }}
          onClick={() => {
            user
              ? history.push(`/new-address/detail?id=${data.id}`)
              : history.push(`/new-address/detail`);
            localStorage.setItem("temporaryData", JSON.stringify(data));
          }}
        >
          {long && lat && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer"
              }}
            >
              <Minimap lat={lat && lat} lng={long && long} />
            </div>
          )}

          {!id && !long && !lat && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer"
              }}
            >
              <Minimap lat={address?.latitude} lng={address?.longitude} />
            </div>
          )}
        </div>
        <div
          style={{
            fontWeight: 500,
            fontSize: 12,
            marginBottom: 8,
            padding: "0px 16px"
          }}
        >
          Detail alamat
        </div>
        <div className={classes.searchDiv}>
          <InputBase
            type="text"
            placeholder="Nama Jalan, Gedung, No.Rumah"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            InputProps={{
              "aria-label": "search"
            }}
            onChange={e => changeData("address", e.target.value)}
            value={data.address}
          />
        </div>
        <div className={classes.navButton}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => (user ? handleSave() : handleSaveGuest())}
            disabled={disableSave}
          >
            Update
          </Button>
        </div>
      </Paper>
    </Container>
  );
};

export default withTransaction("UpdateAddress", "component")(UpdateAddress);
