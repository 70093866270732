import React from "react";

const Masuk = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M120 60C120 93.1366 93.1366 120 60 120C26.8634 120 0 93.1366 0 60C0 26.8634 26.8634 0 60 0C93.1366 0 120 26.8634 120 60Z"
      fill={process.env.REACT_APP_COLOR_SECONDARY || "#FFE570"}
    />
    <path
      d="M108.084 60.0001C108.084 86.5633 86.5632 108.084 59.9999 108.084C33.4367 108.084 11.9159 86.5633 11.9159 60.0001C11.9159 33.4368 33.4367 11.916 59.9999 11.916C86.5632 11.916 108.084 33.4368 108.084 60.0001Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
    <path
      d="M60.0899 64.6525C65.3726 64.6525 69.6647 56.9986 69.6647 51.4158C69.6647 45.833 65.3726 41.3008 60.0899 41.3008C54.8073 41.3008 50.5151 45.833 50.5151 51.4158C50.5151 56.9986 54.8073 64.6525 60.0899 64.6525ZM76.9284 70.5054C76.6882 69.8751 76.268 69.2448 75.8778 68.7345C74.4371 66.8436 72.0359 66.1232 69.7548 65.733C68.6742 65.5529 67.5637 65.3728 66.4831 65.1927C64.6522 66.7535 62.4911 67.744 60.0599 67.744C57.6287 67.744 55.4676 66.7235 53.6367 65.1627C52.4961 65.3428 51.3556 65.5529 50.185 65.733C47.9338 66.1232 45.5326 66.8436 44.0619 68.7345C43.6717 69.2448 43.2515 69.9051 43.0114 70.5054C42.7112 71.2558 42.5612 72.1562 42.5612 72.9666V75.2478C47.3035 77.3488 53.3065 78.6094 59.8498 78.6395C59.8798 78.6395 59.9098 78.6395 59.9399 78.6395C59.9699 78.6395 59.9999 78.6395 60.0299 78.6395C66.5732 78.6094 72.5462 77.3488 77.3186 75.2478V72.9666C77.3786 72.1562 77.2285 71.2858 76.9284 70.5054Z"
      fill="white"
    />
  </svg>
);

export default Masuk;
