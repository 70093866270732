import { axiosInstance } from "../config";

export const loginWithFirebaseToken = async token => {
  const response = await axiosInstance.post(
    "/customer/auth/login-with-firebase-gmail",
    {
      token
    }
  );
  return response.data;
};
