/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import currencyFormatter from "../../utilities/currency-formatter";
import { CartContext } from "../../context/cart";
import "./index.css";

function ProductItem(props) {
  const { classes, product } = props;
  const { addCart, increaseCart, decreaseCart, cart, cartUpdated } = useContext(
    CartContext
  );
  const [qty, setQty] = useState(0);
  const discountPercent =
    product.promoPriceType === "PERCENTAGE"
      ? product.promoPrice
      : (100 - (product.promoPrice / product.regularPrice) * 100) % 1 === 0
      ? 100 - (product.promoPrice / product.regularPrice) * 100
      : (100 - (product.promoPrice / product.regularPrice) * 100).toFixed(2);

  useEffect(() => {
    const selectedItem = cart.find(item => item.id === product.id);
    if (selectedItem) {
      setQty(selectedItem.total);
    } else {
      setQty(0);
    }
  }, [cartUpdated]);

  const cardMedia = () => {
    if (product.isPromo && product.size === null) {
      return "space-between";
    }
    if (product.isPromo) {
      return "flex-start";
    }
    if (product.size === null) {
      return "flex-end";
    }
  };

  return (
    <Box className={classes.card}>
      <Grid container spacing={0}>
        <Grid
          onClick={() => {
            props.history.push(
              `/product/${product.id}?from=/category/${product.categories[0].id}`
            );
          }}
          item
          xs={4}
        >
          <CardMedia
            className={classes.media}
            image={
              product.image.url
                ? product.image.url
                : "https://via.placeholder.com/150"
            }
          >
            <div
              className={classes.cardMedia}
              style={{ justifyContent: cardMedia() }}
            >
              {product.isPromo && (
                <p className={classes.cardMediaDisc}>
                  Disc.
                  {discountPercent}%
                </p>
              )}

              {product.size === null && (
                <div className={classes.cardMediaDigital}>Produk Digital</div>
              )}
            </div>
          </CardMedia>
        </Grid>
        <Grid
          item
          xs={8}
          justify="center"
          direction="column"
          style={{ display: "flex", padding: "0px 10px" }}
        >
          <div
            className="title"
            onClick={() => {
              props.history.push(
                `/product/${product.id}?from=/category/${product.categories[0].id}`
              );
            }}
          >
            {product.name}
          </div>
          <div className={classes.flexWrapper}>
            <div
              onClick={() => {
                props.history.push(
                  `/product/${product.id}?from=/category/${product.categories[0].id}`
                );
              }}
            >
              {product.isPromo && (
                <Typography variant="caption" className={classes.old}>
                  <b>{currencyFormatter.format(product.regularPrice)}</b>
                </Typography>
              )}
              {product.isPromo && (
                <Typography className={classes.price}>
                  {currencyFormatter.format(product.price)}
                  <b style={{ color: "#C7C7C9", fontSize: 10 }}>
                    {" "}
                    <b style={{ color: "#C7C7C9", fontSize: 10 }}>
                      {product.unit ? `/${product.unit}` : ""}
                    </b>
                  </b>
                </Typography>
              )}
              {!product.isPromo && (
                <Typography className={classes.price}>
                  {currencyFormatter.format(product.price)}
                  <b style={{ color: "#C7C7C9", fontSize: 10 }}>
                    {" "}
                    <b style={{ color: "#C7C7C9", fontSize: 10 }}>
                      {product.unit ? `/${product.unit}` : ""}
                    </b>
                  </b>
                </Typography>
              )}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                height: 40
              }}
            >
              {qty > 0 ? (
                <ButtonGroup
                  size="small"
                  aria-label="Small outlined button group"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "-webkit-fill-available",
                    marginRight: 1
                  }}
                >
                  <Button
                    onClick={event => {
                      decreaseCart(product);
                    }}
                    style={{
                      color: process.env.REACT_APP_COLOR_FONT || "#000000",
                      border: "1px solid #C4C4C4",
                      borderRadius: 4,
                      maxWidth: 30,
                      minWidth: 30,
                      maxHeight: 30,
                      minHeight: 30,
                      padding: 1
                    }}
                  >
                    -
                  </Button>
                  <Button
                    style={{
                      border: "0",
                      fontWeight: "bold",
                      fontSize: 12,
                      maxWidth: 30,
                      minWidth: 30,
                      maxHeight: 30,
                      minHeight: 30,
                      padding: 1
                    }}
                  >
                    {qty || 0}
                  </Button>
                  <Button
                    onClick={event => {
                      increaseCart(product);
                    }}
                    style={{
                      color: process.env.REACT_APP_COLOR_FONT || "#000000",
                      backgroundColor:
                        process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                      border: `1px solid ${process.env
                        .REACT_APP_COLOR_SECONDARY || "#FFE570"}`,
                      borderRadius: 4,
                      maxWidth: 30,
                      minWidth: 30,
                      maxHeight: 30,
                      minHeight: 30,
                      padding: 1,
                      fontWeight: "bold"
                    }}
                  >
                    +
                  </Button>
                </ButtonGroup>
              ) : (
                <div>
                  {product.onStock ? (
                    <Button
                      onClick={event => {
                        addCart(product);
                      }}
                      style={{
                        color: process.env.REACT_APP_COLOR_FONT || "#000000",
                        backgroundColor:
                          process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                        borderRadius: 4,
                        width: 75,
                        height: 30,
                        fontSize: 10,
                        textTransform: "none",
                        fontWeight: 700,
                        fontFamily:
                          process.env.REACT_APP_FONT_URL_BUTTON ||
                          "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
                      }}
                    >
                      <b>Tambahkan</b>
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      disabled
                      style={{
                        borderRadius: 4,
                        width: 90,
                        padding: 0,
                        height: 30,
                        fontSize: 8,
                        fontFamily:
                          process.env.REACT_APP_FONT_URL_BUTTON ||
                          "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
                      }}
                    >
                      <b>Stok Habis</b>
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default withRouter(ProductItem);
