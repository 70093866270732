const styles = (theme) => ({
  container: {
    paddingTop: 64,
    marginBottom: 0,
    padding: 0,
    maxWidth: 444,
    minHeight: '100vh',
    height: '100%',
    backgroundColor: '#FAFAFA',
    borderLeft: '1px solid #f1f1f1',
    borderRight: '1px solid #f1f1f1',
  },
  img: {
    maxWidth: 200,
  },
  paper: {
    borderRadius: 0,
    // background: 'red',
    padding: '0% 4% 0 4%',
    minHeight: 600,
  },
});
export default styles;
