const styles = (theme) => ({
  appBar: {
    backgroundColor: '#153b50',
    color: 'white',
    width: '100%',
    maxWidth: 448,
    position: 'fixed',
    top: 0,
  },
  body: {},
  container: {
    paddingTop: 64,
    marginBottom: 56,
    padding: 0,
  },
  borderRadius: 0,
});

export default styles;
