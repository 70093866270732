/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import "firebase/remote-config";
import "firebase/auth";
import { Switch, withRouter } from "react-router-dom";
import BottomNavigationApp from "./components/bottom-navigation";
import PrivateRoute from "./components/private-route";
import Home from "./containers/home";
import Alamat from "./containers/daftar-alamat";
// import TambahAlamat from "./containers/tambah-alamat";
import AddAddress from "./containers/address-add";
import DetailAddress from "./containers/address-detail";
import UpdateAddress from "./containers/address-update";
import Profile from "./containers/profile";
import TermOfUse from "./containers/term-of-use";
import AboutUs from "./containers/about-us";
import PrivacyPolicy from "./containers/privacy-policy";
import Login from "./containers/login";
import Orders from "./containers/orders";
import Help from "./containers/help";
import ProductDetails from "./containers/product-details";
import ProductList from "./containers/product-list";
import Cart from "./containers/cart";
import CartReview from "./containers/cart-review";
import CartShipment from "./containers/cart-shipment";
import CartSuccess from "./containers/cart-success";
import ProfileEdit from "./containers/profile-edit";
import OrderHitory from "./containers/order-history";
import OrderDetails from "./containers/order-details";
import NotFound from "./containers/not-found";
import HelpDetails from "./containers/help-details";
import ProductSearch from "./containers/product-search";
import TimeOut from "./containers/time-out";
import Market from "./containers/market";
import TopSeller from "./containers/top-seller-list";
import Voucher from "./containers/voucher";
import { CartContext } from "./context/cart";
import AddressList from "./containers/address-list";
import Maps from "./containers/maps/index";
import BannerDetail from "./containers/banner-detail";
import Loading from "./components/loading";
import { useHistory } from "react-router-dom";
import { getListPasar } from "./services/vendor";
import { tenantInfo } from "./services/vendor";
import { ApmRoute } from "@elastic/apm-rum-react";
import withClearCache from "./clearCache";
import posthog from "posthog-js";
import * as Sentry from "@sentry/react";

const ClearCacheComponent = withClearCache(MainApp);

function App(props) {
  return <ClearCacheComponent {...props} />;
}

function MainApp(props) {
  const pasar = JSON.parse(localStorage.getItem("selectedPasar"));
  const user = JSON.parse(localStorage.getItem("users"));
  const [isLoading, setIsLoading] = useState(true);
  const homeRoute = ["/", "/orders", "/profile", "/help"];
  const { restoreCart, restoreDefault } = useContext(CartContext);
  const history = useHistory();
  props.history.listen((location, action) => {
    window.scrollTo(0, 0);
  });

  const setDefaultLocation = res => {
    restoreDefault();
    sessionStorage.clear();
    localStorage.setItem("listLocation", JSON.stringify(res));
    localStorage.setItem("selectedPasar", JSON.stringify(res[0]));
    localStorage.removeItem("cart");
    setIsLoading(false);
    history.push("/");
  };

  const createDynamicWebmanifest = () => {
    var dynamicManifest = {
      name: process.env.REACT_APP_BRAND_NAME || "Srikopi",
      short_name: process.env.REACT_APP_BRAND_NAME || "Srikopi",
      icons: [
        {
          src: "/android-chrome-192x192.png",
          sizes: "192x192",
          type: "image/png"
        },
        {
          src: "/android-chrome-512x512.png",
          sizes: "512x512",
          type: "image/png"
        }
      ],
      theme_color: "#ffffff",
      background_color: "#ffffff",
      display: "standalone"
    };
    const stringManifest = JSON.stringify(dynamicManifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    document
      .querySelector("#manifest-placeholder")
      .setAttribute("href", manifestURL);
  };

  const setEnvOnIndexHtml = () => {
    document.title = process.env.REACT_APP_BRAND_NAME || "Srikopi";
    const logoElements = document.getElementsByClassName("logo-url");
    for (let logoElement of logoElements) {
      logoElement.href =
        process.env.REACT_APP_LOGO_URL ||
        "https://i.ibb.co/CV4xj5D/srikopi.png";
    }
    document.getElementById("meta-theme-color").content =
      process.env.REACT_APP_COLOR_PRIMARY || "#FFD101";
    document.getElementById("font-url").href =
      process.env.REACT_APP_FONT_URL ||
      "https://fonts.googleapis.com/css2?family=Montserrat&display=swap";
    document.getElementById("google-map-api").src =
      process.env.REACT_APP_FONT_URL ||
      `https://maps.googleapis.com/maps/api/js?key=${process.env
        .REACT_APP_GOOGLE_MAPS_API || ""}&libraries=places`;
    createDynamicWebmanifest();
  };

  useEffect(() => {
    if (user) {
      Sentry.setUser({ email: user.email });
    }
    setEnvOnIndexHtml();
    const initializeApp = async () => {
      const res = await getListPasar("");
      if (!pasar) {
        setDefaultLocation(res);
      } else {
        const resSelectedIndex = res.findIndex(item => item.id === pasar?.id);
        if (resSelectedIndex !== -1) {
          restoreCart();
          localStorage.setItem("listLocation", JSON.stringify(res));
          localStorage.setItem(
            "selectedPasar",
            JSON.stringify(res[resSelectedIndex])
          );
          setIsLoading(false);
        } else {
          if (window.location.pathname !== "/market") {
            setDefaultLocation(res);
          } else {
            restoreCart();
            setIsLoading(false);
          }
        }
      }
    };
    const fetchAPI = async () => {
      const response = await tenantInfo();
      if (response) {
        localStorage.setItem("tenant", JSON.stringify(response.data));
      }
    };
    initializeApp();
    fetchAPI();
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_POSTHOG_ENABLE === "true") {
      posthog.capture(`Page event ${window.location.pathname}`, {
        property: `${window.location.pathname}`
      });
    }
  }, [window.location.pathname]);

  if (isLoading) {
    return <Loading />;
  }

  // if (!pasar) {
  //   return <Market />;
  // }
  return (
    <React.Fragment>
      <Switch>
        {/* HOME */}
        <ApmRoute exact path="/" component={Home} />
        {/* CATEGORY & PRODUCT */}
        <ApmRoute path="/category/:id" component={ProductList} />
        <ApmRoute exact path="/product/:id" component={ProductDetails} />
        <ApmRoute exact path="/product-search" component={ProductSearch} />
        <ApmRoute exact path="/cart" component={Cart} />
        <ApmRoute exact path="/cart-review" component={CartReview} />
        <ApmRoute exact path="/cart-success" component={CartSuccess} />
        <ApmRoute exact path="/cart-shipment" component={CartShipment} />
        <ApmRoute exact path="/cart-shipment/address" component={AddressList} />
        {/* <Route exact path="/checkout-review" component={CartShipmentNew} /> */}
        <ApmRoute exact path="/top-seller" component={TopSeller} />
        {/* PASAR */}
        <ApmRoute exact path="/market" component={Market} cartStateSetDefault />
        {/* PROFILE & AUTH */}
        <PrivateRoute exact path="/profile" component={Profile} />
        <ApmRoute exact path="/profile-edit" component={ProfileEdit} />
        <ApmRoute exact path="/login" component={Login} />
        <ApmRoute exact path="/address" component={Alamat} />
        <ApmRoute exact path="/new-address" component={AddAddress} />
        <ApmRoute exact path="/new-address/detail" component={DetailAddress} />
        <ApmRoute exact path="/address/maps" component={Maps} />
        <ApmRoute exact path="/banner/:id" component={BannerDetail} />
        <ApmRoute
          exact
          path="/cart-shipment/update-address"
          component={UpdateAddress}
        />
        <ApmRoute exact path="/term-of-use" component={TermOfUse} />
        <ApmRoute exact path="/about-us" component={AboutUs} />
        <ApmRoute exact path="/privacy-policy" component={PrivacyPolicy} />
        {/* <Route exact path='/register' component={Register} /> */}
        {/* ORDER */}
        <PrivateRoute exact path="/orders" component={Orders} />
        <ApmRoute exact path="/order-history" component={OrderHitory} />
        <ApmRoute exact path="/order/:id" component={OrderDetails} />
        {/* HELP */}
        <ApmRoute exact path="/help" component={Help} />
        <ApmRoute exact path="/help/:id" component={HelpDetails} />
        <ApmRoute exact path="/timeout" component={TimeOut} />
        <ApmRoute exact path="/cart-shipment/voucher" component={Voucher} />
        <ApmRoute path="/" component={NotFound} />
      </Switch>

      {homeRoute.indexOf(props.location.pathname) !== -1 && (
        <>
          <BottomNavigationApp />
        </>
      )}
    </React.Fragment>
  );
}

export default withRouter(App);
