/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Button, Container, CssBaseline, Typography } from "@material-ui/core";

import AppBar from "../../components/app-bar";
import Loading from "../../components/loading";
import { getUserAddrres, setDefaultUserAddrres } from "../../services/address";
import { CartContext } from "../../context/cart";
import { withTransaction } from "@elastic/apm-rum-react";

function AddressList(props) {
  const { classes } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const user = JSON.parse(localStorage.getItem("users"));
  const history = useHistory();
  const selectedAddress = JSON.parse(localStorage.getItem("selectedAddress"));

  const initializeApp = async () => {
    setIsLoading(true);
    const listAddress = await getUserAddrres();
    setAddresses(listAddress?.data);
    setIsLoading(false);
    if (listAddress.data?.length < 1) {
      history.push("/new-address");
    }
  };

  const handleSetDefaultAddress = id => {
    setIsLoading(true);
    setDefaultUserAddrres(id)
      .then(res => {
        initializeApp();
        localStorage.setItem("selectedAddress", JSON.stringify(res.data));
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
        alert("Gagal set-default address");
      });
  };

  const handleSetAddress = data => {
    localStorage.setItem("selectedAddress", JSON.stringify(data));
    localStorage.removeItem("selectedPayment");
    localStorage.removeItem("selectedShipping");
    props.history.push("/cart-shipment?tabs=1");
  };

  const handleUpdate = id => {
    history.push(`/cart-shipment/update-address?id=${id}`);
  };
  useEffect(() => {
    initializeApp();
    localStorage.removeItem("temporaryData");
    localStorage.removeItem("temp_data_address");
    if (!user) {
      history.push("/cart-shipment?tabs=0");
    }
  }, []);

  useEffect(() => {
    if (selectedAddress) {
      const findSelectedAddress = addresses?.find(
        item => item.id === selectedAddress.id
      );
      localStorage.setItem(
        "selectedAddress",
        JSON.stringify(findSelectedAddress || selectedAddress)
      );
      localStorage.removeItem("selectedPayment");
      localStorage.removeItem("selectedShipping");
    }
  }, [addresses?.length]);

  return (
    <React.Fragment>
      <Container
        elevation={0}
        component="main"
        maxWidth="xs"
        className={classes.container}
      >
        <CssBaseline />
        <AppBar title="Alamat" goBack={true} />
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {addresses.map(item => (
              <div className={classes.addressCard}>
                <div style={{ display: "flex", height: "100%" }}>
                  <span
                    style={{
                      position: "inherit",
                      backgroundColor: item.default
                        ? process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                        : "white",
                      minWidth: 5,
                      height: "100%",
                      borderRadius: "8px 0px 0px 8px"
                    }}
                  >
                    &nbsp;
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 5,
                      padding: 10,
                      fontWeight: "bold",
                      width: "100%"
                    }}
                  >
                    {item.default ? (
                      <div
                        style={{
                          backgroundColor:
                            process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                          color: "white",
                          borderRadius: 8,
                          padding: 7,
                          width: "fit-content",
                          fontSize: 12
                        }}
                      >
                        Utama
                      </div>
                    ) : (
                      <div
                        onClick={() => handleSetDefaultAddress(item.id)}
                        style={{
                          backgroundColor: "white",
                          border: `1px solid ${process.env
                            .REACT_APP_COLOR_PRIMARY || "#FFD101"}`,
                          color:
                            process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                          borderRadius: 8,
                          padding: 7,
                          width: "fit-content",
                          fontSize: 12,
                          cursor: "pointer"
                        }}
                      >
                        Jadikan Alamat Utama
                      </div>
                    )}
                    <div onClick={() => handleSetAddress(item)}>
                      <Typography
                        style={{ marginTop: 10 }}
                        variant="subtitle2"
                        display="block"
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          marginTop: 10
                        }}
                        variant="caption"
                        display="block"
                      >
                        {item.address}
                      </Typography>
                      <Typography
                        style={{ marginTop: 5 }}
                        variant="caption"
                        display="block"
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        style={{ marginTop: 5 }}
                        variant="caption"
                        display="block"
                      >
                        {/* +62 {item.phone?.slice(2, item?.phone?.length)} */}+
                        {item?.phone}
                      </Typography>
                    </div>
                    <div
                      style={{
                        color: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                        fontWeight: 600,
                        cursor: "pointer",
                        marginTop: 20
                      }}
                      onClick={() => {
                        handleUpdate(item.id);
                      }}
                    >
                      Ubah Alamat
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {addresses.length < 1 && (
              <>
                <p style={{ textAlign: "center" }}>
                  Alamat kosong silahkan tambahkan alamat terlebih dahulu
                </p>
              </>
            )}
          </>
        )}
        <div
          style={{
            position: "fixed",
            bottom: 10,
            maxWidth: 444,
            width: "100%",
            padding: "10px 16px"
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
              textTransform: "capitalize",
              width: "100%",
              color: process.env.REACT_APP_COLOR_FONT || "#000000",
              fontWeight: "bold",
              fontFamily:
                process.env.REACT_APP_FONT_FAMILY_BUTTON || "Open Sans"
            }}
            onClick={() => {
              history.push("/new-address");
              localStorage.removeItem("selectedAddress");
            }}
          >
            tambah alamat
          </Button>
        </div>
      </Container>
    </React.Fragment>
  );
}
export default withTransaction("AddressList", "component")(AddressList);
