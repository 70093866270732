const styles = theme => ({
  card: {
    borderRadius: 8,
    padding: 16
  },
  media: {
    height: 115,
    borderRadius: 8
  },
  cardMedia: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    margin: 0
  },
  old: {
    textDecoration: "line-through",
    color: "#C7C7C9"
  },
  price: {
    color: "#25282B",
    fontWeight: "bold",
    fontSize: 13
  },
  cardMediaTitle: {
    color: process.env.REACT_APP_COLOR_FONT || "#000000",
    backgroundColor: "#FF4600",
    width: "20%",
    fontWeight: "bold",
    fontSize: 10,
    borderRadius: 3,
    opacity: 0.9,
    marginBottom: 4,
    paddingLeft: 4,
    paddingRight: 4
  },
  cardMediaDisc: {
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
    color: process.env.REACT_APP_COLOR_FONT || "#000000",
    width: "fit-content",
    padding: "8px 12px",
    fontWeight: "bold",
    fontSize: 10,
    borderRadius: "5px 0 5px 0",
    opacity: 0.9,
    marginTop: 0,
    marginBottom: 46,
    paddingLeft: 4,
    paddingRight: 4
  },
  flexWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  cardMediaDigital: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    width: "100%",
    padding: "5px 10px",
    textAlign: "center",
    fontWeight: 600,
    fontSize: 8,
    borderRadius: "0px 0px 5px 5px"
  }
});

export default styles;
