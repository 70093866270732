import React from "react";

const Fab = () => (
  <>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M22.9828 8.70603H18.0038L15.2108 2.31073C15.0554 1.9549 14.6409 1.79229 14.285 1.94778C13.9292 2.10317 13.7666 2.51768 13.9221 2.87356L16.4693 8.70607H7.53077L10.078 2.87356C10.2334 2.51768 10.0709 2.10321 9.71501 1.94778C9.35918 1.79229 8.94462 1.95481 8.78927 2.31073L5.99627 8.70603H1.01726C0.36593 8.70603 -0.117445 9.29567 0.0250551 9.91634L2.64701 21.3365C2.75112 21.79 3.16334 22.1123 3.63921 22.1123H20.3608C20.8367 22.1123 21.2489 21.79 21.353 21.3365L23.975 9.91634C24.1175 9.29571 23.6341 8.70603 22.9828 8.70603ZM6.12856 10.8625C6.03462 10.8625 5.93913 10.8435 5.84749 10.8035C5.49162 10.6481 5.3291 10.2336 5.48454 9.87771L6.01273 8.66825H7.54723L6.77323 10.4405C6.65787 10.7048 6.39959 10.8625 6.12856 10.8625ZM8.4844 18.3623C8.4844 18.7506 8.16959 19.0654 7.78127 19.0654C7.39296 19.0654 7.07815 18.7506 7.07815 18.3623V13.206C7.07815 12.8177 7.39296 12.5029 7.78127 12.5029C8.16959 12.5029 8.4844 12.8177 8.4844 13.206V18.3623ZM12.7031 18.3623C12.7031 18.7506 12.3883 19.0654 12 19.0654C11.6117 19.0654 11.2969 18.7506 11.2969 18.3623V13.206C11.2969 12.8177 11.6117 12.5029 12 12.5029C12.3883 12.5029 12.7031 12.8177 12.7031 13.206V18.3623ZM16.9219 18.3623C16.9219 18.7506 16.6071 19.0654 16.2188 19.0654C15.8305 19.0654 15.5156 18.7506 15.5156 18.3623V13.206C15.5156 12.8177 15.8305 12.5029 16.2188 12.5029C16.6071 12.5029 16.9219 12.8177 16.9219 13.206V18.3623ZM18.1525 10.8035C18.0609 10.8435 17.9654 10.8625 17.8715 10.8625C17.6005 10.8625 17.3422 10.7049 17.2268 10.4405L16.4528 8.66829H17.9873L18.5155 9.87776C18.6709 10.2336 18.5084 10.6481 18.1525 10.8035Z"
          fill={process.env.REACT_APP_COLOR_FONT || "#000000"}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default Fab;
