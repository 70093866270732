import React from "react";

const MarketIcon = () => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8381 4.25015L15.0808 -0.000488281H3.04738L0.290039 4.24919C0.290039 5.19887 1.09195 5.99951 2.04523 5.99951C2.99756 5.99951 3.80043 5.24951 3.80043 4.24919C3.80043 5.19887 4.60234 5.99951 5.55563 5.99951C6.50795 5.99951 7.31082 5.24951 7.31082 4.24919C7.31082 5.19887 8.11273 5.99951 9.06602 5.99951C10.0183 5.99951 10.8212 5.24951 10.8212 4.24919C10.8212 5.19887 11.6231 5.99951 12.5764 5.99951C13.5287 5.99951 14.3316 5.24951 14.3316 4.24919C14.3316 5.19887 15.1335 5.99951 16.0868 5.99951C17.0363 5.99951 17.8382 5.24951 17.8382 4.25013L17.8381 4.25015Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
    <path
      d="M16.0839 8.0002C15.5321 8.0002 15.031 7.89988 14.5797 7.7002V11.4999H3.54846V7.7002C3.09721 7.89988 2.59614 8.0002 2.04428 8.0002C1.89387 8.0002 1.69363 8.0002 1.54321 7.95051V13.4995H16.584L16.585 7.94955C16.4346 8.00017 16.2343 8.00017 16.0839 8.00017L16.0839 8.0002Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
  </svg>
);

export default MarketIcon;
