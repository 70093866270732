/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Paper,
  Grid,
  Button,
  Container,
  CssBaseline,
  Divider,
  Box,
  ButtonGroup,
  CardMedia
} from "@material-ui/core";
import usePopState from "react-usepopstate";
import currencyFormatter from "../../utilities/currency-formatter";

import AppBar from "../../components/app-bar";
import BottomSheet from "../../components/bottom-sheet";
import Loading from "../../components/loading";

import KosongVector from "../../vector/kosongVector.svg";

import { CartContext } from "../../context/cart";
import { withTransaction } from "@elastic/apm-rum-react";

function CartPage(props) {
  const { classes, history, subTotalPrice } = props;
  const [isLoading, setIsLoading] = useState(true);
  const { cart, cartUpdated, increaseCart, decreaseCart } = useContext(
    CartContext
  );
  const [qty, setQty] = useState(0);

  const { isBackButtonClick } = usePopState({
    isPrompt: false,
    callback: () => {
      history.push("/");
    }
  });

  useEffect(() => {
    const selectedItem = cart.find(item => item.id);
    if (selectedItem) {
      setQty(selectedItem.total);
    } else {
      setQty(0);
    }
    setIsLoading(false);
    console.log(cart);
  }, [cartUpdated]);

  useEffect(() => {
    localStorage.removeItem("selectedPayment");
    localStorage.removeItem("usedVoucher");
  }, []);

  if (cart.length < 1) {
    props.history.push("/");
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <Container
          elevation={0}
          component="main"
          maxWidth="xs"
          className={classes.container}
        >
          <CssBaseline />
          <AppBar title="Keranjang Belanja" goBack />
          <Paper elevation={0} className={classes.body}>
            {cart < 1 && (
              <div>
                <div align="center" className={classes.empty}>
                  <img src={KosongVector} alt="Cart kosong" />
                </div>

                <Box
                  display="flex"
                  justifyContent="center"
                  m={1}
                  p={1}
                  bgcolor="background.paper"
                >
                  <Typography align="center" variant="subtitle1">
                    <strong>Keranjangmu masih kosong nih :(</strong>
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  justifyContent="center"
                  m={1}
                  p={1}
                  bgcolor="background.paper"
                >
                  <Typography
                    align="center"
                    className={classes.caption}
                    variant="caption"
                  >
                    <p>Cari produk kebutuhanmu hari ini,</p>
                    <p>yuk belanja sekarang!</p>
                  </Typography>
                  <div className={classes.stickToBottom}>
                    <Paper className={classes.paperbtn}>
                      <Button
                        style={{
                          backgroundColor:
                            process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                          color: process.env.REACT_APP_COLOR_FONT || "#000000",
                          fontWeight: "bold",
                          textTransform: "none"
                        }}
                        variant="contained"
                        fullWidth
                        onClick={() => props.history.push("/")}
                      >
                        Belanja Sekarang
                      </Button>
                    </Paper>
                  </div>
                </Box>
              </div>
            )}
            {cart.length > 0 && (
              <div>
                <Grid
                  container
                  spacing={0}
                  style={{ padding: 16, backgroundColor: "#FAFAFA" }}
                >
                  <Grid item xs={6}>
                    <Typography variant="caption" display="block">
                      <b>Total produk: {cart.length}</b>
                    </Typography>
                  </Grid>
                  <Grid align="right" item xs={6}>
                    <Button
                      onClick={() => history.push("/")}
                      disableRipple
                      style={{
                        color: process.env.REACT_APP_COLOR_FONT || "#000000",
                        textTransform: "none",
                        fontSize: 12,
                        padding: 0
                      }}
                    >
                      <b>Tambah lagi</b>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            )}

            {cart &&
              cart.map(item => (
                <div>
                  <Box key={item.id} className={classes.paper}>
                    <Grid container spacing={0}>
                      <Grid item xs={3} style={{ paddingRight: 16 }}>
                        <CardMedia
                          className={classes.img}
                          image={
                            item.image.url
                              ? item.image.url
                              : "https://via.placeholder.com/150"
                          }
                        >
                          <div className={classes.cardMedia}>
                            {item.size === null && (
                              <div className={classes.cardMediaDigital}>
                                Produk Digital
                              </div>
                            )}
                          </div>
                        </CardMedia>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="caption" display="block">
                          <b>{item.name || "-"}</b>
                        </Typography>
                        <Typography
                          style={{ marginTop: 30 }}
                          variant="caption"
                          display="block"
                        >
                          <b>{currencyFormatter.format(item.price)}</b>
                          <b style={{ color: "grey", fontSize: 10 }}>
                            {item.unit_of_measure
                              ? `/${item.unit_of_measure}`
                              : ""}
                          </b>
                        </Typography>
                      </Grid>

                      <Grid
                        style={{ display: "flex", alignItems: "flex-end" }}
                        item
                        xs={3}
                      >
                        <ButtonGroup
                          size="small"
                          aria-label="Small outlined button group"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "-webkit-fill-available",
                            marginRight: 1
                          }}
                        >
                          <Button
                            onClick={() => {
                              decreaseCart(item);
                            }}
                            style={{
                              color: "#C4C4C4",
                              borderRadius: 4,
                              border: "1px solid #C4C4C4",
                              maxWidth: 30,
                              minWidth: 30,
                              maxHeight: 30,
                              minHeight: 30,
                              padding: 1,
                              fontWeight: 700
                            }}
                          >
                            -
                          </Button>
                          <Button
                            style={{
                              border: "0",
                              fontWeight: "bold",
                              fontSize: 12,
                              maxWidth: 30,
                              minWidth: 30,
                              maxHeight: 30,
                              minHeight: 30,
                              padding: 1
                            }}
                          >
                            {item.total}
                          </Button>
                          <Button
                            onClick={() => {
                              increaseCart(item);
                            }}
                            style={{
                              color:
                                process.env.REACT_APP_COLOR_FONT || "#000000",
                              backgroundColor:
                                process.env.REACT_APP_COLOR_PRIMARY ||
                                "#FFD101",
                              borderRadius: 4,
                              border: `1px solid ${process.env
                                .REACT_APP_COLOR_SECONDARY || "#FFE570"}`,
                              maxWidth: 30,
                              minWidth: 30,
                              maxHeight: 30,
                              minHeight: 30,
                              padding: 1,
                              fontWeight: 700
                            }}
                          >
                            +
                          </Button>
                        </ButtonGroup>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider />
                </div>
              ))}
          </Paper>
        </Container>
      )}
      {cart.length > 0 && (
        <BottomSheet
          name="Lanjutkan Transaksi"
          link={() => history.push("/cart-shipment")}
          price={subTotalPrice}
        />
      )}
    </React.Fragment>
  );
}
export default withTransaction("CartPage", "component")(CartPage);
