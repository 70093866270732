import React from "react";

const Other = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1485 0H1.1984C0.536187 0 0 0.537842 0 1.1984V13.1469C0 13.8091 0.537841 14.3453 1.1984 14.3453H13.1469C13.8091 14.3453 14.3453 13.8074 14.3453 13.1469L14.3469 1.1984C14.3469 0.5378 13.809 0 13.1485 0H13.1485Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
    <path
      d="M30.8032 0H18.8531C18.1909 0 17.6547 0.537842 17.6547 1.1984V13.1469C17.6547 13.8091 18.1926 14.3453 18.8531 14.3453H30.8016C31.4638 14.3453 32 13.8074 32 13.1469L32.0016 1.1984C32.0016 0.5378 31.4637 0 30.8032 0H30.8032Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
    <path
      d="M13.1485 17.6553H1.1984C0.536187 17.6553 0 18.1931 0 18.8537V30.8021C0 31.4643 0.537841 32.0005 1.1984 32.0005H13.1469C13.8091 32.0005 14.3453 31.4627 14.3453 30.8021L14.3469 18.8537C14.3469 18.1931 13.809 17.6553 13.1485 17.6553H13.1485Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
    <path
      d="M30.8032 17.6553H18.8531C18.1909 17.6553 17.6547 18.1931 17.6547 18.8537V30.8021C17.6547 31.4643 18.1926 32.0005 18.8531 32.0005H30.8016C31.4638 32.0005 32 31.4627 32 30.8021L32.0016 18.8537C32.0016 18.1931 31.4637 17.6553 30.8032 17.6553H30.8032Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
  </svg>
);
export default Other;
