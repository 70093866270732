const styles = theme => ({
  container: {
    padding: 0,
    paddingTop: 48 + 56
  },
  card: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: 10,
    margin: "8px 0",
    padding: 16,
    height: 147
  },
  media: {
    borderRadius: 8,
    height: 115,
    width: 115,
    "@media (max-width:375px)": {
      width: 85
    },
    display: "flex"
  },
  old: {
    textDecoration: "line-through",
    color: "#C7C7C9"
  },
  price: {
    color: "#25282B",
    fontWeight: "bold",
    fontSize: 13,
    "@media (max-width:375px)": {
      fontSize: 11
    }
  },
  cardMediaTitle: {
    color: process.env.REACT_APP_COLOR_FONT || "#000000",
    backgroundColor: "#FF4600",
    width: "50%",
    fontWeight: "bold",
    fontSize: 10,
    borderRadius: 3,
    opacity: 0.9,
    marginBottom: 4,
    paddingLeft: 4,
    paddingRight: 4
  },
  cardMediaDisc: {
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
    color: process.env.REACT_APP_COLOR_FONT || "#000000",
    width: 65,
    padding: 10,
    fontWeight: "bold",
    fontSize: 10,
    borderRadius: "5px 0px 5px 0px",
    opacity: 0.9,
    margin: 0
  },
  cardMediaDigital: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    width: "100%",
    padding: "5px 10px",
    textAlign: "center",
    fontWeight: 600,
    fontSize: 8,
    borderRadius: "0px 0px 5px 5px"
  },
  cardMedia: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    width: "100%"
  },
  cardContent: {
    padding: "0px 8px 0px 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%"
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  btn: {
    justifyContent: "center",
    display: "flex",
    margin: 0
  }
});

export default styles;
