/* eslint-disable array-callback-return */
import React, { useState, createContext } from "react";

export const CartContext = createContext();

const CartContextProvider = props => {
  const [cart, setCart] = useState([]);
  const [price, setPrice] = useState(0);
  const [cartUpdated, setCartUpdated] = useState(true);

  const restoreDefault = () => {
    setCart([]);
    setPrice(0);
    localStorage.setItem("cart", []);
    localStorage.setItem("price", 0);
  };

  const restoreCart = () => {
    const cartData = localStorage.getItem("cart");
    const priceData = localStorage.getItem("price");
    if (cartData) {
      setCart(JSON.parse(cartData));
      setPrice(priceData);
    }
  };

  const calculateTotal = list => {
    if (list.length > 0) {
      const sum = item => item.reduce((x, y) => x + y);
      const total = sum(list.map(product => Number(product.totalPrice)));
      setPrice(total);

      localStorage.setItem("price", total);
      setCartUpdated(!cartUpdated);
    } else {
      setPrice(0);
      localStorage.setItem("price", 0);
      setCartUpdated(!cartUpdated);
    }
  };

  const addCart = item => {
    const cartData = [...cart, { ...item, total: 1, totalPrice: item.price }];
    setCart(cartData);
    calculateTotal(cartData);
    localStorage.setItem("cart", JSON.stringify(cartData));
  };

  const increaseCart = item => {
    const objIndex = cart.findIndex(select => select.id === item.id);
    const cartData = cart;
    cartData[objIndex].total += 1;
    cartData[objIndex].totalPrice =
      cartData[objIndex].total * cartData[objIndex].price;
    setCart(cartData);
    calculateTotal(cartData);
    localStorage.setItem("cart", JSON.stringify(cartData));
  };

  const decreaseCart = item => {
    const objIndex = cart.findIndex(select => select.id === item.id);
    const cartData = cart;
    if (cartData[objIndex].total > 1) {
      cartData[objIndex].total -= 1;
      cartData[objIndex].totalPrice =
        cartData[objIndex].total * cartData[objIndex].price;
      setCart(cartData);
      calculateTotal(cartData);
      localStorage.setItem("cart", JSON.stringify(cartData));
    } else {
      const newCart = cart.filter(obj => obj.id !== item.id);
      setCart(newCart);
      calculateTotal(newCart);
      localStorage.setItem("cart", JSON.stringify(newCart));
    }
  };

  const addNote = (id, note) => {
    const cartData = [];
    cart.map(item => {
      if (item.id === id) {
        item.note = note;
      }
      cartData.push(item);
    });
    setCart(cartData);
    localStorage.setItem("cart", JSON.stringify(cartData));
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        price,
        restoreDefault,
        restoreCart,
        calculateTotal,
        addCart,
        increaseCart,
        decreaseCart,
        addNote,
        cartUpdated
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
