const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: "theme.palette.background.paper",
    color: "black",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.05)",
    padding: "0px 16px",
    margin: "8px 0px",
  },
  menunggu: {
    width: "100%",
    backgroundColor: "#cfd5dc",
  },
  menungguwarna: { color: "#1f3952" },
  proseswarna: { color: "#fdbb00" },
  proses: {
    width: "100%",
    backgroundColor: "#fdf4c8",
  },
  listmenunggu: {
    color: "black",
  },
  listproses: {
    color: "black",
  },
});

export default styles;
