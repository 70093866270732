import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import RightArrow from "@material-ui/icons/KeyboardArrowRightRounded";
import InputAdornment from "@material-ui/core/InputAdornment";
import Shipping from "../../vector/shipping";
import currencyFormatter from "../../utilities/currency-formatter";

const ButtonShippingMethod = ({
  selectedShipping,
  openShipmentMethod,
  openCourier
}) => {
  const shipping = JSON.parse(localStorage.getItem("selectedShipping"));
  const address = JSON.parse(localStorage.getItem("selectedAddress"));

  const getTitle = () => {
    if (!selectedShipping) return null;
    if (shipping?.code === "delivery" && shipping?.gateway === "local") {
      return "Reguler (Diantar dalam 45-90 menit)";
    } else {
      return `${selectedShipping?.name ||
        shipping?.name} ${selectedShipping?.serviceName ||
        shipping?.serviceName}  ${
        selectedShipping?.estimatedTimeMaximum === 1
          ? `(${selectedShipping?.estimatedTimeMinimum ||
              shipping?.estimatedTimeMinimum} Hari)`
          : `(${selectedShipping?.estimatedTimeMinimum ||
              shipping?.estimatedTimeMinimum} - ${selectedShipping?.estimatedTimeMaximum ||
              shipping?.estimatedTimeMaximum} Hari)`
      }  ${currencyFormatter.format(selectedShipping?.cost || shipping?.cost)}`;
    }
  };

  return (
    <>
      <Typography style={{ marginBottom: 16, fontWeight: 600 }}>
        Metode Pengiriman
      </Typography>
      <TextField
        variant="outlined"
        fullWidth
        disabled
        value={getTitle()}
        placeholder="Pilih Metode Pengiriman"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Shipping />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <RightArrow
                style={{
                  color: address
                    ? process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                    : "red"
                }}
              />
            </InputAdornment>
          ),
          style: {
            cursor: "pointer",
            color: "#333333",
            fontSize: 12,
            fontWeight: 500
          }
        }}
        // eslint-disable-next-line
        inputProps={{ style: { cursor: "pointer" } }}
        onClick={openShipmentMethod}
      />
    </>
  );
};

export default ButtonShippingMethod;
