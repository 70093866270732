/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as Sentry from "@sentry/react";
import DialogShipmentMethod from "../../components/dialog-shipment-method";
import DialogPaymentMethod from "../../components/dialog-payment-method";
import LoadingOrder from "../../components/loading-order";
import currencyFormatter from "../../utilities/currency-formatter";
import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "../../components/app-bar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import vectorPasar from "../../vector/Vectorpasar.svg";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import InputAdornment from "@material-ui/core/InputAdornment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { ThemeProvider } from "@material-ui/styles";
import {
  createOrder,
  calculateOrder,
  createOrderGuest
} from "../../services/orders";
import { getUserAddrres } from "../../services/address";
import { ReactComponent as Server } from "../../vector/serverBusy.svg";
import Slide from "@material-ui/core/Slide";
import Clear from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import { CartContext } from "../../context/cart";
import { CircularProgress } from "@material-ui/core";
import vectorInfo from "../../vector/Vectorinfo.svg";
import { getBankAcc } from "../../services/bank";
import Voucher from "../../components/voucher";
import ButtonPaymentMethod from "../../components/button-payment-method";
import ButtonShippingMethod from "../../components/button-shipping-method";
import Address from "../../vector/addresSelected.js";
import AddressEmpty from "../../vector/address.js";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import VoucherSection from "../voucher";
import DialogErrorField from "../../components/dialog-error-field";
import ReactFlagsSelect from "react-flags-select";
import CountryData from "../../utilities/country-code";
import queryString from "query-string";
import { TrendingUpRounded } from "@material-ui/icons";
import usePopState from "react-usepopstate";
import { Link } from "react-router-dom";
import { withTransaction } from "@elastic/apm-rum-react";

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif"
  },
  palette: {
    background: {
      default: "#FBFDFF"
    },
    primary: {
      main: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
    }
  },
  overrides: {
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: 12,
          fontWeight: 500,
          color: "#A6A6A6"
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        border: "1px solid #F5F5F5",
        borderRadius: "8px"
      },
      adornedStart: {
        paddingLeft: "16px"
      }
    }
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function CartShipment(props) {
  const history = useHistory();
  const { classes } = props;
  const query = useQuery();
  const location = useLocation();
  const { cart, price, restoreDefault } = useContext(CartContext);
  // const { tabs } = queryString.parse(location.search);
  const tabs = query.get("tabs");
  const selectedPasar = JSON.parse(localStorage.getItem("selectedPasar"));
  const user = JSON.parse(localStorage.getItem("users"));
  const payment = JSON.parse(localStorage.getItem("selectedPayment"));
  const shipping = JSON.parse(localStorage.getItem("selectedShipping"));
  const address = JSON.parse(localStorage.getItem("selectedAddress"));
  const savedData = JSON.parse(localStorage.getItem("savedData"));
  const [tab, setTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCreateOrder, setIsLoadingCreateOrder] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [keyboard, setKeyboard] = useState(false);
  const [error, setError] = useState(false);
  const [openShipmentMethod, setOpenShipmentMethod] = useState(false);
  const [openPaymentMethod, setOpenPaymentMethod] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [calculateResponse, setCalculateResponse] = useState(null);
  const [usedVoucher, setUsedVoucher] = useState("");
  const [selectedShipping, setSelectedShipping] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  // const [displayVoucher, setDisplayVoucher] = useState(false);
  const [note, setNote] = useState("");
  const [isError, setIsError] = useState(false);
  const [dialogErrorField, setDialogErrorField] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("ID");
  const [voucherError, setVoucherError] = useState(false);
  const [voucherSuccess, setVoucherSuccess] = useState(false);
  const [isOutOfRange, setIsOutOfRange] = useState(false);
  const [used, setUsed] = useState(null);
  const [hiddenTab, setHiddenTab] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertShipping, setAlertShipping] = useState(false);
  const [alertPayment, setAlertPayment] = useState(false);
  const [shippingNull, setShippingNull] = useState(false);
  const [isNullShippingChannel, setIsNullShippingChannel] = useState(false);
  // const [triggerCalculate, setTriggerCalculate] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [dialogError, setDialogError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [internalServer, setInternalServer] = useState(false);
  const label = process.env.REACT_APP_SHIPPING_COST_LABEL || "Ongkir";

  const urlParams = new URLSearchParams(window.location.search);

  const { isBackButtonClick } = usePopState({
    isPrompt: false,
    callback: () => {
      history.push("/cart");
    }
  });

  const handleCalculateOrder = async isShippingNull => {
    if (!firstLoad && tab === 0) {
      return;
    }
    setUsed(localStorage.getItem("isUsed"));
    setIsLoading(true);
    const countryCodeNumber = CountryData[selectedCountryCode].secondary.slice(
      1,
      CountryData[selectedCountryCode].secondary.length
    );

    const validPhone = selectedAddress?.phone
      ? selectedAddress?.phone.slice(0, 1) === "0"
        ? countryCodeNumber +
          selectedAddress?.phone.slice(1, selectedAddress?.phone.length)
        : selectedAddress?.phone.slice(0, 2) === countryCodeNumber
        ? selectedAddress?.phone
        : countryCodeNumber + selectedAddress?.phone
      : null;

    const body = {
      items:
        cart.map(item => {
          return {
            productId: item.id,
            quantity: item.total,
            note: ""
          };
        }) || [],
      contact: {
        phone: validPhone || "",
        email: selectedAddress?.email || ""
      },
      shipping: {
        name: selectedAddress?.name || "",
        phone: validPhone || "",
        email: selectedAddress?.email || "",
        address: tabs === "0" ? "" : selectedAddress?.address || "",
        postcode: tabs === "0" ? "" : selectedAddress?.postcode || "",
        country: tabs === "0" ? "" : selectedAddress?.country || "",
        latitude: tabs === "0" ? "" : Number(selectedAddress?.latitude) || "",
        longitude: tabs === "0" ? "" : Number(selectedAddress?.longitude) || "",
        note: selectedAddress?.note || "",
        province: tabs === "0" ? "" : selectedAddress?.province || "",
        provinceId: tabs === "0" ? "" : selectedAddress?.provinceId || "",
        city: tabs === "0" ? "" : selectedAddress?.city || "",
        cityId: tabs === "0" ? "" : selectedAddress?.cityId || "",
        shippingChannel: !selectedShipping
          ? null
          : {
              id: selectedShipping?.id,
              service: selectedShipping?.code
            }
      },
      billing: {
        name: selectedAddress?.name || "",
        phone: validPhone || "",
        email: selectedAddress?.email || "",
        address: tabs === "0" ? "" : selectedAddress?.address || "",
        postcode: tabs === "0" ? "" : selectedAddress?.postcode || "",
        country: tabs === "0" ? "" : selectedAddress?.country || "",
        latitude: tabs === "0" ? null : Number(selectedAddress?.latitude) || "",
        longitude:
          tabs === "0" ? null : Number(selectedAddress?.longitude) || "",
        note: selectedAddress?.note || "",
        province: tabs === "0" ? "" : selectedAddress?.province || "",
        provinceId: tabs === "0" ? "" : selectedAddress?.provinceId || "",
        city: tabs === "0" ? "" : selectedAddress?.city || "",
        cityId: tabs === "0" ? "" : selectedAddress?.cityId || ""
      },
      voucherCode: selectedAddress
        ? Object.values(selectedAddress)?.filter(item => item === "")?.length <=
          9
          ? usedVoucher
          : ""
        : "",
      customerNote: selectedAddress?.note
    };

    await calculateOrder(body)
      .then(res => {
        localStorage.setItem(
          "isNeedCompleteAddress",
          res.data.data.isNeedCompleteAddress
        );

        if (
          res?.data?.data?.availableShippings?.length < 1 &&
          res?.data?.data?.isShippingRequired === true
        ) {
          setShippingNull(true);
        }

        if (
          !res?.data?.data?.availableShippings?.find(
            item => item.name === "Pickup"
          ) &&
          res?.data?.data?.isShippingRequired === true
        ) {
          setHiddenTab(true);
          setTab(1);
          urlParams.set("tabs", "1");
          history.replace(
            `${history.location.pathname}?${urlParams.toString()}`
          );
          // history.push("/cart-shipment?tabs=1", { from: "/cart" });
        }

        if (
          res?.data?.data?.availableShippings?.length === 1 &&
          res?.data?.data?.availableShippings[0].name === "Pickup"
        ) {
          setTab(0);
          setHiddenTab(true);
          urlParams.set("tabs", "0");
          history.replace(
            `${history.location.pathname}?${urlParams.toString()}`
          );
          // history.push("/cart-shipment");
        }

        if (tabs !== "1") {
          setSelectedShipping(
            res?.data?.data?.availableShippings?.find(
              item => item.name === "Pickup"
            )
          );
        }

        setIsLoading(false);
        setIsAlert(false);
        setCalculateResponse(res?.data?.data);

        if (res?.data?.data?.vouchers.length > 0) {
          setVoucherSuccess(true);
        }
        localStorage.removeItem("isUsed");
        setFirstLoad(false);
      })
      .catch(error => {
        setFirstLoad(false);
        setIsLoading(false);
        localStorage.removeItem("isUsed");
        if (error?.response?.data?.meta?.statusCode === 409) {
          setFirstLoad(true);
          setUsedVoucher("");
          localStorage.removeItem("usedVoucher");
          setVoucherError(true);
        } else if (error?.response?.data?.meta?.statusCode === 400) {
          Sentry.captureException(error, {
            extra: {
              payload: {
                headers: error.response.config.headers,
                data: error.response.config.data
              },
              response: error.response.data
            }
          });
          localStorage.removeItem("selectedShipping");
          localStorage.removeItem("selectedPayment");
          setSelectedPayment(null);
          setSelectedShipping(null);
          setIsOutOfRange(true);
          setIsAlert(true);
        } else if (
          error?.response?.data?.meta?.message ===
          "There's no active shipping channel yet!"
        ) {
          localStorage.removeItem("selectedShipping");
          localStorage.removeItem("selectedPayment");
          setSelectedPayment(null);
          setSelectedShipping(null);
          setIsNullShippingChannel(true);
        } else if (error?.response?.data?.meta?.statusCode === 500) {
          setInternalServer(true);
          setErrorMessage(error?.response?.data?.meta?.message);
          localStorage.removeItem("selectedShipping");
          localStorage.removeItem("selectedPayment");
          setSelectedPayment(null);
          setSelectedShipping(null);
        } else {
          setSelectedPayment(null);
          setSelectedShipping(null);
          setUsedVoucher("");
          console.log(error);
          setDialogError(true);
          setErrorMessage(error?.response?.data?.meta?.message);
          localStorage.removeItem("selectedShipping");
          localStorage.removeItem("selectedPayment");
          localStorage.removeItem("usedVoucher");
        }
      });
  };

  const initializeApp = async () => {
    try {
      const savedVoucher = localStorage.getItem("usedVoucher") || "";
      setUsedVoucher(savedVoucher);
      // setSelectedAddress(null);
      // setCalculateResponse(null);
      urlParams.set("tabs", tabs);
      history.replace(`${history.location.pathname}?${urlParams.toString()}`);
      if (tabs === "1") {
        setTab(1);
        const listAddress = user ? await getUserAddrres() : { data: [] };
        setAddresses(listAddress?.data || []);
        if (address) {
          setSelectedAddress(address);
        } else {
          if (user) {
            if (listAddress?.data?.length > 0) {
              const defaultAddress = listAddress.data[0];
              localStorage.setItem(
                "selectedAddress",
                JSON.stringify(defaultAddress)
              );
              const selectedlocal = JSON.parse(
                localStorage.getItem("selectedAddress")
              );
              const validSelectedAddress = listAddress.data.find(
                item => item.id === selectedlocal?.id
              );
              setSelectedAddress(
                validSelectedAddress || defaultAddress || null
              );
            }
            if (listAddress?.data?.length < 1) {
              setSelectedAddress({
                name: "",
                email: "",
                phone: "",
                note: "",
                address: "",
                city: "",
                cityId: "",
                latitude: "",
                longitude: "",
                postcode: "",
                province: "",
                provinceId: ""
              });
            }
          } else {
            setSelectedAddress({
              name: "",
              email: "",
              phone: "",
              note: "",
              address: "",
              city: "",
              cityId: "",
              latitude: "",
              longitude: "",
              postcode: "",
              province: "",
              provinceId: ""
            });
          }
        }
        if (shipping) {
          setSelectedShipping(shipping);
        } else {
          setSelectedShipping(null);
        }
      } else {
        setTab(0);
        localStorage.removeItem("selectedShipping");
        setSelectedAddress({
          name: savedData?.name || "",
          email: user ? user.email : savedData?.email || "",
          phone: savedData?.phone || "",
          note: savedData?.note || "",
          address: "",
          city: "",
          cityId: "",
          latitude: "",
          longitude: "",
          postcode: "",
          province: "",
          provinceId: ""
        });
      }

      if (payment) {
        setSelectedPayment(payment);
      } else {
        setSelectedPayment(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Initial Effect

  useEffect(() => {
    initializeApp();
  }, [tabs]);

  useEffect(() => {
    if (selectedAddress) {
      handleCalculateOrder();
    }
  }, [JSON.stringify(selectedShipping), JSON.stringify(selectedAddress)]);

  useEffect(() => {
    if (cart.length < 1) {
      props.history.push("/");
    }
  }, []);

  const notAvailableShipping = () => {
    if (
      tabs === "1" &&
      loopShipping(calculateResponse?.availableShippings)?.length === 0 &&
      selectedAddress !== null
    ) {
      setIsNullShippingChannel(true);
    }
  };

  const handleEx = () => {
    setExpanded(!expanded);
  };

  const handleCloseShipmentMethod = () => {
    setOpenShipmentMethod(false);
    window.document.body.style.overflow = "auto";
  };

  const handleCloseShippingMethod = () => {
    setOpenPaymentMethod(false);
    window.document.body.style.overflow = "auto";
  };

  const handleChange = e => {
    setSelectedAddress({ ...selectedAddress, [e.target.name]: e.target.value });
    localStorage.setItem(
      "savedData",
      JSON.stringify({ ...selectedAddress, [e.target.name]: e.target.value })
    );
  };

  const loopShipping = data => {
    let result = [];
    if (data?.length > 0) {
      data
        .filter(shipping => shipping.name !== "Pickup")
        .map(item =>
          item?.services?.map(data => {
            return result.push({
              id: item.id,
              name: item.name,
              cost: data.cost,
              min: data.estimatedTime.minimum,
              max: data.estimatedTime.maximum,
              code: data.code,
              gateway: item.gateway,
              serviceName: data.name
            });
          })
        );
    }

    return result;
  };

  const orderNow = async () => {
    if (isAlert) {
      setIsOutOfRange(true);
    }
    if (
      selectedAddress?.name === "" ||
      selectedAddress?.email === "" ||
      selectedAddress?.phone === ""
    ) {
      setIsError(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      return;
    }

    if (!selectedAddress) {
      setIsError(true);
      let elmt = document.getElementById("alamat");
      elmt.scrollIntoView({
        block: "center",
        behavior: "smooth"
      });

      return;
    }

    if (calculateResponse?.isShippingRequired === true) {
      if (!selectedShipping) {
        setIsError(true);
        let elmt = document.getElementById("shipping");
        elmt.scrollIntoView({
          block: "center",
          behavior: "smooth"
        });
        return;
      }
    }

    if (!selectedPayment) {
      setIsError(true);
      let elmnt = document.getElementById("payment");
      elmnt.scrollIntoView({
        block: "center",
        behavior: "smooth"
      });

      return;
    }

    const countryCodeNumber = CountryData[selectedCountryCode].secondary.slice(
      1,
      CountryData[selectedCountryCode].secondary.length
    );

    const validPhone = selectedAddress?.phone
      ? selectedAddress?.phone.slice(0, 1) === "0"
        ? countryCodeNumber +
          selectedAddress?.phone.slice(1, selectedAddress?.phone.length)
        : selectedAddress?.phone.slice(0, 2) === countryCodeNumber
        ? selectedAddress?.phone
        : countryCodeNumber + selectedAddress?.phone
      : null;

    await setIsLoadingCreateOrder(true);
    const order = {
      items:
        cart.map(item => {
          return {
            productId: item.id,
            quantity: item.total,
            note: ""
          };
        }) || [],
      contact: {
        phone: validPhone || "",
        email: selectedAddress?.email || address?.email || ""
      },
      shipping: {
        name: selectedAddress?.name || address?.name || "",
        phone: validPhone || "",
        email: selectedAddress?.email || address?.email || "",
        address:
          tabs === "0"
            ? ""
            : selectedAddress?.address || address?.address || "",
        postcode:
          tabs === "0"
            ? ""
            : selectedAddress?.postcode || address?.postcode || "",
        country:
          tabs === "0"
            ? ""
            : selectedAddress?.country || address?.country || "",
        latitude:
          tabs === "0"
            ? ""
            : selectedAddress?.latitude || Number(address?.latitude) || "",
        longitude:
          tabs === "0"
            ? ""
            : selectedAddress?.longitude || Number(address?.longitude) || "",
        note: selectedAddress?.note || address?.note || "",
        province:
          tabs === "0"
            ? ""
            : selectedAddress?.province || address?.province || "",
        provinceId:
          tabs === "0"
            ? ""
            : selectedAddress?.provinceId || address?.provinceId || "",
        city: tabs === "0" ? "" : selectedAddress?.city || address?.city || "",
        cityId:
          tabs === "0" ? "" : selectedAddress?.cityId || address?.cityId || "",
        shippingChannel: selectedShipping
          ? {
              id: selectedShipping?.id || shipping.id,
              service: selectedShipping.code || shipping.code
            }
          : null
      },
      billing: {
        name: selectedAddress?.name || address?.name || "",
        phone: validPhone || "",
        email: selectedAddress?.email || address?.email || "",
        address:
          tabs === "0"
            ? ""
            : selectedAddress?.address || address?.address || "",
        postcode:
          tabs === "0"
            ? ""
            : selectedAddress?.postcode || address?.postcode || "",
        country:
          tabs === "0"
            ? ""
            : selectedAddress?.country || address?.country || "",
        latitude:
          tabs === "0"
            ? ""
            : selectedAddress?.latitude || Number(address?.latitude) || "",
        longitude:
          tabs === "0"
            ? ""
            : selectedAddress?.longitude || Number(address?.longitude) || "",
        note: selectedAddress?.note || address?.note || "",
        province:
          tabs === "0"
            ? ""
            : selectedAddress?.province || address?.province || "",
        provinceId:
          tabs === "0"
            ? ""
            : selectedAddress?.provinceId || address?.provinceId || "",
        city: tabs === "0" ? "" : selectedAddress?.city || address?.city || "",
        cityId:
          tabs === "0" ? "" : selectedAddress?.cityId || address?.cityId || ""
      },
      voucherCode: usedVoucher,
      customerNote: selectedAddress?.note,
      paymentChannelId: selectedPayment.id || payment.id
    };

    try {
      const result = user
        ? await createOrder(order)
        : await createOrderGuest(order);

      if (result.data.data.id) {
        localStorage.setItem("respon", JSON.stringify(result.data.data.id));
        localStorage.removeItem("usedVoucher");
        localStorage.removeItem("selectedShipping");
        localStorage.removeItem("selectedPayment");

        restoreDefault();
        window.location.replace(result.data.data?.payments[0]?.url);
      } else {
        setIsLoadingCreateOrder(false);
        setError(true);
      }
    } catch (error) {
      console.log(error);
      alert("Gagal create order");
      setIsLoadingCreateOrder(false);
    }
  };

  const handleChangeAddress = () => {
    const address = JSON.parse(localStorage.getItem("selectedAddress"));

    if (user) {
      if (addresses?.length < 1) {
        props.history.push("/new-address");
      } else {
        props.history.push("/cart-shipment/address");
      }
    } else {
      if (address) {
        props.history.push("/cart-shipment/update-address");
      } else {
        props.history.push("/new-address");
      }
    }
  };

  const handleButtonPaymentMethod = () => {
    if (selectedAddress && selectedShipping) {
      setOpenPaymentMethod(true);
    } else if (calculateResponse?.isShippingRequired === false) {
      setOpenPaymentMethod(true);
    } else if (selectedAddress && !selectedShipping) {
      setAlertPayment(true);
      window.scrollTo({
        top: 40,
        behavior: "smooth"
      });
      return false;
    } else {
      setAlertPayment(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      return false;
    }
  };

  const distanceLabel = data => {
    if (
      calculateResponse &&
      data?.shippings[0]?.shippingChannel?.code === "delivery"
    ) {
      return (
        <div>
          {label}{" "}
          <span style={{ textTransform: "uppercase" }}>
            ({data.distance.value} {data.distance.unit})
          </span>
        </div>
      );
    }
    return label;
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Container className={classes.container} component="main" maxWidth="xs">
          <CssBaseline />
          {isLoadingCreateOrder && <LoadingOrder />}
          <AppBar title="Pesan" goBack={true} />
          {calculateResponse?.isShippingRequired && (
            <Tabs
              value={tab}
              onChange={(event, newValue) => {
                setTab(newValue);
                setFirstLoad(true);
                localStorage.removeItem("selectedPayment");
                localStorage.removeItem("usedVoucher");
                setUsedVoucher("");
                setSelectedPayment(null);
                setSelectedShipping(null);
                setSelectedAddress(null);
                urlParams.set("tabs", newValue.toString());
                history.replace(
                  `${history.location.pathname}?${urlParams.toString()}`
                );
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
              style={{ display: hiddenTab ? "none" : "" }}
            >
              <Tab label={<b>Ambil Sendiri</b>} />
              <Tab label={<b>Pesan Antar</b>} />
            </Tabs>
          )}

          <Paper elevation={0} className={classes.body}>
            <List className={classes.root} disablePadding>
              {tab === 1 ? (
                <>
                  <Typography style={{ fontSize: 16 }}>
                    <b>Detail Pengiriman</b>
                  </Typography>
                  {address ? (
                    <div
                      onClick={handleChangeAddress}
                      style={{
                        border: "1px solid #F5F5F5",
                        width: "100%",
                        borderRadius: 8,
                        marginTop: 10,
                        padding: 10,
                        cursor: "pointer"
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid
                          style={{ alignSelf: "center", justifySelf: "center" }}
                          item
                          xs={2}
                        >
                          <Address />
                        </Grid>
                        <Grid style={{ alignSelf: "center" }} item xs={8}>
                          <Typography
                            variant="caption"
                            style={{
                              fontWeight: 500
                            }}
                            display="block"
                          >
                            Alamat Pengiriman
                          </Typography>
                          <Typography
                            style={{
                              marginTop: 5,
                              fontWeight: 600
                            }}
                            variant="subtitle1"
                            display="block"
                          >
                            {selectedAddress?.address}
                          </Typography>
                        </Grid>
                        <Grid style={{ alignSelf: "center" }} item xs={2}>
                          <Typography
                            style={{
                              color:
                                process.env.REACT_APP_COLOR_PRIMARY ||
                                "#FFD101",
                              marginTop: 5,
                              fontWeight: 600
                            }}
                            variant="caption"
                            display="block"
                          >
                            Ubah
                          </Typography>
                        </Grid>
                      </Grid>
                      {isAlert && (
                        <>
                          <div
                            style={{
                              borderBottom: "1px solid red",
                              margin: "5px 0px"
                            }}
                          ></div>
                          <Typography style={{ color: "red", fontSize: 12 }}>
                            Alamat anda tidak dapat dijangkau
                          </Typography>
                        </>
                      )}
                    </div>
                  ) : (
                    <div
                      id="alamat"
                      onClick={handleChangeAddress}
                      style={{
                        border: "1px solid #F5F5F5",
                        width: "100%",
                        borderRadius: 8,
                        marginTop: 10,
                        padding: 10,
                        cursor: "pointer"
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid
                          style={{ alignSelf: "center", justifySelf: "center" }}
                          item
                          xs={3}
                        >
                          <AddressEmpty />
                        </Grid>
                        <Grid style={{ alignSelf: "center" }} item xs={7}>
                          <Typography
                            // variant="subtitle2"
                            style={{
                              fontSize: 14,
                              fontWeight: 600
                            }}
                            display="block"
                          >
                            Sebelum lanjut bayar, isi detail alamat kamu dulu ya
                            di sini!
                          </Typography>
                          <Typography
                            style={{
                              color: "#808080",
                              fontSize: 11,
                              marginTop: 5
                            }}
                            variant="caption"
                            display="block"
                          >
                            Alamat untuk penerima pengiriman
                          </Typography>
                        </Grid>
                        <Grid style={{ alignSelf: "center" }} item xs={2}>
                          <ChevronRightIcon
                            style={{
                              color:
                                process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {isError && !selectedAddress && (
                    <p style={{ color: "red", marginLeft: 16 }}>
                      Harap isi alamat terlebih dahulu!
                    </p>
                  )}
                </>
              ) : (
                <>
                  <TextField
                    error={isError && selectedAddress?.name === ""}
                    // helperText="Harap isi nama!"
                    id="standard-full-width"
                    label="Nama Lengkap"
                    fullWidth
                    name="name"
                    value={selectedAddress?.name}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <TextField
                    error={isError && selectedAddress?.email === ""}
                    // helperText="Harap isi alamat!"
                    id="standard-full-width"
                    label="Alamat Email"
                    fullWidth
                    name="email"
                    value={selectedAddress?.email}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />

                  <TextField
                    error={isError && selectedAddress?.phone === ""}
                    // helperText="Harap isi phone!"
                    id="standard-full-width"
                    label="Nomor Telepon"
                    fullWidth
                    name="phone"
                    value={selectedAddress?.phone}
                    onChange={handleChange}
                    type="number"
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ReactFlagsSelect
                            selected={selectedCountryCode}
                            showSelectedLabel={false}
                            fullWidth={false}
                            selectButtonClassName={classes.flagButton}
                            customLabels={CountryData}
                            onSelect={code => setSelectedCountryCode(code)}
                          />
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <TextField
                    id="standard-multiline-static"
                    label="Catatan Order"
                    fullWidth
                    multiline
                    rows="3"
                    name="note"
                    value={selectedAddress?.note}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </>
              )}
            </List>
          </Paper>

          {tab === 1 && (
            <div id="buttonShipping">
              <Paper elevation={0} className={classes.body}>
                <ButtonShippingMethod
                  selectedShipping={selectedShipping || shipping}
                  openShipmentMethod={() => {
                    if (address) {
                      if (
                        loopShipping(calculateResponse?.availableShippings)
                          .length === 0
                      ) {
                        notAvailableShipping();
                      } else {
                        setOpenShipmentMethod(true);
                      }
                    } else {
                      setAlertShipping(true);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                      });
                      return false;
                    }
                  }}
                />

                {isError && !selectedShipping && (
                  <p style={{ color: "red", marginLeft: 16 }}>
                    Harap pilih metode pengiriman!
                  </p>
                )}
              </Paper>
            </div>
          )}

          <Paper elevation={0} className={classes.body}>
            <ButtonPaymentMethod
              selectedPayment={selectedPayment || payment}
              onClick={() => {
                handleButtonPaymentMethod();
              }}
            />
            {isError && !selectedPayment && (
              <p style={{ color: "red", marginLeft: 16 }}>
                Harap pilih metode pembayaran!
              </p>
            )}
          </Paper>

          {selectedShipping?.code !== "pickup" && (
            <div id="shipping">
              <DialogShipmentMethod
                open={openShipmentMethod}
                onClose={() => handleCloseShipmentMethod()}
                onSelect={ship => {
                  setSelectedShipping(ship);
                  handleCloseShipmentMethod();
                }}
                selectedShipping={selectedShipping || shipping}
                data={loopShipping(calculateResponse?.availableShippings)}
              />
            </div>
          )}
          <div id="payment">
            <DialogPaymentMethod
              open={openPaymentMethod}
              onClose={() => handleCloseShippingMethod()}
              onSelect={pay => {
                setSelectedPayment(pay);
                handleCloseShippingMethod();
              }}
              selectedPayment={selectedPayment || payment}
              data={calculateResponse?.availablePayments}
            />
          </div>
          <DialogErrorField
            open={dialogErrorField}
            onClose={() => setDialogErrorField(false)}
          />

          <Paper
            elevation={0}
            className={classes.body}
            style={cart.length >= 3 ? { paddingBottom: 0 } : {}}
          >
            <List className={classes.grid}>
              <ListItem style={{ padding: 0 }}>
                <Typography style={{ fontSize: 16 }}>
                  <b>Ringkasan Pemesanan</b>
                </Typography>
              </ListItem>
            </List>

            <List className={classes.grid}>
              {cart.length >= 1 && (
                <div>
                  {cart.slice(0, 2).map(item => {
                    const unitOfMeansure =
                      item.meta_data &&
                      item.meta_data.filter(
                        meta_data => meta_data.key === "_woo_uom_input"
                      );
                    return (
                      <div>
                        <Grid style={{ marginTop: 16 }} container spacing={0}>
                          <Grid
                            item
                            xs={2}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <Typography variant="caption" display="block">
                              <b>{item.total + "x"}</b>
                            </Typography>
                          </Grid>
                          <Grid align="left" item xs={7}>
                            <Typography
                              style={{
                                color: "#4E5356",
                                fontSize: 12,
                                fontWeight: "bold"
                              }}
                            >
                              {item.name}
                            </Typography>
                            <Typography
                              style={{ color: "#4E5356" }}
                              variant="caption"
                              display="block"
                            >
                              {currencyFormatter.format(item.price)}
                            </Typography>
                            {item.on_sale && (
                              <Typography variant="caption" display="block">
                                <b
                                  style={{
                                    textDecoration: "line-through",
                                    color: "grey"
                                  }}
                                >
                                  {currencyFormatter.format(item.price)}
                                </b>
                                {currencyFormatter.format(item.sale_price)}/{" "}
                                {unitOfMeansure
                                  ? unitOfMeansure[0].value
                                  : item.unit_of_measure
                                  ? item.unit_of_measure
                                  : "-"}
                              </Typography>
                            )}
                            {item.on_sale === "undefined" && (
                              <Typography variant="caption" display="block">
                                {currencyFormatter.format(item.price)}/{" "}
                                {unitOfMeansure
                                  ? unitOfMeansure[0].value
                                  : item.unit_of_measure
                                  ? item.unit_of_measure
                                  : "-"}
                              </Typography>
                            )}
                          </Grid>
                          <Grid align="right" item xs={3}>
                            <Typography variant="caption" display="block">
                              {currencyFormatter.format(item.totalPrice)}
                            </Typography>
                          </Grid>
                        </Grid>
                        {cart.length === 1 ? (
                          <></>
                        ) : (
                          <Divider style={{ marginTop: 16 }} fullWidth />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              {cart.length >= 3 && (
                <div>
                  <Collapse in={expanded}>
                    {cart.slice(2, cart.length).map(item => {
                      const unitOfMeansure =
                        item.meta_data &&
                        item.meta_data.filter(
                          meta_data => meta_data.key === "_woo_uom_input"
                        );
                      return (
                        <div>
                          <Grid
                            style={{ marginTop: 16, marginBottom: 16 }}
                            container
                            spacing={0}
                          >
                            <Grid
                              item
                              xs={2}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <Typography variant="caption" display="block">
                                <b>{item.total + "x"}</b>
                              </Typography>
                            </Grid>
                            <Grid align="left" item xs={7}>
                              <Typography
                                style={{
                                  color: "#4E5356",
                                  fontSize: 12,
                                  fontWeight: "bold"
                                }}
                              >
                                {item.name}
                              </Typography>
                              <Typography
                                style={{ color: "#4E5356" }}
                                variant="caption"
                                display="block"
                              >
                                {currencyFormatter.format(item.price)}
                              </Typography>
                              {item.on_sale && (
                                <Typography variant="caption" display="block">
                                  <b
                                    style={{
                                      textDecoration: "line-through",
                                      color: "grey"
                                    }}
                                  >
                                    {currencyFormatter.format(item.price)}
                                  </b>
                                  {currencyFormatter.format(item.sale_price)}/{" "}
                                  {unitOfMeansure
                                    ? unitOfMeansure[0].value
                                    : item.unit_of_measure
                                    ? item.unit_of_measure
                                    : "-"}
                                </Typography>
                              )}
                              {item.on_sale === "undefined" && (
                                <Typography variant="caption" display="block">
                                  {currencyFormatter.format(item.price)}
                                  {"/" + item.unit_of_measure || ""}
                                </Typography>
                              )}
                            </Grid>
                            <Grid align="right" item xs={3}>
                              <Typography variant="caption" display="block">
                                {currencyFormatter.format(item.totalPrice)}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider style={{ marginTop: 16 }} fullWidth />
                        </div>
                      );
                    })}
                  </Collapse>
                  <ExpansionPanel
                    style={{
                      backgroundColor: "white",
                      background: "white",
                      border: 0,
                      marginTop: 0
                    }}
                    elevation={0}
                    onClick={handleEx}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      onClick={handleEx}
                      classes={{ expanded: "classes.expandRoot" }}
                      style={{ padding: 0 }}
                    >
                      {expanded === false ? (
                        <Typography
                          style={{
                            width: "100%",
                            color:
                              process.env.REACT_APP_COLOR_FONT || "#000000",
                            fontSize: 12,
                            margin: "8px 0"
                          }}
                          align="right"
                        >
                          Lebih detail
                        </Typography>
                      ) : (
                        <Typography
                          style={{
                            width: "100%",
                            color:
                              process.env.REACT_APP_COLOR_FONT || "#000000",
                            fontSize: 12
                          }}
                          align="right"
                        >
                          Lebih singkat
                        </Typography>
                      )}
                    </ExpansionPanelSummary>
                  </ExpansionPanel>
                </div>
              )}
            </List>
          </Paper>

          <Paper elevation={0} className={classes.voucher}>
            <>
              {usedVoucher !== "" ? (
                <Voucher
                  remove={() => setUsedVoucher("")}
                  click={event => {
                    if (
                      tab === 0
                        ? selectedAddress?.name === "" ||
                          selectedAddress?.email === "" ||
                          selectedAddress?.phone === ""
                        : address === null
                    ) {
                      setIsError(true);
                      setDialogErrorField(true);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                      });
                      return false;
                    } else {
                      return true;
                    }

                    // setDisplayVoucher(true);
                  }}
                  cardColor={`linear-gradient(to right, ${process.env
                    .REACT_APP_COLOR_PRIMARY || "#FFD101"}, #ffe675)`}
                  buttonColor={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
                  content="Voucher Terpasang"
                  buttonContent="Ubah"
                />
              ) : (
                <Voucher
                  click={() => {
                    if (
                      tab === 0
                        ? selectedAddress?.name === "" ||
                          selectedAddress?.email === "" ||
                          selectedAddress?.phone === ""
                        : address === null
                    ) {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                      });
                      setIsError(true);
                      setDialogErrorField(true);
                      return false;
                    } else return true;
                  }}
                  buttonColor={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
                  content="Punya kode voucher?"
                  buttonContent="Masukkan"
                />
              )}
            </>
          </Paper>

          <Paper elevation={0} className={classes.body}>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Typography variant="caption" display="block">
                  Subtotal
                </Typography>
              </Grid>
              <Grid align="right" item xs={6}>
                <Typography variant="caption" display="block">
                  {currencyFormatter.format(
                    calculateResponse ? calculateResponse.subTotalPrice : price
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <Grid item xs={12} align="center" style={{ display: "flex" }}>
                  <img src={vectorInfo} alt="Info versi" />
                  <Typography
                    style={{ color: "grey", marginLeft: 8 }}
                    variant="caption"
                    display="block"
                  >
                    {"  "}belum termasuk biaya antar
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="caption" display="block">
                  {distanceLabel(calculateResponse)}
                </Typography>
              </Grid>
              <Grid align="right" item xs={6}>
                <Typography variant="caption" display="block">
                  {currencyFormatter.format(
                    calculateResponse ? calculateResponse.totalShipping : 0
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" display="block">
                  Diskon
                </Typography>
              </Grid>
              <Grid align="right" item xs={6}>
                <Typography variant="caption" display="block">
                  {currencyFormatter.format(
                    calculateResponse ? calculateResponse.totalDiscount : 0
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          {keyboard === false && (
            <div className={classes.stickToBottom}>
              <Divider />
              <Grid
                className={classes.grid}
                container
                item
                xs={12}
                spacing={0}
                style={{ padding: "16px 16px 0px" }}
              >
                <Grid item xs={6}>
                  <Typography variant="caption" display="block">
                    <b>Total Pembayaran</b>
                  </Typography>
                </Grid>
                <Grid align="right" item xs={6}>
                  <Typography variant="caption" display="block">
                    {currencyFormatter.format(
                      Number(
                        calculateResponse ? calculateResponse.totalPrice : price
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    style={{ marginBottom: 5, marginTop: 5, width: "100%" }}
                  />
                  <Typography
                    variant="caption"
                    display="block"
                    style={{ padding: "8px 0 0" }}
                  >
                    Kamu Belanja Di: <img src={vectorPasar} alt="Market" />{" "}
                    <b>{selectedPasar.name}</b>
                  </Typography>
                </Grid>
              </Grid>

              <Paper className={classes.paperbtn}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <div>
                      <Button
                        className={classes.button}
                        style={{
                          backgroundColor:
                            process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                          color: process.env.REACT_APP_COLOR_FONT || "#000000",
                          height: "100%",
                          fontWeight: 700,
                          fontFamily:
                            process.env.REACT_APP_FONT_URL_BUTTON ||
                            "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
                        }}
                        variant="contained"
                        fullWidth={true}
                        onClick={orderNow}
                      >
                        Pesan
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          )}

          <Dialog
            classes={{ paperFullScreen: classes.fullPaper }}
            fullScreen
            open={error}
            onClose={event => setError(false)}
            TransitionComponent={Transition}
          >
            <div align="left">
              <IconButton
                style={{ padding: 5 }}
                aria-label="Menu"
                onClick={event => setError(false)}
              >
                <Clear />
              </IconButton>
            </div>
            <React.Fragment>
              <div style={{ marginTop: 10 }} align="center">
                <Server />
                <Typography variant="caption" display="block">
                  <b>Ups.. server sibuk nih</b>
                </Typography>
                <Typography variant="caption" display="block">
                  Internal server{" "}
                  {process.env.REACT_APP_BRAND_NAME || "Srikopi"} lagi kurang
                  fit nih. Tunggu beberapa saat lagi dan tekan coba lagi ya.
                </Typography>
              </div>
              <div
                style={{
                  padding: 15
                }}
              >
                <Button
                  className={classes.button}
                  style={{
                    backgroundColor:
                      process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                  }}
                  variant="contained"
                  fullWidth={true}
                  onClick={orderNow}
                >
                  Coba Lagi
                </Button>
              </div>
            </React.Fragment>
          </Dialog>

          <Dialog
            classes={{ paperFullScreen: classes.fullPaper }}
            fullScreen
            open={voucherSuccess && used === "true"}
            onClose={event => {
              setVoucherSuccess(false);
            }}
            TransitionComponent={Transition}
          >
            <div style={{ marginTop: 10 }} align="center">
              <div
                style={{
                  width: "15%",
                  backgroundColor: "#f5f5f5",
                  height: 4,
                  borderRadius: 5,
                  marginBottom: 20
                }}
              ></div>
              <Typography style={{ fontWeight: 700 }}>
                Voucher Telah Terpasang!
              </Typography>
              <Typography
                variant="caption"
                display="block"
                style={{ fontSize: 12, fontWeight: 400, padding: "20px 15px" }}
              >
                Voucher senilai{" "}
                {currencyFormatter.format(
                  Number(calculateResponse?.totalDiscount)
                )}{" "}
                telah terpasang, selamat menikmati!
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.buttonError}
                style={{
                  backgroundColor:
                    process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                }}
                variant="contained"
                fullWidth={true}
                onClick={() => {
                  setVoucherSuccess(false);
                  localStorage.removeItem("isUsed");
                }}
              >
                OK
              </Button>
            </div>
          </Dialog>

          <Dialog
            classes={{ paperFullScreen: classes.fullPaper }}
            fullScreen
            open={voucherError}
            onClose={event => {
              setVoucherError(false);
              handleCalculateOrder();
            }}
            TransitionComponent={Transition}
          >
            <div style={{ marginTop: 10 }} align="center">
              <div
                style={{
                  width: "15%",
                  backgroundColor: "#f5f5f5",
                  height: 4,
                  borderRadius: 5,
                  marginBottom: 20
                }}
              ></div>

              <Typography
                variant="caption"
                display="block"
                style={{ fontSize: 14, fontWeight: 600, padding: "20px 0px" }}
              >
                Maaf kode voucher anda tidak bisa dipakai
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.buttonError}
                style={{
                  backgroundColor:
                    process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                }}
                variant="contained"
                fullWidth={true}
                onClick={() => {
                  setVoucherError(false);
                  localStorage.removeItem("isUsed");
                  handleCalculateOrder();
                }}
              >
                OK
              </Button>
            </div>
          </Dialog>

          <Dialog
            classes={{ paperFullScreen: classes.fullPaper }}
            fullScreen
            open={isOutOfRange}
            onClose={event => {
              setIsOutOfRange(false);
            }}
            TransitionComponent={Transition}
          >
            <div style={{ marginTop: 10 }} align="center">
              <div
                style={{
                  width: "15%",
                  backgroundColor: "#f5f5f5",
                  height: 4,
                  borderRadius: 5,
                  marginBottom: 20
                }}
              ></div>

              <Typography
                variant="caption"
                display="block"
                style={{ fontSize: 14, fontWeight: 600, padding: "20px 0px" }}
              >
                Maaf, alamat anda tidak dapat kami jangkau
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.buttonError}
                style={{
                  backgroundColor:
                    process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                }}
                variant="contained"
                fullWidth={true}
                onClick={() => {
                  setIsOutOfRange(false);
                }}
              >
                OK
              </Button>
            </div>
          </Dialog>

          <Dialog
            classes={{ paperFullScreen: classes.fullPaper }}
            fullScreen
            open={alertShipping}
            onClose={event => {
              setAlertShipping(false);
            }}
            TransitionComponent={Transition}
          >
            <div style={{ marginTop: 10 }} align="center">
              <div
                style={{
                  width: "15%",
                  backgroundColor: "#f5f5f5",
                  height: 4,
                  borderRadius: 5,
                  marginBottom: 20
                }}
              ></div>

              <Typography
                variant="caption"
                display="block"
                style={{ fontSize: 14, fontWeight: 600, padding: "20px 0px" }}
              >
                Harap isi alamat terlebih dahulu!
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.buttonError}
                style={{
                  backgroundColor:
                    process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                }}
                variant="contained"
                fullWidth={true}
                onClick={() => {
                  setAlertShipping(false);
                }}
              >
                OK
              </Button>
            </div>
          </Dialog>

          <Dialog
            classes={{ paperFullScreen: classes.fullPaper }}
            fullScreen
            open={alertPayment}
            onClose={event => {
              setAlertPayment(false);
            }}
            TransitionComponent={Transition}
          >
            <div style={{ marginTop: 10 }} align="center">
              <div
                style={{
                  width: "15%",
                  backgroundColor: "#f5f5f5",
                  height: 4,
                  borderRadius: 5,
                  marginBottom: 20
                }}
              ></div>

              <Typography
                variant="caption"
                display="block"
                style={{ fontSize: 14, fontWeight: 600, padding: "20px 0px" }}
              >
                {address && !selectedShipping
                  ? "Harap pilih metode pengiriman terlebih dahulu!"
                  : "Harap isi alamat terlebih dahulu!"}
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.buttonError}
                style={{
                  backgroundColor:
                    process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                }}
                variant="contained"
                fullWidth={true}
                onClick={() => {
                  setAlertPayment(false);
                }}
              >
                OK
              </Button>
            </div>
          </Dialog>

          <Dialog
            classes={{ paperFullScreen: classes.fullPaper }}
            fullScreen
            open={shippingNull}
            onClose={event => {
              setShippingNull(false);
              history.push("/cart");
            }}
            TransitionComponent={Transition}
          >
            <div style={{ marginTop: 10 }} align="center">
              <div
                style={{
                  width: "15%",
                  backgroundColor: "#f5f5f5",
                  height: 4,
                  borderRadius: 5,
                  marginBottom: 20
                }}
              ></div>

              <Typography
                variant="caption"
                display="block"
                style={{ fontSize: 14, fontWeight: 600, padding: "20px 0px" }}
              >
                Maaf, tidak ada pengiriman yang tersedia!
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.buttonError}
                style={{
                  backgroundColor:
                    process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                }}
                variant="contained"
                fullWidth={true}
                onClick={() => {
                  setShippingNull(false);
                  history.push("/cart");
                }}
              >
                OK
              </Button>
            </div>
          </Dialog>

          <Dialog
            classes={{ paperFullScreen: classes.fullPaper }}
            fullScreen
            open={isNullShippingChannel}
            onClose={event => {
              setIsNullShippingChannel(false);
              history.push("/cart-shipment?tabs=1");
            }}
            TransitionComponent={Transition}
          >
            <div style={{ marginTop: 10 }} align="center">
              <div
                style={{
                  width: "15%",
                  backgroundColor: "#f5f5f5",
                  height: 4,
                  borderRadius: 5,
                  marginBottom: 20
                }}
              ></div>

              <Typography
                variant="caption"
                display="block"
                style={{ fontSize: 14, fontWeight: 600, padding: "20px 10px" }}
              >
                Maaf, tidak ada pengiriman yang tersedia!
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.buttonError}
                style={{
                  backgroundColor:
                    process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                }}
                variant="contained"
                fullWidth={true}
                onClick={() => {
                  setIsNullShippingChannel(false);
                  history.push("/cart-shipment?tabs=1");
                }}
              >
                OK
              </Button>
            </div>
          </Dialog>

          <Dialog
            classes={{ paperFullScreen: classes.fullPaper }}
            fullScreen
            open={dialogError || internalServer}
            onClose={event => {
              setDialogError(false);
              localStorage.removeItem("isUsed");
            }}
            TransitionComponent={Transition}
          >
            <div style={{ marginTop: 10 }} align="center">
              <div
                style={{
                  width: "15%",
                  backgroundColor: "#f5f5f5",
                  height: 4,
                  borderRadius: 5,
                  marginBottom: 20
                }}
              ></div>

              <Typography
                variant="caption"
                display="block"
                style={{ fontSize: 14, fontWeight: 600, padding: "20px 10px" }}
              >
                {errorMessage}
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.buttonError}
                style={{
                  backgroundColor:
                    process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                }}
                variant="contained"
                fullWidth={true}
                onClick={() => {
                  setDialogError(false);
                  localStorage.removeItem("isUsed");
                }}
              >
                OK
              </Button>
            </div>
          </Dialog>

          <Dialog
            classes={{ paperFullScreen: classes.fullPaper }}
            fullScreen
            open={internalServer}
            onClose={event => {
              setInternalServer(false);
            }}
            TransitionComponent={Transition}
          >
            <div style={{ marginTop: 10 }} align="center">
              <div
                style={{
                  width: "15%",
                  backgroundColor: "#f5f5f5",
                  height: 4,
                  borderRadius: 5,
                  marginBottom: 20
                }}
              ></div>

              <Typography
                variant="caption"
                display="block"
                style={{ fontSize: 14, fontWeight: 600, padding: "20px 10px" }}
              >
                {errorMessage}
              </Typography>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.buttonError}
                style={{
                  backgroundColor:
                    process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                }}
                variant="contained"
                fullWidth={true}
                onClick={() => {
                  setInternalServer(false);
                  history.push("/cart");
                }}
              >
                OK
              </Button>
            </div>
          </Dialog>

          <Dialog open={isLoading} TransitionComponent={Transition}>
            <div style={{ padding: 10 }}>
              <CircularProgress />
            </div>
          </Dialog>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default withTransaction("CartShipment", "component")(CartShipment);
