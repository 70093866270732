const style = theme => ({
  container: {
    paddingTop: 64,
    padding: 0,
    backgroundColor: "#FAFAFA",
    borderLeft: "1px solid #f1f1f1",
    borderRight: "1px solid #f1f1f1"
  },
  // orderStatus: {
  //   backgroundColor: "#2DBE78",
  // },
  // orderStatusText: {
  //   color: "white",
  //   fontSize: 12,
  //   fontWeight: 600,
  //   textAlign: "center",
  //   padding: 8,
  // },
  shippingTypeText: {
    fontWeight: 600,
    fontSize: 14
  },
  marketName: {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8
  },
  marketLocation: {
    fontWeight: 400,
    fontSize: 12
  },
  shippingIdText: {
    fontWeight: 500,
    fontSize: 10,
    textTransform: "uppercase"
  },
  checkStatusText: {
    fontWeight: 600,
    fontSize: 10
  },
  buttonCheckStatus: {
    padding: "4px 12px"
  },
  moreItemText: {
    fontWeight: 600,
    fontSize: 12,
    textTransform: "initial"
  },
  key: {
    fontWeight: 400,
    fontSize: 12
  },
  value: {
    fontWeight: 600,
    fontSize: 12
  },
  global: {
    body: { backgroundColor: "red" }
  }
});

export default style;
