/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import firebase from "firebase/app";
import "firebase/auth";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import { Toolbar } from "@material-ui/core";
import { withTransaction } from "@elastic/apm-rum-react";
import profilBG from "../../vector/profilBG.svg";
import arrowRight from "../../vector/arrowRight.svg";
import { getBuildDate } from "../../utilities/clear-cache-utils";

function ProfileComponent(props) {
  const { classes, history } = props;
  const initialState = {
    data: {},
    user: {}
  };
  const [state, setState] = useState(initialState);
  const [isVersion, setIsVersion] = useState("");
  useEffect(() => {
    const version = parseInt(localStorage.getItem("version"));
    setIsVersion(getBuildDate(version));
    async function fetchAPI() {
      const user = JSON.parse(localStorage.getItem("users"));
      const data = JSON.parse(localStorage.getItem("users"));
      setState({ ...state, user, data });
    }
    fetchAPI();
  }, []);
  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.href = `/`;
        const selectedPasar = JSON.parse(localStorage.getItem("selectedPasar"));
        const firstOpen = JSON.parse(localStorage.getItem("firstOpen"));
        localStorage.clear();
        localStorage.setItem("selectedPasar", JSON.stringify(selectedPasar));
        localStorage.setItem("firstOpen", JSON.stringify(firstOpen));
      });
  };
  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <CssBaseline />

        <AppBar
          elevation={0}
          position="static"
          className={classes.appbar}
          divider
        >
          <Toolbar>
            <Grid item xs={12} className={classes.profilApp}>
              <Typography className={classes.textProfil}>
                <b
                  style={{
                    fontFamily:
                      process.env.REACT_APP_FONT_FAMILY || "Montserrat"
                  }}
                >
                  Profil
                </b>
              </Typography>
              <Button
                disableRipple
                style={{
                  color: process.env.REACT_APP_COLOR_FONT || "#000000",
                  background: "#FFFFFF",
                  textTransform: "none",
                  fontSize: 16
                }}
              />
            </Grid>
          </Toolbar>
        </AppBar>

        <Paper
          elevation={0}
          style={{
            backgroundColor: "#FFFFFF"
          }}
          className={classes.root}
        >
          <Grid container spacing={0}>
            <Grid item xs={3} align="center" style={{ display: "flex" }}>
              <Avatar
                alt="Remy Sharp"
                src={state.data.photoURL ? state.data.photoURL : profilBG}
                className={classes.bigAvatar}
              />
            </Grid>
            <Grid
              item
              xs={9}
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Typography className={classes.nama} align="left">
                <b style={{ fontSize: 16, fontWeight: 700 }}>
                  {state.data.displayName ? state.data.displayName : "User"}
                </b>
                <p
                  style={{
                    fontSize: 10,
                    color: "#4E5356",
                    marginTop: 4,
                    marginBottom: 0
                  }}
                >
                  {state.data.email ? state.data.email : "User@email.com"}
                </p>
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <Paper
          elevation={0}
          style={{
            backgroundColor: "#FFFFFF",
            marginTop: "3%",
            boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.05)"
          }}
        >
          <List component="nav">
            <ListItem
              button
              onClick={() => history.push("/term-of-use")}
              style={{ padding: "20px 14px" }}
            >
              <div style={{ color: "#707585", fontSize: 12, fontWeight: 600 }}>
                Syarat Penggunaan{" "}
              </div>
              <img
                style={{ position: "absolute", right: 20 }}
                src={arrowRight}
                alt="arrow"
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem
              button
              onClick={() => history.push("/privacy-policy")}
              style={{ padding: "20px 14px" }}
            >
              <div style={{ color: "#707585", fontSize: 12, fontWeight: 600 }}>
                Kebijakan Privasi
              </div>
              <img
                style={{ position: "absolute", right: 20 }}
                src={arrowRight}
                alt="arrow"
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem
              button
              onClick={() => history.push("/about-us")}
              style={{ padding: "20px 14px" }}
            >
              <div style={{ color: "#707585", fontSize: 12, fontWeight: 600 }}>
                Tentang Kami
              </div>
              <div
                style={{
                  color: "#d1d1d1",
                  fontSize: 8,
                  fontWeight: 600,
                  position: "absolute",
                  right: 20,
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <img src={arrowRight} alt="arrow" style={{ marginLeft: 10 }} />
              </div>
            </ListItem>
            <Divider variant="middle" />
            <ListItem button onClick={signOut} style={{ padding: "20px 14px" }}>
              <div
                style={{
                  color: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                  fontSize: 12,
                  fontWeight: 700
                }}
              >
                Keluar
              </div>
            </ListItem>
          </List>
        </Paper>

        <Grid item xs={12} className={classes.versi}>
          <Typography className={classes.textVersi}>
            {process.env.REACT_APP_BRAND_NAME || "Srikopi"} v.
            {isVersion}
          </Typography>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default withTransaction(
  "ProfileComponent",
  "component"
)(ProfileComponent);
