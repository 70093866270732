const styles = (theme) => ({
  container: {
    width: "100%",
    padding: 16,
    display: "flex",
    flexDirection: "column",
  },
});

export default styles;
