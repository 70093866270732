const styles = (theme) => ({
  container: {
    marginBottom: 0,
    padding: 0,
    minHeight: '100%',
    height: '100%',
    borderLeft: '1px solid #f1f1f1',
    borderRight: '1px solid #f1f1f1',
    maxWidth: 444,
  },
  typography: {
    padding: 10,
  },
  img: {
    maxWidth: 200,
  },
  paper: {
    paddingTop: 64,
    borderRadius: 0,
    minHeight: '100vh',
  },
});
export default styles;
