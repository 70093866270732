import React from "react";

const Address = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M55.6061 35.5987C52.8036 36.5497 49.9901 37.4714 47.1654 38.3638C46.5832 38.5469 46.001 38.7292 45.4189 38.9106C44.8202 39.0971 44.2211 39.2817 43.6214 39.4643C42.2152 39.8951 40.806 40.3179 39.3938 40.7329C38.1366 41.1036 36.8776 41.4681 35.6167 41.8266C31.5961 42.9678 27.5577 44.0489 23.5015 45.0697C22.2836 45.376 21.0641 45.6767 19.8431 45.9719C18.6946 46.2501 17.5446 46.5233 16.3932 46.7915C16.2889 46.8167 16.1847 46.8404 16.0789 46.8655C13.8284 47.3885 11.5724 47.892 9.31114 48.3759C7.54421 48.7562 5.77433 49.124 4.0015 49.4792C3.27465 49.6261 2.54716 49.7706 1.81905 49.9128C1.20352 42.9592 1.6076 35.13 4.99374 28.9651C11.9151 16.3656 27.1208 12.6455 40.2135 18.5684C42.0128 19.3879 43.7321 20.3619 45.3507 21.4787C50.3687 24.9347 54.7529 29.9431 55.6061 35.5987Z"
        fill="#D6F3F5"
      />
      <path
        d="M55.6061 35.5982C52.8036 36.5492 49.9901 37.4709 47.1654 38.3633C46.5832 38.5464 46.001 38.7287 45.4189 38.9101L42.0458 25.6252L41.2217 22.3778L45.3507 21.4785C50.3687 24.9342 54.7529 29.9426 55.6061 35.5982Z"
        fill="#DFFDFF"
      />
      <path
        d="M43.6211 39.4645C42.2149 39.8952 40.8057 40.3181 39.3935 40.7331L35.0775 26.4901L39.4702 25.1504L43.6211 39.4645Z"
        fill="#F5F5F5"
      />
      <path
        d="M47.1651 38.363C46.5829 38.5461 46.0007 38.7284 45.4186 38.9098C44.82 39.0963 44.2208 39.2808 43.6211 39.4635L39.4703 25.1494L42.0455 25.6249L43.1665 25.8319L47.1651 38.363Z"
        fill="#A6A6A6"
      />
      <path
        d="M16.3929 46.7905C16.2887 46.8157 16.1844 46.8395 16.0787 46.8645C13.8281 47.3876 11.5722 47.891 9.31087 48.3749C9.1139 46.9259 8.91736 45.477 8.72126 44.0282C8.14773 39.7976 7.5742 35.5679 7.00067 31.3391C9.12389 30.6832 11.2254 30.0287 13.3487 29.374C14.028 32.5306 14.6651 35.771 15.2229 39.027C15.6675 41.6174 16.0643 44.2172 16.3929 46.7905Z"
        fill="#F5F5F5"
      />
      <path
        d="M35.6165 41.8265C31.5959 42.9677 27.5575 44.0487 23.5012 45.0696C22.2833 45.3759 21.0638 45.6766 19.8428 45.9718C18.6943 46.25 17.5444 46.5232 16.3929 46.7914C16.2887 46.8165 16.1844 46.8403 16.0787 46.8653L14.8695 39.1145L13.3488 29.375H19.2896L20.4295 36.2434L33.7657 33.6756L35.6165 41.8265Z"
        fill="#A6A6A6"
      />
      <path
        d="M19.8428 45.9715C18.6943 46.2497 17.5443 46.5229 16.3929 46.791C16.2886 46.8162 16.1844 46.84 16.0786 46.865C13.8281 47.388 11.5721 47.8915 9.31085 48.3754C7.54392 48.7557 5.77404 49.1235 4.00122 49.4787L3.08154 45.3838L8.72125 44.0287L11.0675 43.465L10.5171 40.1757L14.8693 39.1141L15.2227 39.0275L17.999 38.3506L19.8428 45.9715Z"
        fill="#808080"
      />
      <path
        d="M23.5012 45.0697C22.2833 45.376 21.0638 45.6767 19.8428 45.9719L17.9991 38.3512L21.4982 38.0645L23.5012 45.0697Z"
        fill="#B3B3B3"
      />
      <path
        d="M34.1422 20.0175C33.691 19.7884 33.4894 19.176 33.7946 18.7588C33.8738 18.6618 33.9733 18.5819 34.0864 18.5244C34.1996 18.4668 34.324 18.4328 34.4517 18.4245C34.5793 18.4162 34.7073 18.4338 34.8275 18.4762C34.9476 18.5187 35.0572 18.5849 35.1492 18.6708C35.0799 18.3531 35.054 18.0281 35.072 17.704C35.0811 17.5574 35.1226 17.4145 35.1938 17.2848C35.2649 17.1551 35.3641 17.0416 35.4845 16.952C35.6081 16.8666 35.7588 16.8257 35.9104 16.8364C36.062 16.8471 36.205 16.9087 36.3145 17.0106C36.312 16.6104 36.4311 15.9568 36.6545 15.6198C36.7616 15.4478 36.9131 15.3055 37.0941 15.2068C37.2751 15.1081 37.4795 15.0564 37.6873 15.0566C38.322 15.0899 38.7489 15.6952 38.9817 16.2664C39.2023 16.8079 39.343 17.3767 39.3997 17.956C39.438 17.5402 39.8569 17.4359 40.2843 17.4988C40.7354 17.5651 40.9394 17.9847 40.9983 18.3711C42.5267 18.1779 43.9113 17.4191 45.4127 17.1145C45.601 17.0762 45.8343 17.0543 45.9504 17.2027C45.9941 17.2763 46.0082 17.3629 45.9899 17.4459C45.9715 17.5288 45.9221 17.6023 45.851 17.6522C44.6983 18.555 42.8762 18.7087 41.4903 19.1207C39.7832 19.6288 38.0722 20.1245 36.3574 20.6081C35.0791 20.9686 33.7987 21.3223 32.5162 21.6692C32.3293 21.7196 30.106 22.4624 30.0952 22.0954C30.0724 21.3199 31.2645 21.0457 31.8443 20.7822C32.5799 20.4486 33.4303 20.3598 34.1422 20.0175Z"
        fill="#FAFAFA"
      />
      <path
        d="M1.06796 31.4738C0.817694 31.5129 0.563951 31.5438 0.305429 31.566C0.262107 31.5752 0.21728 31.5756 0.17378 31.5673C0.13028 31.5589 0.0890512 31.5419 0.0526987 31.5173C0.0206087 31.4812 0.00199733 31.4357 -0.000155887 31.3882C-0.00230911 31.3407 0.0121208 31.2938 0.040823 31.2551C0.100428 31.1791 0.177797 31.1178 0.266614 31.0762C0.509205 30.9403 3.64363 29.5048 3.67129 29.5591C3.52154 29.2665 3.25128 28.9037 3.49518 28.5724C3.54164 28.5167 3.60012 28.4714 3.66655 28.4397C3.73298 28.4079 3.80576 28.3906 3.87984 28.3888C3.95392 28.387 4.02751 28.4009 4.09549 28.4293C4.16347 28.4578 4.22422 28.5002 4.2735 28.5537C4.1514 28.2289 4.27104 27.7175 4.40211 27.3959C4.46379 27.2331 4.57458 27.0919 4.72018 26.9905C4.86579 26.8892 5.03952 26.8322 5.21895 26.8271C5.57654 26.8383 5.90791 27.1754 5.83883 27.5144C5.52628 27.1004 5.93384 26.3056 6.34081 25.9768C6.55216 25.8204 6.80641 25.7274 7.07202 25.7093C7.33762 25.6912 7.60288 25.7488 7.83488 25.875C8.06346 26.0065 8.259 26.1854 8.4074 26.3987C8.55581 26.6119 8.6534 26.8543 8.69315 27.1084C8.77266 27.561 8.66418 28.0151 8.56382 28.451C9.20498 28.3951 9.80849 28.1602 10.4321 28.0236C10.8521 27.9321 11.3252 27.8902 11.6865 28.0853C11.1341 28.6064 10.2298 28.7248 9.50753 28.9574C7.86153 29.4875 6.21611 30.0006 4.58083 30.5323C3.40075 30.9158 2.27484 31.2856 1.06796 31.4738Z"
        fill="#FAFAFA"
      />
      <path
        d="M59.4912 47.7485C59.3319 47.8384 59.1217 47.9504 58.8638 48.0816C54.5315 50.3001 37.001 58.1372 35.2274 58.8439C34.8703 58.9869 34.3955 59.2006 33.8857 59.407C32.921 59.7961 31.831 60.1528 31.1822 59.9331C29.0742 59.2168 21.8445 51.2288 21.2584 49.4642C21.0065 48.7051 22.2887 48.0936 23.0734 47.6998C23.168 47.6527 23.2566 47.6085 23.3329 47.5671C25.6563 46.3245 44.15 39.3462 45.7269 38.9924C46.2886 38.8657 46.8686 38.6593 47.4472 38.5148C48.3571 38.2878 49.2669 38.2156 50.1065 38.8495C51.6589 40.0229 56.8964 43.8274 57.8994 44.6721C58.6257 45.2825 61.0406 46.8728 59.4912 47.7485Z"
        fill="#EDD8D1"
      />
      <path
        d="M21.2337 43.6697C21.1553 44.3118 20.9661 46.9729 21.5983 47.7078C21.9573 48.1251 27.7401 54.1831 28.676 54.9399C29.2494 55.4032 29.9772 56.0182 30.7543 56.1049C33.2635 56.3846 57.2566 44.5883 57.9362 44.3001C58.431 44.0903 58.0966 42.3046 57.9977 41.4912L21.2337 43.6697Z"
        fill="#564953"
      />
      <path
        d="M57.0166 43.8806C52.4371 45.9156 47.7026 48.2135 43.0564 50.3615C42.4916 50.6218 41.928 50.8819 41.3662 51.1365C38.6058 52.3952 35.8873 53.5825 33.263 54.6105C33.2295 54.2888 33.2369 53.5615 33.1746 53.2804C35.8492 52.086 38.5348 50.8916 41.2314 49.6972C46.4859 47.3672 51.7171 44.9618 56.9355 42.6318C56.9941 43.0456 57.0212 43.463 57.0166 43.8806Z"
        fill="#BFAFA7"
      />
      <path
        d="M41.3662 51.1365C38.6058 52.3952 35.8873 53.5826 33.263 54.6105C33.2295 54.2889 33.2369 53.5616 33.1746 53.2805C35.8492 52.086 38.5348 50.8916 41.2314 49.6973L41.3662 51.1365Z"
        fill="#28A96B"
      />
      <path
        d="M57.5308 42.3158C57.38 42.4009 57.1803 42.5072 56.9355 42.6318C52.8252 44.7367 36.1927 52.1728 34.5098 52.8425C34.1709 52.9783 33.7205 53.181 33.2369 53.3769C32.3215 53.7461 31.2873 54.0846 30.6718 53.8762C28.6717 53.1964 21.8126 45.6176 21.2563 43.9437C21.0175 43.2234 22.2339 42.643 22.9784 42.2696C23.0682 42.2248 23.1522 42.1827 23.2246 42.1437C25.4288 40.9649 42.9751 34.3441 44.4712 34.0085C45.0042 33.8882 45.5546 33.6924 46.1033 33.5552C46.9667 33.34 47.8297 33.2714 48.6263 33.8727C50.0993 34.9861 55.0685 38.5957 56.02 39.397C56.7096 39.9758 59.0006 41.485 57.5308 42.3158Z"
        fill="#77758A"
      />
      <path
        d="M56.9355 42.6324C54.6195 43.8184 48.3253 46.698 42.9723 49.1048C42.3857 49.3693 41.8107 49.6265 41.2547 49.8754C41.2444 49.8797 41.2343 49.8852 41.2242 49.8894C37.8541 51.3983 35.1385 52.5942 34.5098 52.843C34.1709 52.9788 33.7205 53.1815 33.2369 53.3774C32.6805 52.7131 32.1162 52.0525 31.5438 51.3956C31.2165 51.0194 30.8853 50.6441 30.5501 50.2697C28.1068 47.5244 25.5477 44.856 22.9784 42.2699C23.0682 42.2252 23.1522 42.1831 23.2246 42.1441C24.0963 41.6783 27.3697 40.3596 31.1005 38.9149C31.6739 38.694 32.2591 38.4688 32.8471 38.2436C36.1984 36.9599 39.67 35.6662 41.957 34.8507C42.5943 34.6229 43.1404 34.4312 43.5646 34.2886C44.0078 34.1403 44.3205 34.0425 44.4713 34.0089C45.0042 33.8887 45.5546 33.6929 46.1034 33.5557C46.6479 34.0173 47.1949 34.4793 47.7443 34.9416C48.1918 35.3178 48.6408 35.6946 49.0912 36.0718C50.8235 37.5235 52.5716 38.9751 54.3066 40.4255L55.9187 41.7765C56.259 42.0618 56.598 42.3471 56.9355 42.6324Z"
        fill="#F5F5F5"
      />
      <path
        d="M45.0956 39.0201L37.4833 43.2158L32.8473 38.2425C36.1985 36.9588 39.6701 35.6651 41.9571 34.8496L45.0956 39.0201Z"
        fill="#2DBE78"
      />
      <path
        d="M24.7337 47.482C24.9872 47.9365 26.7796 49.8275 27.051 50.0344C27.395 50.2966 28.6825 49.2861 28.7709 49.0642C28.8614 48.8369 26.6438 46.6953 26.345 46.4822C26.0669 46.2837 24.514 47.088 24.7337 47.482Z"
        fill="#403A44"
      />
      <path
        d="M25.0803 47.6032C25.0803 47.6032 26.9967 49.6137 27.1778 49.7186C27.3588 49.8234 28.3002 49.1417 28.3907 49.0018C28.4812 48.8619 26.4536 46.9215 26.3269 46.834C26.2002 46.7466 24.9104 47.4122 25.0803 47.6032Z"
        fill="#5C606C"
      />
      <path
        d="M52.9725 38.1861L50.8869 36.3528C50.6805 36.171 50.3723 36.4673 50.5797 36.6495L52.6653 38.4828C52.8717 38.6642 53.1799 38.3684 52.9725 38.1861Z"
        fill="#BDCBD8"
      />
      <path
        d="M55.9188 41.7758C55.9188 41.7758 48.0326 47.2412 43.3053 42.0024L38.7968 44.6247L42.9722 49.1041C42.3857 49.3686 41.8107 49.6258 41.2547 49.8747L39.482 47.9615L31.5438 51.3951C31.2165 51.0189 30.8852 50.6435 30.5501 50.2691L38.3769 46.7673L31.1006 38.9144C31.674 38.6934 32.2593 38.4682 32.8473 38.2431L37.4833 43.2163C39.5948 42.0445 45.0956 39.0207 45.0956 39.0207L41.957 34.8502C42.5942 34.6223 43.1404 34.4306 43.5646 34.2881L44.8869 36.0684L47.7442 34.9411C48.1918 35.3173 48.6407 35.694 49.0912 36.0712L45.8615 37.3816L47.4879 39.5703L44.9477 41.0473C46.2684 42.2178 49.6475 44.0571 54.689 40.7454L55.9188 41.7758Z"
        fill="#FFFFFD"
      />
      <path
        d="M43.0564 50.3618C42.4913 50.6229 41.928 50.8813 41.3667 51.1372L41.2465 49.8505L42.9699 49.0908L43.0564 50.3618Z"
        fill="#D9D9D7"
      />
      <path
        d="M41.246 49.8504L41.2241 49.8883C37.8541 51.3972 35.1385 52.5931 34.5098 52.8419C34.1709 52.9777 33.7205 53.1804 33.2369 53.3763C32.6805 52.712 32.1162 52.0514 31.5438 51.3945L39.482 47.9609L41.246 49.8504Z"
        fill="#2DBE78"
      />
      <path
        d="M27.8732 41.0879L30.7554 39.79L34.1676 43.6702L31.3745 44.8948L27.8732 41.0879Z"
        fill="#A6A6A6"
      />
      <path
        d="M32.3284 46.4507L33.7209 48.264L36.534 46.827L35.1841 45.3838L32.3284 46.4507Z"
        fill="#A6A6A6"
      />
      <path
        d="M29.3853 47.9615L31.5432 46.8271L32.744 48.658L30.6244 49.6971L29.3853 47.9615Z"
        fill="#A6A6A6"
      />
      <path
        d="M39.957 44.8952L41.3662 44.126L42.1515 45.3847L40.8786 46.084L39.957 44.8952Z"
        fill="#A6A6A6"
      />
      <path
        d="M41.246 46.4512L43.7067 45.1045L46.024 47.2373L43.0564 48.496L41.246 46.4512Z"
        fill="#A6A6A6"
      />
      <path
        d="M47.11 37.7617L48.9204 36.918L49.717 38.1813L47.7618 39.033L47.11 37.7617Z"
        fill="#A6A6A6"
      />
      <path
        d="M23.1939 47.7826L22.2638 46.886C22.2228 46.8478 22.1679 46.8267 22.111 46.8272C22.054 46.8276 21.9995 46.8497 21.9593 46.8886C21.919 46.9275 21.8961 46.9801 21.8956 47.0351C21.8951 47.0901 21.917 47.1431 21.9566 47.1827C22.2665 47.4817 22.5766 47.7806 22.8867 48.0794C22.9275 48.1187 22.9827 48.1408 23.0404 48.1408C23.098 48.1408 23.1532 48.1187 23.194 48.0793C23.2347 48.04 23.2576 47.9866 23.2576 47.931C23.2576 47.8753 23.2347 47.822 23.1939 47.7826Z"
        fill="#362F34"
      />
      <path
        d="M23.5261 48.1816C23.4685 48.1816 23.4132 48.2037 23.3725 48.2431C23.3317 48.2824 23.3088 48.3358 23.3088 48.3914C23.3088 48.4471 23.3317 48.5004 23.3725 48.5398C23.4132 48.5791 23.4685 48.6012 23.5261 48.6012C23.5837 48.6012 23.639 48.5791 23.6797 48.5398C23.7204 48.5004 23.7433 48.4471 23.7433 48.3914C23.7433 48.3358 23.7204 48.2824 23.6797 48.2431C23.639 48.2037 23.5837 48.1816 23.5261 48.1816Z"
        fill="#362F34"
      />
      <path
        d="M27.9753 52.5273C27.9223 52.8131 27.8434 53.2419 27.7646 53.6918C28.6031 54.4293 29.5019 55.1002 30.4522 55.698C30.5584 55.2877 30.6071 54.8656 30.597 54.4428C29.6746 53.8688 28.7982 53.2285 27.9753 52.5273Z"
        fill="#61525E"
      />
      <path
        d="M31.6592 55.6807C31.6251 55.2498 31.5741 54.7996 31.5399 54.3682C31.3541 54.4308 31.1554 54.4496 30.9606 54.4228C30.9751 54.8424 30.9895 55.262 31.004 55.6815C31.1564 55.7476 31.4957 55.7119 31.6592 55.6807Z"
        fill="#61525E"
      />
      <path
        d="M56.7598 40.9735L56.724 40.9697C56.6664 40.9697 56.6111 40.9918 56.5704 41.0312C56.5297 41.0705 56.5068 41.1239 56.5068 41.1795C56.5084 41.2347 56.5318 41.2871 56.5722 41.3261C56.6126 41.3651 56.6669 41.3877 56.724 41.3893L56.7598 41.3931C56.8174 41.3931 56.8727 41.371 56.9134 41.3316C56.9542 41.2923 56.977 41.2389 56.977 41.1833C56.9754 41.1281 56.952 41.0757 56.9116 41.0367C56.8712 40.9977 56.8169 40.975 56.7598 40.9735Z"
        fill="#BDCBD8"
      />
      <path
        d="M29.9634 28.3233C29.7683 28.6935 29.5946 29.0739 29.443 29.4626C29.1994 28.5268 28.7606 27.5933 28.3635 26.7452C27.8211 25.5705 27.1373 24.4615 26.3263 23.4413C24.8255 21.5745 22.8545 20.1056 20.5346 19.3246C20.2015 19.2127 20.061 19.7198 20.3898 19.8324C22.6561 20.6083 24.5822 22.1037 26.0159 23.9541C26.722 24.8764 27.3253 25.8682 27.8156 26.9129C28.3113 27.9545 28.5795 29.0763 29.0287 30.1261C28.4934 29.8972 27.9361 29.7199 27.3647 29.5969C27.3193 29.5862 27.2714 29.5915 27.2297 29.6118C27.188 29.6321 27.1551 29.6661 27.1369 29.7077C27.1188 29.7492 27.1166 29.7957 27.1307 29.8387C27.1448 29.8818 27.1743 29.9185 27.2139 29.9424C27.8445 30.3016 28.6026 30.4979 29.287 30.7435C29.3518 30.7601 29.4206 30.7514 29.4788 30.7195C29.537 30.6876 29.58 30.6349 29.5984 30.5728C29.7964 29.8814 30.0674 29.2115 30.4072 28.5737C30.5581 28.2902 30.1137 28.0384 29.9634 28.3233Z"
        fill="#71B4BD"
      />
      <path
        d="M12.5057 4.52477C12.5025 4.51413 12.4966 4.50448 12.4884 4.49674C12.4802 4.489 12.4701 4.48345 12.459 4.48063C12.4479 4.47781 12.4363 4.47781 12.4252 4.48065C12.4142 4.48348 12.404 4.48905 12.3959 4.49679C12.1318 4.79678 12.0833 5.24376 11.9896 5.61843C11.8579 6.05515 11.7641 6.50172 11.7094 6.9535C11.7003 7.08104 11.8832 7.08566 11.9249 6.98147C12.0771 6.53128 12.2036 6.07336 12.3038 5.60976C12.3993 5.25621 12.5671 4.89105 12.5057 4.52477Z"
        fill="#222222"
      />
      <path
        d="M17.1032 5.3045C16.5483 4.58201 15.5011 4.23838 14.5052 4.17307C13.1993 4.08734 12.5132 4.46677 12.3685 4.62061C12.2157 4.40215 10.9741 3.6735 10.0208 3.47897C9.19138 3.31002 8.16134 3.48959 7.63647 3.75938C7.64067 3.68679 7.64792 3.61323 7.65849 3.54064C7.91412 1.65021 9.97404 -0.22511 13.1011 0.0220152C15.6277 0.22047 17.1332 2.49312 17.2162 4.36649C17.2157 4.6823 17.1777 4.99701 17.1032 5.3045Z"
        fill="#2DBE78"
      />
      <path
        d="M17.1032 5.30409C16.5483 4.5816 15.5011 4.23798 14.5052 4.17266C13.1993 4.08693 12.5132 4.46636 12.3685 4.6202C12.2157 4.40175 10.9741 3.6731 10.0208 3.47856C9.19138 3.30961 8.16134 3.48919 7.63647 3.75897C7.63647 3.75897 8.09023 2.65607 9.03699 2.49454C9.98375 2.333 10.7098 2.71495 10.8723 3.10752C11.3545 2.72652 11.9431 2.49251 12.5633 2.43524C13.56 2.36097 14.6153 3.04655 14.6923 3.62177C14.6923 3.62177 15.4963 2.96907 16.1713 3.15619C16.8464 3.34332 17.1613 4.53027 17.1032 5.30409Z"
        fill="#28A96B"
      />
      <path
        d="M17.0232 5.29823C16.0866 5.51333 15.1562 5.7557 14.2309 6.0115C13.5476 6.20086 12.8147 6.35946 12.1462 6.62141C13.1765 5.76731 14.0529 4.58693 14.8308 3.54668C14.9136 3.43605 14.7264 3.33564 14.6432 3.44081C14.1376 4.08107 13.6258 4.7135 13.0791 5.32173C12.6928 5.75122 12.2553 6.14911 11.8839 6.59148C11.8823 6.59064 11.8807 6.59008 11.8791 6.58938C11.7898 6.01393 11.6683 5.44356 11.5153 4.88076C11.3531 4.25519 11.212 3.57255 10.8737 3.01159C10.8246 2.9302 10.7144 2.98922 10.7269 3.07145C10.8223 3.70626 11.1034 4.32414 11.2816 4.943C11.4345 5.47529 11.5518 6.01527 11.6856 6.55134C11.244 6.11159 10.7553 5.71831 10.2278 5.37809C9.4089 4.80468 8.59944 4.15519 7.70844 3.69171C7.62691 3.64975 7.53566 3.75353 7.60706 3.8187C8.80829 4.91727 10.3435 5.62284 11.5008 6.76811C10.4498 7.3039 9.45134 7.98486 8.45534 8.60162C7.48265 9.203 6.54357 9.83753 5.78031 10.6839C5.61637 10.8657 5.93036 11.0846 6.09271 10.9046C7.6298 9.20048 9.88728 8.34233 11.7218 6.99216C11.7352 7.00364 11.7512 7.0119 11.7685 7.01627C11.7859 7.02065 11.804 7.02101 11.8215 7.01734C13.0879 8.49878 14.1734 10.1161 15.0567 11.8373C15.0744 11.8714 15.1051 11.8975 15.1423 11.9102C15.1796 11.9229 15.2204 11.9212 15.2564 11.9054C15.2923 11.8897 15.3206 11.8611 15.3352 11.8257C15.3498 11.7903 15.3497 11.7508 15.3348 11.7155C14.5273 9.96758 13.4604 8.34255 12.1678 6.89161C12.9326 6.73007 13.6898 6.43973 14.4368 6.23429C15.3189 5.9915 16.2062 5.76913 17.0939 5.54648C17.1257 5.53552 17.152 5.51338 17.1675 5.48449C17.1831 5.4556 17.1868 5.42209 17.1779 5.39069C17.1689 5.35928 17.148 5.3323 17.1193 5.31516C17.0907 5.29801 17.0563 5.29196 17.0232 5.29823Z"
        fill="#333333"
      />
      <path
        d="M1.74084 19.4451C4.24193 20.552 8.15467 22.1662 10.8036 23.3342C11.6658 23.2181 12.4938 23.0725 13.2917 22.9115C14.0585 22.7577 14.7966 22.5886 15.5083 22.4145C16.6723 22.132 17.7661 21.8391 18.7989 21.5819C16.0062 20.4786 11.9701 19.0676 8.98339 18.0195C6.83193 18.0706 3.59888 18.7915 1.74084 19.4451Z"
        fill="#AA7445"
      />
      <path
        d="M3.83698 18.9269C4.44353 19.1757 5.00547 19.4124 5.58943 19.7079C6.12849 19.5689 6.66233 19.2961 7.20125 19.1571C6.80383 18.9423 6.34457 18.6826 5.94716 18.4678C5.20505 18.5363 4.55447 18.7318 3.83698 18.9269Z"
        fill="#875B37"
      />
      <path
        d="M1.74084 19.4453C2.4256 19.2708 3.12716 19.0947 3.83698 18.9272C4.53376 18.7618 5.23995 18.6063 5.94673 18.468C6.95003 18.2669 7.96368 18.1172 8.98339 18.0198C9.40818 15.3001 9.84774 12.6847 9.90278 9.70703C8.95993 9.89989 7.98478 10.121 7.00341 10.3472C6.27361 10.515 5.5399 10.6869 4.81401 10.8515C4.13012 11.0053 3.45376 11.1551 2.7942 11.2903C2.53452 13.9835 1.99922 16.7516 1.74084 19.4453Z"
        fill="#CC955D"
      />
      <path
        d="M8.9837 18.0206C9.33014 15.1448 9.58924 12.7188 9.90294 9.70703C13.1836 10.9085 16.3899 12.2226 19.6705 13.4241C19.7112 16.0854 19.1105 19.3294 18.7992 21.5824C15.1232 20.4419 12.5267 19.2959 8.9837 18.0206Z"
        fill="#FECB94"
      />
      <path
        d="M4.68292 12.0575C4.92392 12.0003 6.16932 11.8422 6.8367 11.6343C6.89299 11.2054 6.94856 10.7765 7.0034 10.3477C6.2736 10.5155 5.53988 10.6874 4.81399 10.852C4.77301 11.2246 4.73694 11.563 4.68292 12.0575Z"
        fill="#B3804F"
      />
      <path
        d="M3.83698 18.9267C4.53376 18.7614 5.23995 18.6059 5.94672 18.4676C6.01132 18.0811 6.07591 17.6951 6.14051 17.3096C5.42331 17.3714 4.72841 17.4533 4.0115 17.514C3.9343 17.95 3.87347 18.4529 3.83698 18.9267Z"
        fill="#B3804F"
      />
      <path
        d="M12.1107 22.3347C12.406 22.5025 12.979 22.7214 13.2917 22.9116C14.0585 22.7578 14.7966 22.5887 15.5083 22.4146C15.0127 22.2071 14.2578 21.974 13.7622 21.7666C13.0518 21.9346 12.642 22.1901 12.1107 22.3347Z"
        fill="#875B37"
      />
      <path
        d="M17.4919 20.029C17.6724 19.8056 17.879 19.6032 18.1077 19.4258C18.2188 19.6884 18.2892 19.9654 18.3167 20.2478C18.2423 20.2404 18.1707 20.2295 18.1013 20.2161L17.9934 20.672L17.5644 20.5367C17.6051 20.3957 17.6671 20.2534 17.718 20.1118C17.6425 20.086 17.5675 20.0583 17.4919 20.029Z"
        fill="#875B37"
      />
      <path
        d="M16.6688 18.9795C16.5924 19.1181 16.5445 19.2696 16.5277 19.4258C16.5142 19.5026 16.5303 19.5816 16.5728 19.6478C16.6154 19.7141 16.6815 19.763 16.7588 19.7855C16.8361 19.8079 16.9193 19.8024 16.9926 19.7698C17.066 19.7373 17.1245 19.68 17.1572 19.6087C17.235 19.4696 17.2796 19.3154 17.2875 19.1574C17.0978 19.1065 16.8584 19.0303 16.6688 18.9795Z"
        fill="#875B37"
      />
      <path
        d="M16.442 20.0923L16.6022 20.139C16.638 20.0035 16.6738 19.8679 16.7097 19.7323C16.728 19.6624 16.8362 19.7043 16.818 19.773L16.7128 20.1714L16.8443 20.2099C16.9159 20.2307 16.8733 20.3358 16.8022 20.3145L16.3997 20.1969C16.328 20.1759 16.3706 20.0714 16.442 20.0923Z"
        fill="#875B37"
      />
      <path
        d="M5.46024 18.3618C6.93353 19.2277 8.46422 19.999 10.0424 20.6709L10.0528 20.6754C10.1532 20.6407 10.2527 20.6062 10.3503 20.5715C11.3614 20.2136 12.501 19.7603 13.58 19.6711C13.6857 19.6623 13.7446 19.8058 13.6368 19.8508C12.6305 20.272 11.5114 20.4762 10.4773 20.8413C10.4702 20.8436 10.4628 20.8466 10.4562 20.8491C11.7886 21.4225 13.1406 21.9819 14.3937 22.7033C14.4996 22.7641 14.4003 22.9422 14.2888 22.8879C12.9077 22.2148 11.5071 21.5961 10.0974 20.9839C9.19788 21.3398 8.31992 21.8025 7.40285 22.0748C7.28699 22.1092 7.15896 21.9642 7.2796 21.8825C7.97479 21.4113 8.85 21.0919 9.68277 20.8032L9.65757 20.7922C8.16582 20.1462 6.6451 19.4789 5.2973 18.5767C5.14957 18.4783 5.31077 18.2725 5.46024 18.3618Z"
        fill="#333333"
      />
      <path
        d="M13.8447 17.7045C14.0022 17.0167 14.1503 16.3268 14.2889 15.6347C14.4276 14.9426 14.5571 14.2492 14.6775 13.5547C14.7922 12.8926 14.8513 12.1991 15.056 11.5566C15.0913 11.4458 15.2432 11.503 15.2442 11.6008C15.2571 12.3329 15.0777 13.085 14.9536 13.8055C14.8337 14.5003 14.704 15.1935 14.5644 15.885C14.4248 16.5765 14.2761 17.2664 14.1185 17.9546C13.9723 18.5892 13.888 19.3432 13.5854 19.9265C13.5747 19.9504 13.5556 19.9699 13.5316 19.9816C13.5075 19.9932 13.4799 19.9962 13.4537 19.9901C13.4275 19.984 13.4044 19.9691 13.3885 19.9482C13.3726 19.9272 13.3648 19.9014 13.3667 19.8755C13.3829 19.1585 13.6838 18.4067 13.8447 17.7045Z"
        fill="#333333"
      />
      <path
        d="M5.998 10.6073C6.0033 10.5951 6.01255 10.5848 6.02437 10.578C6.0362 10.5712 6.04997 10.5683 6.06366 10.5697C6.07734 10.571 6.09021 10.5766 6.10035 10.5856C6.11049 10.5946 6.11736 10.6065 6.11995 10.6195C6.17687 11.8922 5.91719 13.2059 5.81812 14.4755C5.76656 15.1334 5.7222 15.792 5.68502 16.4511C5.64606 17.1382 5.67604 17.8573 5.56148 18.5359C5.54222 18.6503 5.3509 18.6716 5.33844 18.5428C5.2123 17.2484 5.42795 15.8518 5.52759 14.5569C5.62724 13.2619 5.63491 11.8614 5.998 10.6073Z"
        fill="#333333"
      />
      <path
        d="M7.6527 16.9832C7.81136 16.9294 7.97989 16.908 8.14758 16.9204C8.33771 16.8977 8.52985 16.8953 8.72053 16.9131C8.73059 16.9156 8.73958 16.921 8.74618 16.9287C8.75278 16.9364 8.75664 16.946 8.7572 16.956C8.75775 16.966 8.75496 16.9759 8.74925 16.9842C8.74353 16.9926 8.73519 16.9989 8.72545 17.0024C8.53782 17.0397 8.34652 17.057 8.15497 17.0541C7.98961 17.0837 7.81969 17.0797 7.65603 17.0424C7.65056 17.0396 7.64594 17.0355 7.64261 17.0305C7.63928 17.0254 7.63735 17.0197 7.63702 17.0137C7.63668 17.0077 7.63795 17.0018 7.6407 16.9965C7.64344 16.9911 7.64758 16.9866 7.6527 16.9832Z"
        fill="#8C663F"
      />
      <path
        d="M6.78587 17.0356C6.89356 17.006 7.00485 16.9903 7.11681 16.9889C7.21484 16.97 7.3162 16.9748 7.41183 17.0029C7.41593 17.0049 7.4194 17.008 7.42191 17.0117C7.42441 17.0155 7.42586 17.0198 7.42611 17.0242C7.42636 17.0287 7.4254 17.0331 7.42333 17.0371C7.42125 17.0411 7.41814 17.0445 7.41429 17.047C7.32232 17.0849 7.22207 17.1002 7.12246 17.0917C7.01085 17.1018 6.89835 17.0975 6.7879 17.079C6.78308 17.0777 6.7788 17.075 6.77568 17.0712C6.77256 17.0675 6.77076 17.0628 6.77053 17.058C6.77031 17.0532 6.77167 17.0484 6.77443 17.0444C6.77719 17.0404 6.78119 17.0373 6.78587 17.0356Z"
        fill="#8C663F"
      />
      <path
        d="M8.43808 17.262C8.49369 17.2582 8.54959 17.2557 8.60521 17.2541C8.64248 17.2479 8.68082 17.2552 8.71282 17.2747L8.72209 17.2758C8.7307 17.2778 8.73841 17.2825 8.74407 17.2891C8.74973 17.2957 8.75304 17.3039 8.75352 17.3124C8.75399 17.321 8.75159 17.3294 8.74669 17.3366C8.74179 17.3437 8.73463 17.3491 8.72629 17.352L8.71731 17.3541C8.68756 17.3767 8.65024 17.388 8.61245 17.3857C8.55698 17.3899 8.50108 17.3931 8.44546 17.395C8.43642 17.3954 8.42737 17.3942 8.41883 17.3913C8.41029 17.3884 8.40242 17.3838 8.39568 17.378C8.38895 17.3722 8.38347 17.3651 8.37956 17.3572C8.37565 17.3493 8.37339 17.3408 8.3729 17.332C8.37242 17.3233 8.37372 17.3146 8.37673 17.3063C8.37974 17.2981 8.38441 17.2905 8.39046 17.284C8.39651 17.2775 8.40383 17.2722 8.412 17.2684C8.42017 17.2646 8.42903 17.2624 8.43808 17.262Z"
        fill="#8C663F"
      />
      <path
        d="M6.86859 17.3852C7.09132 17.343 7.31702 17.3169 7.54379 17.3072C7.77023 17.2759 8 17.274 8.22695 17.3017C8.23541 17.3038 8.24297 17.3084 8.24851 17.3149C8.25406 17.3214 8.25731 17.3294 8.25778 17.3378C8.25825 17.3462 8.25591 17.3546 8.25112 17.3616C8.24633 17.3687 8.23933 17.374 8.23115 17.3769C8.00856 17.4278 7.77991 17.4496 7.55132 17.4417C7.32476 17.4554 7.09747 17.4527 6.87134 17.4336C6.86587 17.4323 6.86097 17.4294 6.85737 17.4252C6.85378 17.421 6.85167 17.4158 6.85136 17.4104C6.85105 17.405 6.85256 17.3996 6.85566 17.395C6.85876 17.3905 6.86329 17.3871 6.86859 17.3852Z"
        fill="#8C663F"
      />
      <path
        d="M7.91428 17.6331C8.17107 17.6373 8.44147 17.5973 8.6955 17.6229C8.7446 17.6279 8.74546 17.7026 8.70057 17.7149C8.44404 17.764 8.18154 17.7774 7.92109 17.7545C7.841 17.7527 7.83303 17.6322 7.91428 17.6331Z"
        fill="#8C663F"
      />
      <path
        d="M7.48191 17.6369C7.51319 17.6359 7.54534 17.633 7.57663 17.634C7.59121 17.6344 7.60574 17.636 7.62008 17.6386C7.63731 17.642 7.65281 17.6515 7.67019 17.6534C7.67881 17.6559 7.68643 17.6609 7.69201 17.6677C7.69758 17.6745 7.70083 17.6828 7.70131 17.6914C7.70178 17.7001 7.69947 17.7087 7.69467 17.7161C7.68988 17.7234 7.68284 17.7291 7.67453 17.7324C7.65759 17.7361 7.64296 17.7471 7.62645 17.7523C7.61226 17.7565 7.59772 17.7595 7.583 17.7613C7.55186 17.7653 7.51971 17.7659 7.48843 17.7681C7.47089 17.7682 7.45401 17.7617 7.4413 17.75C7.42859 17.7384 7.42102 17.7224 7.42018 17.7055C7.41934 17.6886 7.42529 17.672 7.43679 17.6593C7.44829 17.6465 7.46445 17.6385 7.48191 17.6369Z"
        fill="#8C663F"
      />
      <path
        d="M14.6914 3.62259C15.1096 2.4211 14.7248 0.0681658 13.0165 0.0105453C11.3082 -0.0470752 10.8718 3.10835 10.8718 3.10835C11.354 2.72734 11.9426 2.49334 12.5629 2.43606C13.56 2.3618 14.6153 3.04737 14.6914 3.62259Z"
        fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
      />
      <path
        d="M33.5159 39.3064C33.0815 40.1245 32.2226 41.7077 31.0813 41.5818C30.4788 41.5147 29.985 41.0532 29.5737 40.5091C29.2043 40.0224 28.9031 39.47 28.6496 39.0826C27.8111 37.7959 27.3244 35.7666 27.9805 34.3331C28.3345 33.5492 28.9799 32.9219 29.7895 32.5751C30.2514 32.3744 30.7549 32.2789 31.2612 32.2959C31.7674 32.3128 32.2628 32.4419 32.7092 32.673C32.9165 32.7804 33.1116 32.9084 33.2915 33.0548C35.1308 34.5429 34.4608 37.526 33.5159 39.3064Z"
        fill="#2DBE78"
      />
      <path
        d="M33.2914 33.0548C33.1116 32.9084 32.9165 32.7804 32.7092 32.673C32.1887 32.4017 31.6017 32.2722 31.0108 32.2982C31.5047 32.3182 31.9874 32.4463 32.4225 32.673C32.6297 32.7804 32.8249 32.9084 33.0047 33.0548C34.844 34.5429 34.1735 37.526 33.2292 39.3064C32.8296 40.0588 32.0705 41.4568 31.0642 41.5793C31.07 41.58 31.0755 41.5812 31.0813 41.5818C32.2226 41.7077 33.0814 40.1245 33.5159 39.3064C34.4608 37.526 35.1308 34.5429 33.2914 33.0548Z"
        fill="#28A96B"
      />
      <path
        d="M33.067 37.2911C32.8018 37.6146 32.4539 37.8658 32.0575 38.0197C31.8937 38.0853 31.7233 38.1345 31.5491 38.1666C31.0916 38.2578 30.6167 38.2224 30.179 38.0645C29.6818 37.8691 29.2591 37.5306 28.9683 37.0949C28.6775 36.6592 28.5329 36.1475 28.554 35.6296C28.6511 34.0101 30.2645 33.1485 31.6665 33.4562C31.8385 33.4942 32.006 33.5495 32.1661 33.6213C32.3264 33.6927 32.4776 33.7818 32.6165 33.887C32.8137 34.0326 32.9871 34.2061 33.1307 34.4017C33.4336 34.8295 33.5904 35.3381 33.579 35.8562C33.5675 36.3744 33.3885 36.8761 33.067 37.2911Z"
        fill="#FFE366"
      />
      <path
        d="M30.179 38.0645C29.6818 37.8691 29.2591 37.5306 28.9683 37.0949C28.6775 36.6592 28.5329 36.1475 28.554 35.6296C28.6511 34.0101 30.2645 33.1485 31.6664 33.4562C32.0285 34.8198 30.9973 36.8016 30.179 38.0645Z"
        fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
      />
      <path
        d="M32.0575 38.0196C31.8937 38.0851 31.7233 38.1343 31.5491 38.1664C32.0659 36.706 32.2756 35.1607 32.1661 33.6211C32.3264 33.6925 32.4776 33.7817 32.6165 33.8868C32.6834 35.2854 32.4942 36.6845 32.0575 38.0196Z"
        fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Address;
