const styles = (theme) => ({
  root: {},
  container: {
    padding: 0,
    minHeight: '100vh',
    height: '100%',
    borderLeft: '1px solid #f1f1f1',
    borderRight: '1px solid #f1f1f1',
  },
  indicator: { paddingTop: 50, marginBottom: 50 },
  body: {
    minHeight: '100vh',
    display: 'flex',
    padding: '80px 16px',
  },
  text12: {
    fontSize: '12px !important',
    margin: '4px 0',
  },
  text14: {
    fontSize: '14px !important',
    margin: '4px 0',
  },
});
export default styles;
