import React from "react";

const Payment = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 3.41699H13.1875V4.35449H0V3.41699Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
    <path
      d="M13.1875 2.19825C13.1875 1.42284 12.5567 0.791992 11.7812 0.791992H1.40625C0.630844 0.791992 0 1.42284 0 2.19825V2.4795H13.1875V2.19825Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
    <path
      d="M13.1562 6.58218C13.1667 6.58218 13.1771 6.58249 13.1875 6.58259V5.29199H0V9.13574C0 9.91115 0.630844 10.542 1.40625 10.542H9.37931C9.37653 10.4828 9.37497 10.4233 9.37497 10.3635C9.375 8.27843 11.0712 6.58218 13.1562 6.58218ZM2.34375 6.22949H3.51562C3.7745 6.22949 3.98438 6.43937 3.98438 6.69824C3.98438 6.95712 3.7745 7.16699 3.51562 7.16699H2.34375C2.08488 7.16699 1.875 6.95712 1.875 6.69824C1.875 6.43937 2.08488 6.22949 2.34375 6.22949ZM5 8.66696H2.34375C2.08488 8.66696 1.875 8.45709 1.875 8.19821C1.875 7.93934 2.08488 7.72946 2.34375 7.72946H5C5.25887 7.72946 5.46875 7.93934 5.46875 8.19821C5.46875 8.45709 5.25887 8.66696 5 8.66696Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
    <path
      d="M13.1562 7.51953C11.5882 7.51953 10.3125 8.79525 10.3125 10.3633C10.3125 11.9313 11.5882 13.207 13.1562 13.207C14.7243 13.207 16 11.9313 16 10.3633C16 8.79525 14.7243 7.51953 13.1562 7.51953ZM14.519 10.1219L13.394 11.2469C13.3024 11.3385 13.1825 11.3842 13.0625 11.3842C12.9426 11.3842 12.8226 11.3385 12.7311 11.2469L12.153 10.6688C11.9699 10.4858 11.9699 10.189 12.153 10.0059C12.336 9.82284 12.6328 9.82284 12.8159 10.0059L13.0626 10.2526L13.8561 9.45903C14.0392 9.27597 14.336 9.27597 14.519 9.45903C14.702 9.64209 14.702 9.93888 14.519 10.1219Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
  </svg>
);
export default Payment;
