/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import ContentLoader from "react-content-loader";
import Spotlight from "react-spotlight";
import axios from "axios";
import { Container, CssBaseline, Grid, Typography } from "@material-ui/core";
import Header from "../../components/header-home";
import Carousel from "../../components/slider-home";
import Category from "../../components/product-category";
import Slider from "../../components/slider-top-seller";
import FabCart from "../../components/fab";
import Dialog from "@material-ui/core/Dialog";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Clear from "@material-ui/icons/Clear";
import { withTransaction } from "@elastic/apm-rum-react";

import { CartContext } from "../../context/cart";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import atas from "../../vector/atas.svg";
import Other from "../../vector/other.js";

import {
  getProductTopSellers,
  getProductCategories
} from "../../services/products";

import firebase from "firebase/app";
import "firebase/analytics";

const MyLoader = () => (
  <ContentLoader
    height={600}
    width={400}
    speed={1}
    primaryColor="#ededed"
    secondaryColor="#d1d1d1"
  >
    <circle cx="493" cy="62" r="30" />
    <rect x="16" y="20" rx="0" ry="0" width="108" height="20" />
    <rect x="16" y="45" rx="0" ry="0" width="218" height="20" />
    <rect x="16" y="85" rx="20" ry="20" width="370" height="35" />

    <rect x="16" y="150" rx="0" ry="0" width="88" height="20" />
    <rect x="16" y="175" rx="0" ry="0" width="200" height="20" />
    <rect x="16" y="215" rx="0" ry="0" width="350" height="130" />

    <rect x="16" y="370" rx="10" ry="10" width="64" height="66" />
    <rect x="16" y="445" rx="0" ry="0" width="64" height="16" />

    <rect x="92" y="370" rx="10" ry="10" width="64" height="66" />
    <rect x="92" y="445" rx="0" ry="0" width="64" height="16" />

    <rect x="167" y="370" rx="10" ry="10" width="64" height="66" />
    <rect x="167" y="445" rx="0" ry="0" width="64" height="16" />

    <rect x="244" y="370" rx="10" ry="10" width="64" height="66" />
    <rect x="244" y="445" rx="0" ry="0" width="64" height="16" />

    <rect x="320" y="370" rx="10" ry="10" width="64" height="66" />
    <rect x="320" y="445" rx="0" ry="0" width="64" height="16" />

    <rect x="16" y="480" rx="10" ry="10" width="64" height="66" />
    <rect x="16" y="555" rx="0" ry="0" width="64" height="16" />

    <rect x="92" y="480" rx="10" ry="10" width="64" height="66" />
    <rect x="92" y="555" rx="0" ry="0" width="64" height="16" />

    <rect x="167" y="480" rx="10" ry="10" width="64" height="66" />
    <rect x="167" y="555" rx="0" ry="0" width="64" height="16" />

    <rect x="244" y="480" rx="10" ry="10" width="64" height="66" />
    <rect x="244" y="555" rx="0" ry="0" width="64" height="16" />

    <rect x="320" y="480" rx="10" ry="10" width="64" height="66" />
    <rect x="320" y="555" rx="0" ry="0" width="64" height="16" />
  </ContentLoader>
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HomePage(props) {
  const listPasar = JSON.parse(localStorage.getItem("listLocation"));
  const { classes } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isSpotlight, setIsSpotlight] = useState(
    listPasar.length > 1 ? true : false
  );
  const isMobile = useMediaQuery("(max-width:700px)");
  const selectedPasar = useState(
    JSON.parse(localStorage.getItem("selectedPasar"))
  );
  const [categories, setCategories] = useState([]);
  const [topSellers, setTopSellers] = useState([]);
  const [allCategory, setAllCategory] = useState(false);
  const [categoryRow, setCategoryRow] = useState(null);
  const [enableFeaturedProduct, setEnableFeaturedProduct] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setIsSpotlight(false);
        localStorage.setItem("firstOpen", false);
      }, 3000);
    }
  }, [isLoading]);

  useEffect(() => {
    setCategoryRow(process.env.REACT_APP_CATEGORY_ROW || "1");
    setEnableFeaturedProduct(
      process.env.REACT_APP_ENABLE_FEATURED_PRODUCT || "true"
    );
    firebase.analytics().logEvent("application_started");

    const getData = async vendorId => {
      const topSeller = await getProductTopSellers(vendorId);
      const category = await getProductCategories();

      setTopSellers(topSeller.data);
      setCategories(category);

      setIsLoading(false);
    };

    const vendorId = selectedPasar.id;
    getData(vendorId);
  }, []);

  const { cart } = useContext(CartContext);

  const fabStyle = () => {
    if (cart.length > 0) {
      return { marginBottom: 56 };
    }
  };

  console.log(process?.env?.REACT_APP_CATEGORY);
  return (
    <>
      <CssBaseline />
      {!localStorage.getItem("firstOpen") && (
        <Fade in={isSpotlight && !isLoading}>
          <div style={{ maxWidth: 444 }}>
            <Spotlight
              x={isMobile ? 80 : 60}
              y={10}
              color="#0A0A0D"
              radius={100}
              usePercentage
              outerStyles={{ zIndex: 9999 }}
            >
              <div style={{ marginTop: 200 }}>
                <img src={atas} alt="top" />
                <h4 style={{ color: "white", margin: "8px 0px" }}>
                  Tekan tombol Ganti
                </h4>
                <h4 style={{ color: "white", margin: 0 }}>
                  untuk pilih Lokasi
                </h4>
              </div>
            </Spotlight>
          </div>
        </Fade>
      )}
      <Container maxWidth="xs" className={classes.container} style={fabStyle()}>
        {isLoading ? (
          <MyLoader />
        ) : (
          <>
            <div
              style={{
                position: "fixed",
                maxWidth: 442,
                top: 0,

                zIndex: 99,
                height: 150,
                width: "100%",
                backgroundColor: "#fff",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)"
              }}
            >
              <Header />
            </div>
            <br />
            <div className={classes.gridSlider} style={{ marginTop: 143 }}>
              <Carousel />
            </div>
            {categoryRow === "2" ? (
              <div className={classes.gridCategories}>
                {categories.length >= 11 ? (
                  <>
                    <div className={classes.gridCategory}>
                      {categories.slice(0, 5).map(item => {
                        return (
                          <div style={{ width: "20%" }}>
                            <Category
                              image={item.image.url}
                              name={item.name}
                              id={item.id}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div
                      className={classes.gridCategory}
                      style={{ marginTop: 12 }}
                    >
                      {categories.slice(5, 9).map(item => {
                        return (
                          <div style={{ width: "20%" }}>
                            <Category
                              image={item.image.url}
                              name={item.name}
                              id={item.id}
                            />
                          </div>
                        );
                      })}
                      <div
                        style={{ width: "20%" }}
                        onClick={() => {
                          setAllCategory(true);
                        }}
                      >
                        <Category image={<Other />} name="Lainnya" other />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className={classes.gridCategory}>
                      {categories.slice(0, 5).map(item => {
                        return (
                          <div style={{ width: "20%" }}>
                            <Category
                              image={item.image.url}
                              name={item.name}
                              id={item.id}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div
                      className={classes.gridCategory}
                      style={{ marginTop: 12 }}
                    >
                      {categories.slice(5, 10).map(item => {
                        return (
                          <div style={{ width: "20%" }}>
                            <Category
                              image={item.image.url}
                              name={item.name}
                              id={item.id}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className={classes.gridCategories}>
                {categories.length >= 6 ? (
                  <>
                    <div className={classes.gridCategory}>
                      {categories.slice(0, 4).map(item => {
                        return (
                          <div style={{ width: "20%" }}>
                            <Category
                              image={item.image.url}
                              name={item.name}
                              id={item.id}
                            />
                          </div>
                        );
                      })}
                      <div
                        style={{ width: "20%" }}
                        onClick={() => {
                          setAllCategory(true);
                        }}
                      >
                        <Category image={<Other />} name="Lainnya" other />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className={classes.gridCategory}>
                      {categories.slice(0, 5).map(item => {
                        return (
                          <div style={{ width: "20%" }}>
                            <Category
                              image={item.image.url}
                              name={item.name}
                              id={item.id}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            )}

            {enableFeaturedProduct === "true" ? (
              <div className={classes.gridTopSeller}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "16px 16px 20px"
                  }}
                >
                  <Grid item xs={9}>
                    <Typography style={{ fontSize: 13, fontWeight: 700 }}>
                      Produk Pilihan
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 10,
                        fontWeight: 400,
                        color: "#252525"
                      }}
                    >
                      Produk pilihan yang terbaik untukmu
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end"
                    }}
                  >
                    <span
                      className={classes.moreText}
                      onClick={() => {
                        props.history.push("/top-seller");
                      }}
                    >
                      Lihat Semua
                    </span>
                  </Grid>
                </Grid>
                <Grid className={classes.scrollingWrapper}>
                  {topSellers.map(item => {
                    return <Slider top={item} />;
                  })}
                  <Grid className={classes.sliderMarginEnd} />
                </Grid>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <FabCart to="/cart?from=/" />
        </div>

        <Dialog
          classes={{
            paperFullScreen: classes.fullPaper,
            scrollPaper: classes.paper
          }}
          fullScreen
          open={allCategory}
          onClose={event => {
            setAllCategory(false);
          }}
          TransitionComponent={Transition}
        >
          <div className={classes.header}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  backgroundColor: "#F1F2F6",
                  width: "8%",
                  height: 4,
                  borderRadius: 10
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                paddingRight: 15
              }}
            >
              {" "}
              <Clear
                style={{ color: "grey" }}
                onClick={() => setAllCategory(false)}
              />
            </div>

            <Typography style={{ marginTop: 5, fontWeight: "bolder" }}>
              Semua Kategori
            </Typography>
          </div>
          <div style={{ marginTop: 85, borderTop: "2px solid #fafafa" }}>
            <div className={classes.gridCategories}>
              <div className={classes.gridCategory}>
                {categories.map(item => {
                  return (
                    <div style={{ width: "20%" }}>
                      <Category
                        image={item.image.url}
                        name={item.name}
                        id={item.id}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Dialog>
      </Container>
    </>
  );
}

export default withTransaction("HomePage", "component")(HomePage);
