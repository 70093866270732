const styles = theme => ({
  media: {
    height: 180,
    borderRadius: 5
  },
  title: {
    color: "#252525",
    fontWeight: "bold",
    fontSize: 14
  },
  old: {
    textDecoration: "line-through",
    color: "grey",
    marginRight: 10,
    fontSize: 10
  },
  price: {
    color: "#25282B",
    fontWeight: 700,
    fontSize: 13
  },
  container: {
    padding: 0,
    paddingTop: 64,
    marginBottom: 0,
    height: "100vh",
    borderLeft: "1px solid #f1f1f1",
    borderRight: "1px solid #f1f1f1"
  },
  cardMediaTitle: {
    color: process.env.REACT_APP_COLOR_FONT || "#000000",
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
    width: "fit-content",
    height: 42,
    fontWeight: "bold",
    fontSize: 17,
    borderRadius: "0 0 10px 0",
    padding: "8px 12px",
    opacity: 0.9,
    margin: 0
    // marginBottom: 226,
  },
  cardMedia: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "100%"
  },
  descript: {
    padding: 20
  },
  paper: {
    borderRadius: 0,
    height: "100%"
  },
  card: {
    minHeight: 600,
    borderRadius: 0
  },
  btn: {
    justifyContent: "space-between",
    display: "flex",
    marginBottom: 10
  },
  box: {
    marginTop: 8,
    padding: 4
  },
  stickToBottom: {
    width: "100%",
    maxWidth: 442,
    position: "fixed",
    bottom: 0,
    padding: "auto",
    backgroundColor: "white"
  },
  paperbtn: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 442,
    borderRadius: 0
  },
  button: { backgroundColor: "#ED6B5A", color: "white" },
  cardMediaDigital: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    width: "103px",
    padding: "5px 10px",
    textAlign: "center",
    fontWeight: 600,
    fontSize: 8,
    borderRadius: "0px 0px 5px 0px"
  }
});

export default styles;
