/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Paper from "@material-ui/core/Paper";
import ContentLoader from "react-content-loader";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { withTransaction } from "@elastic/apm-rum-react";
import { getProductDetail } from "../../services/products";
import Fab from "../../components/fab";
import currencyFormatter from "../../utilities/currency-formatter";
import AppBar from "../../components/app-bar";

import { CartContext } from "../../context/cart";

const MyLoader = () => (
  <ContentLoader
    height={600}
    width={400}
    speed={1}
    primaryColor="#ededed"
    secondaryColor="#d1d1d1"
  >
    <rect x="14" y="30" rx="5" ry="5" width="376" height="267" />
    <rect x="18" y="320" rx="0" ry="0" width="182" height="29" />
    <rect x="287" y="319" rx="0" ry="0" width="98" height="32" />
    <rect x="16" y="380" rx="5" ry="5" width="368" height="58" />
    <rect x="18" y="461" rx="5" ry="5" width="271" height="42" />
    <rect x="20" y="524" rx="5" ry="5" width="237" height="28" />
  </ContentLoader>
);

function ProductDetails(props) {
  const { addCart, increaseCart, decreaseCart, cart, cartUpdated } = useContext(
    CartContext
  );

  const [productDetails, setProductDetails] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const discountPercent =
    productDetails.promoPriceType === "PERCENTAGE"
      ? productDetails.promoPrice
      : (100 -
          (productDetails.promoPrice / productDetails.regularPrice) * 100) %
          1 ===
        0
      ? 100 - (productDetails.promoPrice / productDetails.regularPrice) * 100
      : (
          100 -
          (productDetails.promoPrice / productDetails.regularPrice) * 100
        ).toFixed(2);

  const fabStyle = () => {
    if (cart.length > 0) {
      return { paddingBottom: 100 };
    }
  };

  const productId = props.match.params.id;

  const [qty, setQty] = useState(0);

  useEffect(() => {
    const initializeApp = async () => {
      const response = await getProductDetail(productId);
      const selectedItem = cart.find(item => item.id === response.id);
      if (selectedItem) {
        setQty(selectedItem.total);
      } else {
        setQty(0);
      }
      setProductDetails(response);
      setIsLoading(false);
    };
    initializeApp();
  }, [cart, productId]);

  useEffect(() => {
    const selectedItem = cart.find(item => item.id === productDetails.id);
    console.log(selectedItem);
    if (selectedItem) {
      setQty(selectedItem.total);
    } else {
      setQty(0);
    }
  }, [cart, cartUpdated, productDetails.id]);

  const { classes } = props;

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <CssBaseline />
        <AppBar title="Detail Produk" goBack={true} />
        {isLoading ? (
          <MyLoader />
        ) : (
          <Paper elevation={0} className={classes.paper} style={fabStyle()}>
            <div>
              <CardMedia
                className={classes.media}
                image={
                  productDetails.image.url
                    ? productDetails.image.url
                    : "https://via.placeholder.com/150"
                }
              >
                <div className={classes.cardMedia}>
                  {productDetails.size === null && (
                    <div className={classes.cardMediaDigital}>
                      Produk Digital
                    </div>
                  )}
                </div>
              </CardMedia>
            </div>
            <CardContent>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography
                    display="block"
                    variant="caption"
                    className={classes.title}
                    gutterBottom
                  >
                    {productDetails.name}
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {productDetails.isPromo && (
                      <>
                        <Typography
                          variant="caption"
                          style={{
                            color:
                              process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"
                          }}
                        >
                          Diskon <b>{discountPercent}%</b>
                        </Typography>
                        <div style={{ color: "grey", margin: "0px 10px" }}>
                          |
                        </div>
                      </>
                    )}
                    {productDetails.isPromo && (
                      <Typography
                        display="block"
                        gutterBottom
                        variant="caption"
                      >
                        <b className={classes.old}>
                          {currencyFormatter.format(
                            productDetails.regularPrice
                          )}
                        </b>
                      </Typography>
                    )}
                    {!productDetails.isPromo && (
                      <Typography
                        display="block"
                        gutterBottom
                        variant="caption"
                        className={classes.price}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                        <b>{currencyFormatter.format(productDetails.price)}</b>
                        <div style={{ color: "grey", fontSize: 10 }}>
                          {" "}
                          {productDetails.unit ? `/${productDetails.unit}` : ""}
                        </div>
                      </Typography>
                    )}
                    {productDetails.isPromo && (
                      <>
                        <Typography
                          display="block"
                          gutterBottom
                          variant="caption"
                          className={classes.price}
                        >
                          <b>
                            {currencyFormatter.format(productDetails.price)}
                          </b>
                        </Typography>
                        <div style={{ color: "grey", fontSize: 10 }}>
                          {productDetails.unit ? `/${productDetails.unit}` : ""}
                        </div>
                      </>
                    )}
                  </div>
                </Grid>

                <Grid style={{ marginTop: 10 }} item xs={12}>
                  <Divider fullWidth />
                  <Typography
                    variant="caption"
                    style={{ marginTop: 7, fontSize: 10 }}
                  >
                    <b>Deskripsi:</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: productDetails.description || "-"
                      }}
                      style={{ marginBottom: -7, fontSize: 10 }}
                    />
                  </Typography>
                  <Divider style={{ marginBottom: 22 }} fullWidth />
                </Grid>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Grid item xs={4}>
                    {cart && qty > 0 ? (
                      <ButtonGroup
                        size="small"
                        aria-label="Small outlined button group"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                          width: "-webkit-fill-available"
                        }}
                      >
                        <Button
                          onClick={() => {
                            decreaseCart(productDetails);
                          }}
                          style={{
                            color: "#000",
                            borderRadius: 4,
                            maxWidth: 30,
                            minWidth: 30,
                            maxHeight: 30,
                            minHeight: 30,
                            padding: 1,
                            borderRight: "1px solid rgba(0, 0, 0, 0.23)"
                          }}
                        >
                          -
                        </Button>
                        <Button
                          style={{
                            border: "0",
                            fontWeight: "bold",
                            fontSize: 12,
                            maxWidth: 30,
                            minWidth: 30,
                            maxHeight: 30,
                            minHeight: 30,
                            padding: 1
                          }}
                        >
                          {qty || 0}
                        </Button>
                        <Button
                          onClick={() => {
                            increaseCart(productDetails);
                          }}
                          style={{
                            color:
                              process.env.REACT_APP_COLOR_FONT || "#000000",
                            backgroundColor:
                              process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                            borderRadius: 4,
                            maxWidth: 30,
                            minWidth: 30,
                            maxHeight: 30,
                            minHeight: 30,
                            padding: 1
                          }}
                        >
                          +
                        </Button>
                      </ButtonGroup>
                    ) : (
                      <>
                        {productDetails.onStock ? (
                          <Button
                            onClick={() => {
                              addCart(productDetails);
                            }}
                            style={{
                              color:
                                process.env.REACT_APP_COLOR_FONT || "#000000",
                              backgroundColor:
                                process.env.REACT_APP_COLOR_PRIMARY ||
                                "#FFD101",
                              borderRadius: 4,
                              width: "100%",
                              height: 30,
                              fontSize: 13,
                              fontWeight: 700,
                              textTransform: "capitalize",
                              fontFamily:
                                process.env.REACT_APP_FONT_URL_BUTTON ||
                                "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
                            }}
                          >
                            Tambahkan
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            disabled
                            style={{
                              borderRadius: 4,
                              width: "100%",
                              padding: 0,
                              height: 30,
                              fontSize: 10,
                              textTransform: "capitalize",
                              fontFamily:
                                process.env.REACT_APP_FONT_URL_BUTTON ||
                                "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
                            }}
                          >
                            <b>Stok Habis</b>
                          </Button>
                        )}
                      </>
                    )}
                  </Grid>
                </div>
              </Grid>
            </CardContent>
          </Paper>
        )}

        {cart.length > 0 && <Fab to="/cart?from=/" />}
      </Container>
    </React.Fragment>
  );
}

export default withTransaction("ProductDetails", "component")(ProductDetails);
