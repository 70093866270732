/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import currencyFormatter from "../../utilities/currency-formatter";
import { CartContext } from "../../context/cart";
import "./index.css";

function TopSeller(props) {
  const { classes, top } = props;
  const { addCart, increaseCart, decreaseCart, cart, cartUpdated } = useContext(
    CartContext
  );
  const [qty, setQty] = useState(0);

  useEffect(() => {
    const selectedItem = cart.find(item => item.id === top.id);
    if (selectedItem) {
      setQty(selectedItem.total);
    } else {
      setQty(0);
    }
  }, [cartUpdated]);

  const discountPercent =
    top.promoPriceType === "PERCENTAGE"
      ? top.promoPrice
      : (100 - (top.promoPrice / top.regularPrice) * 100) % 1 === 0
      ? 100 - (top.promoPrice / top.regularPrice) * 100
      : (100 - (top.promoPrice / top.regularPrice) * 100).toFixed(2);

  const cardMedia = () => {
    if (top.isPromo && top.size === null) {
      return "space-between";
    }
    if (top.isPromo) {
      return "flex-start";
    }
    if (top.size === null) {
      return "flex-end";
    }
  };
  return (
    <Box className={classes.card}>
      <Grid container spacing={0}>
        <Grid
          onClick={() => {
            props.topSeller
              ? props.history.push(`/product/${top.id}?from=/top-seller`)
              : props.history.push(
                  `/product/${top.id}?from=/category/${top.category_id}`
                );
          }}
          item
          xs={3}
        >
          <CardMedia
            className={classes.media}
            image={
              top.image.url ? top.image.url : "https://via.placeholder.com/150"
            }
          >
            <div
              className={classes.cardMedia}
              style={{ justifyContent: cardMedia() }}
            >
              {top.isPromo && (
                <p className={classes.cardMediaDisc}>
                  Disc.
                  {discountPercent}%
                </p>
              )}
              {top.size === null && (
                <div className={classes.cardMediaDigital}>Produk Digital</div>
              )}
            </div>
          </CardMedia>
        </Grid>
        <Grid
          item
          xs={9}
          justify="center"
          direction="column"
          style={{ display: "flex", padding: "0px 10px" }}
        >
          <p
            className="title"
            onClick={() => {
              props.topSeller
                ? props.history.push(`/product/${top.id}?from=/top-seller`)
                : props.history.push(
                    `/product/${top.id}?from=/category/${top.category_id}`
                  );
            }}
          >
            {top.name}
          </p>
          <div className={classes.flexWrapper}>
            <div
              onClick={() => {
                props.topSeller
                  ? props.history.push(`/product/${top.id}?from=/top-seller`)
                  : props.history.push(
                      `/product/${top.id}?from=/category/${top.category_id}`
                    );
              }}
            >
              {top.isPromo && (
                <Typography variant="caption" className={classes.old}>
                  <b>{currencyFormatter.format(top.regularPrice)}</b>
                </Typography>
              )}
              {top.isPromo && (
                <Typography className={classes.price}>
                  {currencyFormatter.format(top.price)}
                  <b style={{ color: "#C7C7C9", fontSize: 10 }}>
                    {" "}
                    <b style={{ color: "#C7C7C9", fontSize: 10 }}>
                      {top.unit ? `/${top.unit}` : ""}
                    </b>
                  </b>
                </Typography>
              )}
              {!top.isPromo && (
                <Typography className={classes.price}>
                  {currencyFormatter.format(top.price)}
                  <b style={{ color: "#C7C7C9", fontSize: 10 }}>
                    {" "}
                    <b style={{ color: "#C7C7C9", fontSize: 10 }}>
                      {top.unit ? `/${top.unit}` : ""}
                    </b>
                  </b>
                </Typography>
              )}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end"
              }}
            >
              {qty > 0 ? (
                <ButtonGroup
                  size="small"
                  aria-label="Small outlined button group"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "-webkit-fill-available",
                    marginRight: 1
                  }}
                >
                  <Button
                    onClick={event => {
                      decreaseCart(top);
                    }}
                    style={{
                      color: process.env.REACT_APP_COLOR_FONT || "#000000",
                      border: "1px solid #C4C4C4",
                      borderRadius: 4,
                      maxWidth: 30,
                      minWidth: 30,
                      maxHeight: 30,
                      minHeight: 30,
                      padding: 1
                    }}
                  >
                    -
                  </Button>
                  <Button
                    style={{
                      border: "0",
                      fontWeight: "bold",
                      fontSize: 12,
                      maxWidth: 30,
                      minWidth: 30,
                      maxHeight: 30,
                      minHeight: 30,
                      padding: 1
                    }}
                  >
                    {qty || 0}
                  </Button>
                  <Button
                    onClick={event => {
                      increaseCart(top);
                    }}
                    style={{
                      color: process.env.REACT_APP_COLOR_FONT || "#000000",
                      backgroundColor:
                        process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                      border: `1px solid ${process.env
                        .REACT_APP_COLOR_SECONDARY || "#FFE570"}`,
                      borderRadius: 4,
                      maxWidth: 30,
                      minWidth: 30,
                      maxHeight: 30,
                      minHeight: 30,
                      padding: 1,
                      fontWeight: "bold"
                    }}
                  >
                    +
                  </Button>
                </ButtonGroup>
              ) : (
                <div>
                  {top.onStock ? (
                    <Button
                      onClick={event => {
                        addCart(top);
                      }}
                      style={{
                        color: process.env.REACT_APP_COLOR_FONT || "#000000",
                        backgroundColor:
                          process.env.REACT_APP_COLOR_PRIMARY || "#FFD101",
                        borderRadius: 4,
                        width: 75,
                        height: 30,
                        fontSize: 10,
                        textTransform: "none",
                        fontWeight: 700,
                        fontFamily:
                          process.env.REACT_APP_FONT_URL_BUTTON ||
                          "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
                      }}
                    >
                      <b>Tambahkan</b>
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      disabled
                      style={{
                        borderRadius: 4,
                        width: 90,
                        padding: 0,
                        height: 30,
                        fontSize: 8,
                        fontFamily:
                          process.env.REACT_APP_FONT_URL_BUTTON ||
                          "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
                      }}
                    >
                      <b>Stok Habis</b>
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default withRouter(TopSeller);
