import React from "react";

const Password = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M120 60C120 93.1366 93.1366 120 60 120C26.8634 120 0 93.1366 0 60C0 26.8634 26.8634 0 60 0C93.1366 0 120 26.8634 120 60Z"
      fill={process.env.REACT_APP_COLOR_SECONDARY || "#FFE570"}
    />
    <path
      d="M108.168 60.084C108.168 86.6473 86.6473 108.168 60.084 108.168C33.5208 108.168 12 86.6473 12 60.084C12 33.5208 33.5208 12 60.084 12C86.6473 12 108.168 33.5208 108.168 60.084Z"
      fill={process.env.REACT_APP_COLOR_PRIMARY || "#FFD101"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.975 54.385V45.6925C71.975 40.8827 68.0924 37 63.2825 37H57.4875C52.6777 37 48.795 40.8827 48.795 45.6925V54.385C45.8975 54.385 43 56.9928 43 60.18V77.565C43 80.7523 45.6078 83.36 48.795 83.36H71.975C75.1623 83.36 77.77 80.7523 77.77 77.565V60.18C77.77 56.9928 74.8725 54.385 71.975 54.385ZM63.2825 74.6675H57.4875L59.4578 68.6987C58.2988 68.293 57.4875 67.2499 57.4875 65.975C57.4875 64.3524 58.7624 63.0775 60.385 63.0775C62.0076 63.0775 63.2825 64.3524 63.2825 65.975C63.2825 67.2499 62.4132 68.293 61.3122 68.6987L63.2825 74.6675ZM66.18 48.59V54.385H54.5321L54.59 48.59C54.59 45.4028 54.3003 42.795 57.4875 42.795H63.2825C66.4698 42.795 66.18 45.4028 66.18 48.59Z"
      fill="white"
    />
  </svg>
);

export default Password;
