import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ChangeIcon from "../../vector/change-loc.svg";
import MarketIcon from "../../vector/marketIcon.js";

function Component(props) {
  const { classes } = props;
  const selectedPasar = JSON.parse(localStorage.getItem("selectedPasar"));
  const listPasar = JSON.parse(localStorage.getItem("listLocation"));

  return (
    <>
      <Grid container className={classes.container} item xs={12}>
        <div className={classes.locationDiv}>
          <Grid item xs={9} className={classes.locationGrid}>
            <div>
              <Typography className={classes.titleText}>
                Kamu berbelanja di
              </Typography>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 6 }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MarketIcon />
              </div>
              <Typography
                style={{ fontSize: "11px", fontWeight: 700, marginLeft: 8 }}
              >
                {selectedPasar ? (
                  selectedPasar.name
                ) : (
                  <span
                    onClick={() => props.history.push("/market")}
                    style={{ color: "red" }}
                  >
                    Pilih cluster
                  </span>
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={3} className={classes.changeGrid}>
            {listPasar?.length > 1 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => props.history.push("/market")}
              >
                <Typography
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                    color: "#2F9CF1",
                    marginRight: 6,
                  }}
                >
                  Ganti
                </Typography>
                <img src={ChangeIcon} alt="Ganti lokasi" />
              </div>
            )}
          </Grid>
        </div>
        <div
          className={classes.searchDiv}
          onClick={() => {
            props.history.push("/product-search");
          }}
        >
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Cari produk yang kamu cari ..."
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            InputProps={{
              "aria-label": "search",
            }}
          />
        </div>
      </Grid>
    </>
  );
}

export default withRouter(Component);
