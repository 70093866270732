import { axiosInstance } from "../config";

export const getListPasar = async (keyword) => {
  const response = await axiosInstance.get(
    `/customer/ecommerce/locations?search=${keyword}`
  );
  return response.data.data;
};

export const locationById = async (id) => {
  const response = await axiosInstance.get(
    `/customer/ecommerce/locations/${id}`
  );
  return response.data;
};

export const tenantInfo = async () => {
  const response = await axiosInstance.get(`/customer/tenant/profile`);
  return response.data;
};
